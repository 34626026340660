import React, { useState } from "react";
import { Styled } from "../../../constants/Styled";
import { Button, Modal } from "antd";
import { bank__data } from "../../../data/bank.Data";
import AddBankWallet from "./AddBank.Wallet";
import TransactionPinWallet from "./TransactionPin.Wallet";
import { ModalTitle } from "../../../common";
import { useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";

const CashOutWallet = () => {
  const [addBank, setAddBank] = useState(false);
  const [showPinModal, setShowPinModal] = useState(false);
  const { wallet } = useSelector(state => state.wallet)

  // Checking if bank__data array is empty
  const isBankDataEmpty =
    !bank__data ||
    bank__data.every((bank) =>
      Object.values(bank).every((value) => value === "")
    );

  return (
    <>
      <Styled.Form>
        <Styled.Section sx="cashout__balance mt-10">
          <Styled.Text>
            Wallet Balance: <b><CurrencyFormat value={wallet?.data?.balance ?? 0} thousandSeparator={true} displayType="text" prefix="₦ " /></b>
          </Styled.Text>
        </Styled.Section>

        <Styled.Text sx="add__bank" onClick={() => setAddBank(true)}>
            + Add Bank Account
          </Styled.Text>

    
        {/* {isBankDataEmpty ? (
          <Styled.Text sx="add__bank" onClick={() => setAddBank(true)}>
            + Add Bank Account
          </Styled.Text>
        ) : (
          bank__data.map((bank, i) => (
            <Styled.Wrapper sx="added__bank" key={i}>
              <Styled.Section>
                <Styled.Img img={bank.bank__icon} alt="" />
                <Styled.Section>
                  <Styled.Text>{bank.bank_ac}</Styled.Text>
                  <Styled.Text>{bank.bank_name}</Styled.Text>
                </Styled.Section>
              </Styled.Section>
              {bank.icon}
            </Styled.Wrapper>
          ))

        )} */}

        <Styled.Section sx="new__auth__wrapper">
          <Styled.Span>Amount</Styled.Span>
          <Styled.Input type="text" placeholder="amount" name="cash_amount" />
        </Styled.Section>

        <Button
          type="primary"
          size="large"
          className="cashout__btn"
          onClick={() => setShowPinModal(true)}
        >
          Cash Out
        </Button>
      </Styled.Form>

      {addBank && (
        <Modal
          title="Select Bank Details"
          width={400}
          open={() => setAddBank(true)}
          onCancel={() => setAddBank(false)}
          footer={null}
        >
          <AddBankWallet closeModal={() => setAddBank(false)} />
        </Modal>
      )}

      {/* transaction pin entering */}
      {showPinModal && (
        <Modal
          title={
            <ModalTitle
              title="Transaction Pin"
              close={() => setShowPinModal(false)}
            />
          }
          open={() => setShowPinModal(true)}
          footer={null}
          width={400}
          closable={false}
        >
          <TransactionPinWallet close={() => setShowPinModal(false)} />
        </Modal>
      )}
    </>
  );
};

export default CashOutWallet;
