import React from "react";
import { Styled } from "../../../../constants/Styled";
import { Button } from "antd";


const EditMerchantInfoProfile = () => {
  return (
    <>
    <Styled.Form sx='px-[8px] pb-[28px] pt-2'>
      {/* merchant name */}
      <Styled.Section sx="new__auth__wrapper pt-5">
        <Styled.Span>Company Name</Styled.Span>
        <Styled.Input type="text" placeholder="company name" name="first_name" />
      </Styled.Section>

      {/* last name */}
      <Styled.Section sx="new__auth__wrapper pt-5">
        <Styled.Span>Company Email Address</Styled.Span>
        <Styled.Input type="text" placeholder="merchant@hello.com" name="last_name" />
      </Styled.Section>

      {/* email */}
      <Styled.Section sx="new__auth__wrapper pt-5">
        <Styled.Span>Company Contact</Styled.Span>
        <Styled.Input
          type="email"
          placeholder="+2348138885517"
          name="email"
        />
      </Styled.Section>

      {/* phone number */}
      <Styled.Section sx="new__auth__wrapper company__msg pt-5">
        <Styled.Span>Office Address</Styled.Span>
        <Styled.Message
          type="text"
          placeholder="95 Broad Street Marina, Lagos Island"
          name="phone_number"
        />
      </Styled.Section>


      <Button type="primary" className="w-[100%] mt-10">Save</Button>
      </Styled.Form>
    </>
  );
};

export default EditMerchantInfoProfile;
