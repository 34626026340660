import React from "react";
import { Styled } from "../constants/Styled";
import { Icons } from "../constants/Icons";

const HelpLabel = (props) => {
  return (
    <Styled.Section sx={`help__label__box ${props.clx}`}>
      <Icons.HelpLabelIcon />
      <Styled.Text>{props.title}</Styled.Text>
    </Styled.Section>
  );
};

export default HelpLabel;
