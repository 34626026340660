import React, { useState } from "react";
import { Styled } from "../../../constants/Styled";
import { useDispatch } from "react-redux";
import { cardGenerateSingle } from "../../../state/cardGenerateSingle.Slice";
import { schema } from "../../../schema/Schema";
import { useFormik } from "formik";
import { Button, Radio } from "antd";
import { toast } from "react-toastify";
import { fetchCards } from "../../../state/cardsSlice";
import ConfirmCardRequestPin from "./confirmCardRequestPin";
import { ModalTitle } from "../../../common";
import { showCustomSuccessModal } from "../../../utils/Utils";

const CardRequest = ({ close }) => {
  const [ closeModal, setCloseModal ] = useState(false);
  const [proceedCardRequest, setProceedCardRequest] = useState(false);

  const handleProceed = () => {
    setProceedCardRequest(!proceedCardRequest);
  };
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      cardPurpose: "",
      contactNumber: "",
      amount: "",
      email: "",
      holderName: "",
      oneTimeUse: false,
      pin: "",
    },

    validationSchema: schema.cardGenerateSingleSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await dispatch(cardGenerateSingle(values)).unwrap();
        setLoading(false);
        showCustomSuccessModal(response?.message, 5000, "Success");
        setCloseModal(close)
        dispatch(fetchCards({ page: 1, limit: 10 }));
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <Styled.Form sx="px-[8px] pb-[8px] pt-2" onSubmit={formik.handleSubmit}>
        {closeModal ? "" : ""}
        <Styled.Section sx="new__auth__wrapper">
          <Styled.Span>Card Purpose</Styled.Span>
          <Styled.Input
            type="text"
            placeholder="Xmas Promo"
            name="cardPurpose"
            value={formik.values.cardPurpose}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <Styled.Section sx="error">
            {formik.touched.cardPurpose &&
              formik.errors.cardPurpose &&
              formik.errors.cardPurpose}
          </Styled.Section>
        </Styled.Section>

        <Styled.Section sx="new__auth__wrapper">
          <Styled.Span>Holder Name</Styled.Span>
          <Styled.Input
            type="text"
            placeholder="Tingo "
            name="holderName"
            value={formik.values.holderName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <Styled.Section sx="error">
            {formik.touched.holderName &&
              formik.errors.holderName &&
              formik.errors.holderName}
          </Styled.Section>
        </Styled.Section>

        {/* card number */}
        <Styled.Section sx="new__auth__wrapper pt-3">
          <Styled.Span>Phone Number</Styled.Span>
          <Styled.Input
            type="text"
            placeholder="+234800000000"
            name="contactNumber"
            value={formik.values.contactNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <Styled.Section sx="error">
            {formik.touched.contactNumber &&
              formik.errors.contactNumber &&
              formik.errors.contactNumber}
          </Styled.Section>
        </Styled.Section>

        {/* amount*/}
        <Styled.Section sx="new__auth__wrapper pt-3">
          <Styled.Span>Amount</Styled.Span>
          <Styled.Input
            type="number"
            placeholder="N 0.00"
            name="amount"
            value={formik.values.amount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <Styled.Section sx="error">
            {formik.touched.amount &&
              formik.errors.amount &&
              formik.errors.amount}
          </Styled.Section>
        </Styled.Section>

        {/* email*/}
        <Styled.Section sx="new__auth__wrapper pt-3">
          <Styled.Span>Email Address</Styled.Span>
          <Styled.Input
            type="email"
            placeholder="email@gmail.com"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <Styled.Section sx="error">
            {formik.touched.email && formik.errors.email && formik.errors.email}
          </Styled.Section>
        </Styled.Section>

        <Styled.Section sx="pt-3">
          <Radio.Group
          className="one__time__use" 
          name="oneTimeUse" 
          value={formik.values.oneTimeUse}
          onChange={formik.handleChange}

          >
            <Radio value={true}>One Time Use</Radio>
            <Radio value={false}>Multiple Use</Radio>
          </Radio.Group>

          <Styled.Section sx="error">
            {formik.touched.oneTimeUse && formik.errors.oneTimeUse && formik.errors.oneTimeUse}
          </Styled.Section>
        </Styled.Section>

        <Button
          type="primary"
          size="large"
          className="card__single__req__btn"
          onClick={handleProceed}
        >
          Proceed
        </Button>
      </Styled.Form>

      {proceedCardRequest && (
        <Styled.TgModal
          title={<ModalTitle close={handleProceed} />}
          closable={false}
          open={proceedCardRequest}
          footer={null}
          width="512px"
        >
          <ConfirmCardRequestPin formik={formik} loading={loading} />
        </Styled.TgModal>
      )}
    </>
  );
};

export default CardRequest;
