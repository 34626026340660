import React from 'react';
import { ConfigProvider } from 'antd';

const ConfigProviderTheme = ({ children}) => {
  return (
    <ConfigProvider
    theme={{
        token: {
            colorPrimary: "#F68A2A",
            colorPrimaryBg: "rgba(246, 138, 41, 0.1)",
            colorBorder: "#F68A2A",
            colorPrimaryBgHover: "none",
            colorPrimaryBorderHover: "none",
            colorBorderBg: "none",
            boxShadow: "none",
            colorBgLayout: "transparent",      
        }
      }}
  >
    {children}
  </ConfigProvider>
  )
}

export default ConfigProviderTheme