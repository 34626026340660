

export const job__desc = [
    {
        value: "frontend",
        desc: "Front End Engineer"
    },

    {
        value: "backend",
        desc: "Back End Engineer"
    },

    {
        value: "devops",
        desc: "Devops Engineer"
    },

    {
        value: "support",
        desc: "Customer Care"
    },

    {
        value: "account",
        desc: "Accountant"
    },
]