import React, { useState } from "react";
import { Tabs } from "antd";
import CustomerTrnx from "./customerTrnx";
import CustomerCards from "./customerCards";


const TrnxCustomer = ({ customerId }) => {

  const [ switchTab, setSwitchTab ] = useState("1")

  const handleTabChange = (key) => {
    setSwitchTab(key);
  }

const options = [
    {
      key: 1,
      label: "Transactions",
      children: <CustomerTrnx customerId={customerId} />
    },

    {
      key: 2,
      label: "Cards",
      children: <CustomerCards customerId={customerId} />,
    },
  ]

  return (
    <>

      <Tabs
          defaultActiveKey={switchTab}
          items={options}
          onChange={handleTabChange}
          centered={false}
          className="pt-[20px]"
        />
    </>
  );
};

export default TrnxCustomer;
