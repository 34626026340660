
import CurrencyFormat from "react-currency-format";


export const customers_columns = [
  {
    title: 'Card Holder Name',
    dataIndex: 'full_name',
    render: (text, record) => record.full_name ? `${record.full_name} (${record.phone_number})` : record.phone_number,
    sorter: (a, b) => a.full_name - b.full_name,
  },


  {
    title: 'Cards',
    dataIndex: 'cards_count',
    sorter: (a, b) => a.cards_count - b.cards_count,
  },

  {
    title: 'Total Amount Spent',
    dataIndex: 'total_amount_spent',
    render: total_amount_spent=> <CurrencyFormat value={total_amount_spent ?? 0} displayType={'text'} thousandSeparator={true} prefix={'₦ '} />,
    sorter: (a, b) => a.total_amount_spent - b.total_amount_spent,
  },
  {
    title: 'Last Amount Spent',
    dataIndex: 'last_amount_spent',
    render: last_amount_spent => <CurrencyFormat value={last_amount_spent ?? 0} displayType={'text'} thousandSeparator={true} prefix={'₦ '} />,
    sorter: (a, b) => a.last_amount_spent - b.last_amount_spent,
  },
  {
    title: 'Partner',
    dataIndex: 'partner',
    key: 'partner',
    render: partner => (partner ? partner : "N/A"),
    sorter: (a, b) => a.partner - b.partner,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: status => <span className={`status-before-content  ${status ? 'active' : 'inactive'}`}>{status === true ? 'active' : 'inactive'}</span>,
    sorter: (a, b) => a.status - b.status,
    width: "120px",
    align: "center"
  },
];
