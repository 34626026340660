import React, { useRef, useState } from "react";
import { Styled } from "../../../constants/Styled";
import { Button, Modal } from "antd";
import { ModalTitle } from "../../../common";
import DisputeTransaction from "./Dispute.Transaction";
import { useReactToPrint } from "react-to-print";
import { formatAmount, formatDateAndTime } from "../../../utils/Utils";

const TransactionDetail = ({ trnxId }) => {
  const [isDispute, setIsDispute] = useState(false);
  const printContentRef = useRef(null);

  const handleDispute = () => {
    setIsDispute(true);
  };

  const handlePrint = useReactToPrint({
    content: () => printContentRef.current,
  });

  return (
    <>
      <Styled.Wrapper sx="trnx__wrapper" ref={printContentRef}>
        <Styled.Section sx="trnx__detail__wrapper">
          <Styled.Text>Status</Styled.Text>
          <Styled.Span
            sx={`status-before-content ${
              trnxId.transaction_status
            }`}
          >
            {trnxId.transaction_status === "COMPLETED" ? "Success" || "Failed" : "Pending"}
          </Styled.Span>
        </Styled.Section>

        <Styled.Section sx="trnx__detail__wrapper">
          <Styled.Text>Transaction ID</Styled.Text>
          <Styled.Span>{trnxId?.wallet_wallet_id ? trnxId?.wallet_wallet_id : "N/A"}</Styled.Span>
        </Styled.Section>

        <Styled.Section sx="trnx__detail__wrapper">
          <Styled.Text>Transaction Type</Styled.Text>
          <Styled.Span>{trnxId?.transaction_transaction_type ? trnxId?.transaction_transaction_type : "N/A"}</Styled.Span>
        </Styled.Section>

        <Styled.Section sx="trnx__detail__wrapper">
          <Styled.Text>Partner</Styled.Text>
          <Styled.Span>
            {trnxId?.affiliates ? trnxId?.affiliates : "N/A"}
          </Styled.Span>
        </Styled.Section>

        <Styled.Section sx="trnx__detail__wrapper">
          <Styled.Text>Time Date</Styled.Text>
          <Styled.Span>
            {formatDateAndTime(trnxId?.transaction_created_at ? trnxId?.transaction_created_at : "N/A")}
          </Styled.Span>
        </Styled.Section>

        <Styled.Section sx="trnx__detail__wrapper">
          <Styled.Text>Description</Styled.Text>
          <Styled.Span>
            {trnxId?.transaction_mode ? trnxId?.transaction_mode : "N/A"}
          </Styled.Span>
        </Styled.Section>
      </Styled.Wrapper>

      <Styled.Wrapper sx="trnx__wrapper mt-5">
        <Styled.Section sx="trnx__detail__wrapper">
          <Styled.Text>Card Purpose</Styled.Text>
          <Styled.Span>
            {trnxId.card_card_purpose ? trnxId.card_card_purpose : "N/A"}
          </Styled.Span>
        </Styled.Section>

        <Styled.Section sx="trnx__detail__wrapper">
          <Styled.Text>Card Number</Styled.Text>
          <Styled.Span>
            {trnxId?.card_card_number ? trnxId?.card_card_number : "N/A"}
          </Styled.Span>
        </Styled.Section>

        <Styled.Section sx="trnx__detail__wrapper">
          <Styled.Text>Card Type</Styled.Text>
          <Styled.Span>
            {trnxId?.card_one_time_use === true ? "One Time Use" : "Multiple"}
          </Styled.Span>
        </Styled.Section>

        <Styled.Section sx="trnx__detail__wrapper">
          <Styled.Text>Card Holder Name</Styled.Text>
          <Styled.Span>
            {trnxId?.card_holder_name ? trnxId?.card_holder_name : "N/A"}
          </Styled.Span>
        </Styled.Section>

        <Styled.Section sx="trnx__detail__wrapper">
          <Styled.Text>Receiver</Styled.Text>
          <Styled.Span>
            {trnxId?.card_receiver_name ? trnxId?.card_receiver_name : "N/A"}
          </Styled.Span>
        </Styled.Section>

        <Styled.Section sx="trnx__detail__wrapper">
          <Styled.Text>Point of Sale</Styled.Text>
          <Styled.Span>
            {trnxId?.card_seller_name ? trnxId?.card_seller_name : "N/A"}
          </Styled.Span>
        </Styled.Section>
      </Styled.Wrapper>

      <Styled.Wrapper sx="trnx__wrapper mt-5">
        <Styled.Section sx="trnx__detail__wrapper">
          <Styled.Text>Transaction Amount</Styled.Text>
          <Styled.Span>{formatAmount(trnxId?.transaction_amount)}</Styled.Span>
        </Styled.Section>

        <Styled.Section sx="trnx__detail__wrapper">
          <Styled.Text>Transaction Fee</Styled.Text>
          <Styled.Span>{formatAmount(trnxId?.transaction_fees)}</Styled.Span>
        </Styled.Section>

        <Styled.Section sx="trnx__detail__wrapper">
          <Styled.Text>Total Amount</Styled.Text>
          <Styled.Span>
            {formatAmount(trnxId?.transaction_sub_total)}
          </Styled.Span>
        </Styled.Section>
      </Styled.Wrapper>


      <Styled.Wrapper sx="flag__msg__btn">
          <Button type="" onClick={handleDispute} className="dispute__trnx__btn">
            Create Dispute
          </Button>
          <Button type="primary" onClick={handlePrint}>Export</Button>
        </Styled.Wrapper>

      {isDispute && (
        <Modal
          title={
            <ModalTitle
              title={`Dispute - ${trnxId?.wallet_id}`}
              close={() => setIsDispute(false)}
            />
          }
          open={handleDispute}
          closable={false}
          footer={null}
          width={512}
        >
          <DisputeTransaction />
        </Modal>
      )}
    </>
  );
};

export default TransactionDetail;
