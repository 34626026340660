import React from 'react'
import {  VerifiedEmail } from '../components'

const EmailVerified = () => {
  return (
    <div>
        <VerifiedEmail />
    </div>
  )
}

export default EmailVerified