

export const dispute_trnx_col = [
    {
        title: "Dispute ID",
        dataIndex: "id",
        sorter: (a, b) => a.id - b.id
    },

    {
        title: "Subject",
        dataIndex: "subject",
        sorter: (a, b) => a.subject - b.subject
    },

    {
        title: "Transaction ID",
        dataIndex: "card_number",
        sorter: (a, b) => a.card_number - b.card_number
    },

    {
        title: "Time / Date",
        dataIndex: "created_at",
        render: (created_at) => {
            const dateObj = new Date(created_at);
            const timeString = dateObj.toLocaleTimeString('en-US', { hour12: false });
            const dateString = dateObj.toLocaleDateString('en-US', { day: '2-digit', month: 'short' });
            return `${timeString} ${dateString}`;
          },
        sorter: (a, b) => a.created_at- b.created_at
    },

    {
        title: "Status",
        dataIndex: "status",
        render: status => (
            <div className={`status-before-content ${status ? status : status}`}>{status ? status : status}</div>
        ),
        sorter: (a, b) => a.status - b.status
    },
]
