import { Button, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Styled } from "../../../../constants/Styled";
import { Icons } from "../../../../constants/Icons";
import {  role_mgr_accepted__staff } from "../../../../data/userRole.Data";
import InviteMember from "./InviteMember";
import { useDispatch, useSelector } from "react-redux";
import { fetchAcceptedStaff } from "../../../../state/activeMember.Slice";

const RoleMgrUserActiveMember = () => {
  const dispatch = useDispatch();
  const [inviteMember, setInviteMember] = useState(false);

  const { acceptedStaff } = useSelector((state) => state.acceptedStaff);

  useEffect(() => {
      dispatch(fetchAcceptedStaff());
  }, [dispatch]);

  const activeStaff = acceptedStaff?.data?.staff;

  const actionColumn = {
    title: "Actions",
    dataIndex: "id",
    key: "actions",
    render: (_, record) => (
      <Space size="large">
        <Icons.EditIcon onClick={record.id} />
        <Icons.DeleteIcon onClick={record.id} />
      </Space>
    ),
  };

  const user__column = [...role_mgr_accepted__staff, actionColumn];
  return (
    <>
      <Styled.Wrapper sx="role__mgr__user pt-4">
        <Styled.Section sx="role__mgr__user__left">
          <Styled.Caption title="Active Member" />
          <Styled.Text>
            Invite your team members, collaborate, manage their roles and
            permissions.
          </Styled.Text>

          <Button
            type="primary"
            size="large"
            onClick={() => setInviteMember(true)}
          >
            Invite Member
          </Button>
        </Styled.Section>

        <Styled.Section sx="role__mgr__user__right table__box shadow-md">
          <Table columns={user__column} dataSource={activeStaff} />
        </Styled.Section>
      </Styled.Wrapper>

      {/* invite member form */}
     {inviteMember && (
         <InviteMember open={inviteMember} cancel={() => setInviteMember(false)} />
     )}
    </>
  );
};

export default RoleMgrUserActiveMember;
