import React from 'react';
import { AcceptInvite } from "../components"

const UserAcceptInvite = () => {
  return (
    <div>
        <AcceptInvite />
    </div>
  )
}

export default UserAcceptInvite