import React, { useEffect } from "react";
import { Styled } from "../../constants/Styled";
import { useDispatch, useSelector } from "react-redux";
import { fetchIndustryType } from "../../state/industryType.Slice";

const MerchantDetails = ({ formik }) => {
  const dispatch = useDispatch();
  const { industryType } = useSelector((state) => state.industryType);

  useEffect(() => {
    dispatch(fetchIndustryType());
  },[dispatch])

 const indu_type = industryType?.data;

  return (
    <Styled.Wrapper>
      <Styled.Section sx="new__auth__wrapper ">
        <Styled.Span>Company Name</Styled.Span>
        <Styled.Input
          type="text"
          placeholder="Company Name"
          name="company_name"
          value={formik.values.company_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <Styled.Section sx="error">
          {formik.touched.company_name &&
            formik.errors.company_name &&
            formik.errors.company_name}
        </Styled.Section>
      </Styled.Section>

      <Styled.Section sx="new__auth__wrapper pt-5">
        <Styled.Span>Industry Type</Styled.Span>
        <Styled.Select
          type="text"
          placeholder="Industry Type"
          name="industry_type"
          value={formik.values.industry_type}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          <option value="">Select Industry Type</option>
          {indu_type?.map((type) => (
            <option value={type.slug} key={type?.id}>{type.name}</option>
          ))}

        </Styled.Select>

        <Styled.Section sx="error">
          {formik.touched.industry_type &&
            formik.errors.industry_type &&
            formik.errors.industry_type}
        </Styled.Section>
      </Styled.Section>

      <Styled.Section sx="new__auth__wrapper pt-5">
        <Styled.Span>CAC Registration Number</Styled.Span>
        <Styled.Input
          type="text"
          placeholder="CAC Registration Number"
          name="cac_number"
          value={formik.values.cac_number}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <Styled.Section sx="error">
          {formik.touched.cac_number &&
            formik.errors.cac_number &&
            formik.errors.cac_number}
        </Styled.Section>
      </Styled.Section>

      <Styled.Section sx="new__auth__wrapper pt-5">
        <Styled.Span>Office Address</Styled.Span>
        <Styled.Input
          type="text"
          placeholder="Office Address"
          name="office_address"
          value={formik.values.office_address}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <Styled.Section sx="error">
          {formik.touched.office_address &&
            formik.errors.office_address &&
            formik.errors.office_address}
        </Styled.Section>
      </Styled.Section>
    </Styled.Wrapper>
  );
};

export default MerchantDetails;
