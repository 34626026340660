import React from "react";
import { DatePicker } from "antd";


const RangePicker = DatePicker;

const CustomDateRangePicker = () => {

  return (
    <RangePicker picker="weeks" size="large" className="trnx__search__select"/>
  );
};

export default CustomDateRangePicker;
