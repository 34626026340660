import React, { useEffect, useState } from "react";
import { Styled } from "../../../constants/Styled";
import { Icons } from "../../../constants/Icons";
import { ModalTitle } from "../../../common";
import FlagCustomer from "./FlagCustomer";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleCustomer } from "../../../state/singleCustomerSlice";


const CustomerOverview = ({ customerId }) => {
  const dispatch = useDispatch();
  const { singlecustomer } = useSelector((state) => state.singlecustomer)
  const [isFlag, setIsFlag] = useState(false);

  useEffect(() => {
    dispatch(fetchSingleCustomer({cardId: customerId?.phone_number}));
  }, [dispatch, customerId])

  const cusData = singlecustomer?.data;

  return (
    <>
      <Styled.Wrapper sx="card__code">
        <Styled.Wrapper sx="voucher__card__footer">
          <Styled.Section>
            <Styled.Span>CARD HOLDER NAME</Styled.Span>
            <Styled.Text>{cusData?.full_name || cusData?.phone_number}</Styled.Text>
          </Styled.Section>

          <Styled.Section>
            <Styled.Span >LAST AMOUNT SPENT</Styled.Span>
            <Styled.Text>{cusData?.last_amount_spent}</Styled.Text>
          </Styled.Section>
        </Styled.Wrapper>

        <Styled.Wrapper sx="voucher__card__footer">
          <Styled.Section>
            <Styled.Span>PARTNER</Styled.Span>
            <Styled.Text>{cusData?.partner ?? "N/A"}</Styled.Text>
          </Styled.Section>

          <Styled.Section>
            <Styled.Span sx="text-start">TOTAL AMOUNT SPENT</Styled.Span>
            <Styled.Text sx="text-start">{cusData?.total_amount_spent}</Styled.Text>
          </Styled.Section>
        </Styled.Wrapper>

        <Styled.Wrapper sx="voucher__card__footer">
          <Styled.Section>
            <Styled.Span>CUSTOMER STATUS</Styled.Span>
            <Styled.Text>
              {customerId?.status ? <Styled.Activate color="#32A75A" text="Active" /> : <Styled.Block color="#32A75A" text="In-active" />  }
                
            </Styled.Text>
          </Styled.Section>
        </Styled.Wrapper>

        <Styled.Wrapper sx="flag__card__btn__btn">
          {cusData?.flagged ? <Icons.FlagCardBtn onClick={() => setIsFlag(true)} /> : <Icons.FlagCardBtn onClick={() => setIsFlag(true)} /> }
         
        </Styled.Wrapper>
      </Styled.Wrapper>

      {isFlag && (
        <Styled.TgModal
          title={
            <ModalTitle
              title={`Flag - ${customerId?.phone_number}`}
              close={() => setIsFlag(false)}
            />
          }
          open={isFlag}
          width={512}
          footer={null}
          closable={false}
        >
          <FlagCustomer close={() => setIsFlag(false)} cardNumber={customerId?.phone_number}/>
        </Styled.TgModal>
      )}
    </>
  );
};

export default CustomerOverview;
