import React, { useEffect, useState } from "react";
import { Styled } from "../../../../constants/Styled";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { updateStaffProfile } from "../../../../state/updateStaffProfile.Slice";
import { schema } from "../../../../schema/Schema";
import { fetchAllJobDesignation } from "../../../../state/jobDesignation.Slice";
import { loginUser, updateUserDetails } from "../../../../state/authSlice";

const ProfileEdit = ({ close }) => {
  const dispatch = useDispatch();
  const [ closeModal, setCloseModal ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { jobDesignation } = useSelector((state) => state.jobDesignation);

  useEffect(() => {
    dispatch(fetchAllJobDesignation());
  },[dispatch]);

  const job_desc = jobDesignation?.data;

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email_address: user?.profile?.email_address,
      job_deesignation: "",
    },

    validationSchema: schema.updateStaffSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const response = await dispatch(updateStaffProfile(values)).unwrap();
        setIsLoading(false);
        toast.success(response.message);
        dispatch(updateUserDetails(response?.data))
        formik.resetForm();
        setCloseModal(close)
        dispatch(loginUser());
      } catch (err) {
        toast.error(err.message);
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <Styled.Form sx="px-[8px] pb-[8px] pt-2" onSubmit={formik.handleSubmit}>
        {/* merchant name */}
        {closeModal ? " " : ""}
        <Styled.Section sx="new__auth__wrapper pt-5">
          <Styled.Span>First Name</Styled.Span>
          <Styled.Input
            type="text"
            placeholder="first name"
            name="first_name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Styled.Section sx="error">
          {formik.touched.first_name &&
            formik.errors.first_name &&
            formik.errors.first_name}
        </Styled.Section>
        </Styled.Section>

        {/* last name */}
        <Styled.Section sx="new__auth__wrapper pt-3">
          <Styled.Span>Last Name</Styled.Span>
          <Styled.Input 
          type="text" 
          placeholder="Last Name" 
          name="last_name"
          value={formik.values.last_name} 
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          />
          <Styled.Section sx="error">
          {formik.touched.last_name &&
            formik.errors.last_name &&
            formik.errors.last_name}
        </Styled.Section>
        </Styled.Section>

        <Styled.Section sx="new__auth__wrapper pt-3">
        <Styled.Span>Job Description</Styled.Span>
        <Styled.Select
          type="text"
          name="job_deesignation"
          value={formik.values.job_deesignation}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          <option value="">Select a Job Description</option>
          {job_desc?.map((item) => (
               <option value={item?.slug} key={item?.id}>{item?.name}</option>
          ))}
        </Styled.Select>

        <Styled.Section sx="error">
          {formik.touched.job_deesignation &&
            formik.errors.job_deesignation &&
            formik.errors.job_deesignation}
        </Styled.Section>
      </Styled.Section>


       {/* email */}
        <Styled.Section sx="new__auth__wrapper pt-3">
          <Styled.Span>Email</Styled.Span>
          <Styled.Input
            type="email"
            placeholder={formik.values.email_address}
            name="email_address"
            value={formik.values.email_address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          />
           <Styled.Section sx="error">
          {formik.touched.email_address &&
            formik.errors.email_address &&
            formik.errors.email_address}
        </Styled.Section>
        </Styled.Section>

        <Button
          type="primary"
          className="card__single__req__btn mt-[10rem]"
          htmlType="submit"
          loading={isLoading}
        >
          Save
        </Button>
      </Styled.Form>
    </>
  );
};

export default ProfileEdit;
