

import React from 'react'
import { SignUp } from '../components'

const UserSignUp = () => {
  return (
    <div>
        <SignUp />
    </div>
  )
}

export default UserSignUp