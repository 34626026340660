import React, { useEffect, useState } from "react";
import {
  CustomerOverview,
  DashboardLayout,
  FlagCustomer,
  TrnxCustomer,
} from "../../components";
import { Dropdown, Menu, Table, Drawer, Input, Select } from "antd";
import { customers_columns } from "../../data/customersData";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomers } from "../../state/customerSlice";
import { Icons } from "../../constants/Icons";
import { CustomDateRangePicker, ModalTitle } from "../../common";
import { Styled } from "../../constants/Styled";
import { options } from "../../data/Data";

const Customers = () => {
  const dispatch = useDispatch();
  const { customers, loading } = useSelector((state) => state.customers);
  const [currentPage, setCurrentPage] = useState(1);
  const [singleDrawerVisible, setSingleDrawerVisible] = useState(false);
  const [selectedCustomerSing, setSelectedCustomerSing] = useState(null);
  const [isFlagCard, setIsFlagCard] = useState(false);

  useEffect(() => {
    dispatch(fetchCustomers());
  }, [dispatch]);

  const allCustomers = customers?.data?.customers;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // handle card flag
  const handleFlagCard = (cardNumber) => {
    setSelectedCustomerSing(cardNumber);
    setIsFlagCard(true);
  };

  const showDrawer = (cardId) => {
    setSelectedCustomerSing(cardId);
    setSingleDrawerVisible(true);
  };

  const closeDrawer = () => {
    setSingleDrawerVisible(false);
    setSelectedCustomerSing(null);
  };

  const isCustomerSelected = allCustomers?.find(
    (cust) => cust?.phone_number === selectedCustomerSing
  );

  const renderActionMenu = (customerId) => (
    <Menu>
      <Menu.Item key="view" onClick={() => showDrawer(customerId)}>
        <Styled.ViewDetail text="View" />
      </Menu.Item>
    </Menu>
  );

  const flaggedStatus = allCustomers?.map((card) => card.flagged);

  const actionColumn = {
    title: "Action",
    dataIndex: "id",
    key: "actions",
    render: (_, record, index) => (
      <>
        <Styled.Section
          key="flagged"
          onClick={() => handleFlagCard(record?.phone_number)}
        >
          {flaggedStatus[index] === true ? (
            <Styled.Unflagged text="Unflag" />
          ) : (
            <Styled.Flagged text="Flag" />
          )}
        </Styled.Section>
      </>
    ),
    width: "120px",
    align: "center",
  };

  const moreColumn = {
    title: "More",
    dataIndex: "id",
    key: "actions",
    render: (_, record) => (
      <Dropdown
        overlay={renderActionMenu(record?.phone_number)}
        placement="bottomRight"
        arrow
      >
        <Icons.ActionDot
          size={20}
          color="#72747D"
          width="15.12px"
          height="15.12px"
          onClick={(e) => e.preventDefault()}
        />
      </Dropdown>
    ),
    align: "center",
  };

  const user_columns = [...customers_columns, actionColumn, moreColumn];

  return (
    <>
      <DashboardLayout>
        <Styled.InnerWrapper sx="pt-[32px]">
          <Styled.Wrapper>
            <Styled.Section sx="merchant__filters">
              <Input
                className="trnx__search__input"
                type="search"
                size="large"
                prefix={<Icons.IconSearch />}
                placeholder="Search Transactions"
              />

              <Styled.RightButtonWrapper>
                <Select
                  className="trnx__search__select"
                  defaultValue="Filter By"
                  size="large"
                  options={options}
                  bordered={false}
                />
                <CustomDateRangePicker />
                <Styled.ExportButtonWrapper>
                  <Icons.ReadPDF />
                  <Icons.ReadExcel />
                </Styled.ExportButtonWrapper>
              </Styled.RightButtonWrapper>
              
            </Styled.Section>
          </Styled.Wrapper>

          <div className="table__box">
            <Table
              dataSource={allCustomers}
              columns={user_columns}
              rowKey="id"
              rowSelection="checkbox"
              loading={loading}
              onChange={handlePageChange}
              pagination={{
                total: customers.data,
                pageSize: 10,
                current: currentPage,
                onChange: handlePageChange,
              }}
              className="centered-pagination"
              scroll={{ x: true }}
            />
          </div>
        </Styled.InnerWrapper>

        {/* single user */}
        <Drawer
          title={<ModalTitle title="Customer Details" close={closeDrawer} />}
          placement="right"
          closable={false}
          open={singleDrawerVisible}
          width={700}
        >
          {isCustomerSelected && (
            <CustomerOverview customerId={isCustomerSelected} />
          )}
          {isCustomerSelected && (
            <TrnxCustomer customerId={isCustomerSelected} />
          )}
        </Drawer>
      </DashboardLayout>

      {isFlagCard && selectedCustomerSing && (
        <Styled.TgModal
          title={
            <ModalTitle
              title={`Flag - ${selectedCustomerSing}`}
              close={() => setIsFlagCard(false)}
            />
          }
          open={isFlagCard}
          width={512}
          footer={null}
          closable={false}
        >
          <FlagCustomer
            cardNumber={selectedCustomerSing}
            close={() => setIsFlagCard(false)}
          />
        </Styled.TgModal>
      )}
    </>
  );
};

export default Customers;
