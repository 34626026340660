

export const trnxPinData = [
    {
        title: "Amount",
        desc: 30000
    },

    {
        title: "Beneficiary Name",
        desc: "Ahua, Godwin"
    },

    {
        title: "Bank Name",
        desc: "GTBank"
    },

    {
        title: "Account Number",
        desc: "0127005772"
    },

]