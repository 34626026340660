import React, { useState } from "react";
import { Styled } from "../../../constants/Styled";
import { Button } from "antd";
import { job__desc } from "../../../data/jobDescription";
import { toast } from "react-toastify";


const UpdateProfile = ({ closeModal }) => {
 

  const [isLoading, setIsLoading] = useState(false);
  const [isClose, setIsClose] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      toast.success("Profile Details Updated Successfully");
      setIsClose(closeModal);
    }, 3000);
  };

  return (
    <>
      <Styled.Form>
        <Styled.Text> {isClose ? "X" : ""}</Styled.Text>
        <Styled.Wrapper sx="pt-5">
          <Styled.Section sx="new__auth__wrapper ">
            <Styled.Span>First Name</Styled.Span>
            <Styled.Input type="text" placeholder="First Name" name="" />
          </Styled.Section>

          <Styled.Section sx="new__auth__wrapper pt-3">
            <Styled.Span>Last Name</Styled.Span>
            <Styled.Input
              type="text"
              placeholder="Last Name"
              name=""
            />
          </Styled.Section>

          <Styled.Section sx="new__auth__wrapper pt-3">
            <Styled.Span>Phone  Number</Styled.Span>
            <Styled.Input
              type="text"
              placeholder="+2348138885517"
              name=""
            />
          </Styled.Section>

          <Styled.Section sx="new__auth__wrapper pt-3">
            <Styled.Span>Email Address</Styled.Span>
            <Styled.Input
              type="email"
              placeholder="godwin.ahua@tingomobile.com.ng
"
              name=""
            />
          </Styled.Section>
          <Styled.Section sx="new__auth__wrapper pt-3">
            <Styled.Span>Job Description</Styled.Span>
            <Styled.Select>
                <option defaultValue>Job Description</option>
                {job__desc.map(job => <option value={job.value}>{job.desc}</option>)}              
             </Styled.Select>
          </Styled.Section>
          <Button
            size="large"
            className="mt-6 w-full text-[20px] font-semibold"
            type="primary"
            loading={isLoading}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Styled.Wrapper>
      </Styled.Form>
    </>
  );
};

export default UpdateProfile;
