import React from 'react'
import Sidebar from '../sidebar/Sidebar'
import { Styled } from '../../constants/Styled'

const Layout = ({ children }) => {
  return (
   <>
       <Sidebar>
        <Styled.Wrapper>
            {children}
          <Styled.Wrapper sx="p-3"></Styled.Wrapper>
        </Styled.Wrapper>
    </Sidebar>
   </>
  )
}

export default Layout;