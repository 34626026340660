import React, { useEffect } from "react";
import { Styled } from "../../../../constants/Styled";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Icons } from "../../../../constants/Icons";
import MerchantLogoProfile from "./MerchantLogo.Profile";
import { fetchMerchantInfo } from "../../../../state/settingMerchantDetail.Slice";

const MerchantInfoProfile = ({ edit }) => {
  const dispatch = useDispatch();
const { merchantInfo } = useSelector((state) => state.merchantInfo);

useEffect(() => {
    dispatch(fetchMerchantInfo())
},[dispatch]);

const merchant = merchantInfo?.data;

  return (
    <div>
      <Styled.Wrapper sx="border__inner mt-5">
        <Styled.Section sx="merchant__overview__header__wrapper mb-[24px]">
          <Styled.Section sx="info__text__verified">
            <Styled.Text sx="info__text">Merchant Information</Styled.Text>
            <Styled.Text sx={`status-before-content ${merchant?.email_verified === true ? "verified" : "unverified"}`}>
              {merchant?.email_verified === true ? "Verified" : "Unverified"}
            </Styled.Text>
          </Styled.Section>
          <Button size="large" onClick={edit} className="btn__icon__gap__2">
            Edit <Icons.EditIcon />
          </Button>
        </Styled.Section>

        {/* merchant image */}

        <MerchantLogoProfile />

        {/* first and last name */}
        <Styled.Wrapper sx="profile__wrapper__content">
          <Styled.Section>
            <Styled.Span>BUSINESS NAME</Styled.Span>
            <Styled.Text>{merchant?.company_name}</Styled.Text>
          </Styled.Section>

          <Styled.Section>
            <Styled.Span>MERCHANT ID</Styled.Span>
            <Styled.Text sx="">{merchant?.cac_number}</Styled.Text>
          </Styled.Section>
        </Styled.Wrapper>

        {/* email and phone number */}
        <Styled.Wrapper sx="profile__wrapper__content">
          <Styled.Section>
            <Styled.Span>EMAIL ADDRESS</Styled.Span>
            <Styled.Text>
              {merchant?.company_email}
            </Styled.Text>
          </Styled.Section>

          <Styled.Wrapper>
            <Styled.Span>INDUSTRY TYPE</Styled.Span>
            <Styled.Text>
              {merchant?.industry_type ? merchant?.industry_type : "N/A"}
            </Styled.Text>
          </Styled.Wrapper>
        </Styled.Wrapper>

        {/* role and address */}
        <Styled.Wrapper sx="profile__wrapper__content">
          <Styled.Section>
            <Styled.Span>CONTACT</Styled.Span>
            <Styled.Text>
              {merchant?.company_phone}
            </Styled.Text>
          </Styled.Section>

          <Styled.Section>
            <Styled.Span>ADDRESS</Styled.Span>
            <Styled.Text>
              {merchant?.office_address}
            </Styled.Text>
          </Styled.Section>
        </Styled.Wrapper>
      </Styled.Wrapper>
    </div>
  );
};

export default MerchantInfoProfile;
