import React, { useState } from "react";
import { Styled } from "../../../../constants/Styled";
import { Button, Input } from "antd";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { schema } from "../../../../schema/Schema";
import { updateAuthPassword } from "../../../../state/updateAuthPassword.Slice";
import { toast } from "react-toastify";

const SecurityPasswordUpdate = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_new_password: "",
    },

    validationSchema: schema.updateAuthPasswordSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const response = await dispatch(updateAuthPassword(values)).unwrap();
        toast.success(response.message);
        setIsLoading(false);
        formik.resetForm();
      } catch (err) {
        toast.error(err.message);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const isPasswordMatch =
    formik.values.new_password === formik.values.confirm_new_password;

  return (
    <>
      <Styled.Section>
        <div className="flex flex-col justify-end items-end text-right space-x-3 absolute right-[15.5rem] top-0 pt-2">
          <Styled.Section sx="error">
            {formik.touched.old_password &&
              formik.errors.old_password &&
              formik.errors.old_password}
          </Styled.Section>

          <Styled.Section sx="error">
            {formik.touched.new_password &&
              formik.errors.new_password &&
              formik.errors.new_password}
          </Styled.Section>

          <Styled.Section sx="error">
            {formik.touched.confirm_new_password &&
              formik.errors.confirm_new_password &&
              isPasswordMatch === false && (
                <> {formik.errors.confirm_new_password}</>
              )}
          </Styled.Section>
        </div>
        <Styled.Caption title="Change Password" />
        <Styled.Wrapper sx="security__wrapper">
          <Styled.Section sx="border__inner change__pass p-8">
            <Styled.Form onSubmit={formik.handleSubmit}>
              <Styled.Section sx="password__auth">
                <Styled.Span>Old Password</Styled.Span>
                <Input.Password
                  className="tingo__input"
                  type="password"
                  placeholder="****"
                  name="old_password"
                  value={formik.values.old_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Styled.Section>

              <Styled.Section sx="password__auth pt-5">
                <Styled.Span>New Password</Styled.Span>
                <Input.Password
                  className="tingo__input"
                  type="password"
                  placeholder="****"
                  name="new_password"
                  value={formik.values.new_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Styled.Section>

              <Styled.Section sx="password__auth pt-5">
                <Styled.Span>Confirm New Password</Styled.Span>
                <Input.Password
                  className="tingo__input"
                  type="password"
                  placeholder="****"
                  name="confirm_new_password"
                  value={formik.values.confirm_new_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Styled.Section>

              <Button className="w-[100%] mt-8" 
              type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Save
              </Button>
            </Styled.Form>
          </Styled.Section>
        </Styled.Wrapper>
      </Styled.Section>
    </>
  );
};

export default SecurityPasswordUpdate;
