import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Styled } from "../../constants/Styled";
import { Images } from "../../constants/Images";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { userEmailVerify } from "../../state/emailVerify.Slice";
import { userEmailVerifyResendToken } from "../../state/resendEmailToken";


const VerifiedEmail = () => {
  const { verification_code } = useParams();
  const dispatch = useDispatch();
  const [ loading, setLoading ] = useState(true);
  const [ emailVerified, setEmailVerified ] = useState(false);
  const { error } = useSelector((state) => state.verifyEmail);

 

  // activate user email
  useEffect(() => {
    const payload = {
      verification_code: verification_code,
    };

    const verifyEmail = async () => {
      setLoading(true);
      try {
        const response = await dispatch(userEmailVerify(payload)).unwrap();
        if (response.message) {
          setEmailVerified(true);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

      verifyEmail();
    
  }, [dispatch, verification_code]);


  // resend verification token to user email
  const handleSendActivationLink = async () => {
    setLoading(true);
    try {
      const resend = {
        verification_code: verification_code,
      };

      const response = await dispatch(userEmailVerifyResendToken(resend)).unwrap();
      setLoading(false);
      toast.success(response.message);
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Styled.Wrapper sx="email__verified__wrapper">
        { loading ? (
          "Verifying Email..."
        ) : emailVerified ? (
          <Styled.Section sx="email__success__content">
            <Styled.Img img={Images.SuccessIcon} alt="" />
            <Styled.Text sx="head text-green-500 text-[20px] font-semibold">
              Success
            </Styled.Text>
            <Styled.Text sx="desc">Your email has been verified</Styled.Text>
          </Styled.Section>
        ) : (
          <Styled.Section sx="email__success__content">
            <Styled.Img img={Images.SuccessIcon} alt="" />
            <Styled.Text sx="head text-red-500 text-[20px] font-semibold">
              {error?.message}
            </Styled.Text>
            <Styled.Text sx="desc pt-2">
              OOPS!!!... Verification token failed
            </Styled.Text>
            <Button
              type="primary"
              size="large"
              className="mt-3"
              loading={loading}
              onClick={handleSendActivationLink}
            >
              Resend Link
            </Button>
          </Styled.Section>
        )}
      </Styled.Wrapper>
    </>
  );
};

export default VerifiedEmail;
