import React, { useState } from "react";
import { Styled } from "../../../constants/Styled";
import { Icons } from "../../../constants/Icons";
import { ModalTitle } from "../../../common";
import FlagFormCard from "./flagForm.Card";

const CardDetails = ({ cardId }) => {
  const [isFlag, setIsFlag] = useState(false);
  return (
    <>
      <Styled.Wrapper sx="card__code">
        <Styled.Wrapper sx="voucher__card__footer">
          <Styled.Section>
            <Styled.Span>CARD NUMBER</Styled.Span>
            <Styled.Text>{cardId?.card_number}</Styled.Text>
          </Styled.Section>

          <Styled.Section>
            <Styled.Span>CARD TYPE</Styled.Span>
            <Styled.Text>{cardId?.one_time_use === true ? "One Time Use" : "Multiple Use"}</Styled.Text>
          </Styled.Section>
        </Styled.Wrapper>

        <Styled.Wrapper sx="voucher__card__footer">
          <Styled.Section>
            <Styled.Span>CARD PURPOSE</Styled.Span>
            <Styled.Text>{cardId?.card_purpose ? cardId?.card_purpose : "N/A"}</Styled.Text>
          </Styled.Section>

          <Styled.Section>
            <Styled.Span>CARD STATUS</Styled.Span>
            <Styled.Text>
              <Styled.Activate color="#32A75A" text={cardId?.status === 1 ? "Active" : "In-active" } />
            </Styled.Text>
          </Styled.Section>
        </Styled.Wrapper>

        <Styled.Wrapper sx="voucher__card__footer">
          <Styled.Section>
            <Styled.Span>CARD HOLDER NAME</Styled.Span>
            <Styled.Text>
              {cardId?.holder_name ? cardId?.holder_name : "N/A"}
            </Styled.Text>
          </Styled.Section>
        </Styled.Wrapper>

        <Styled.Wrapper sx="flag__card__btn__btn">
          <Icons.FlagCardBtn onClick={() => setIsFlag(true)} />
        </Styled.Wrapper>
      </Styled.Wrapper>

      {isFlag && (
        <Styled.TgModal
          title={
            <ModalTitle
              title={`Flag - ${cardId?.card_number}`}
              close={() => setIsFlag(false)}
            />
          }
          open={isFlag}
          width={512}
          footer={null}
          closable={false}
        >
          <FlagFormCard cardNumber={cardId?.card_number} close={() => setIsFlag(false)} />
        </Styled.TgModal>
      )}
    </>
  );
};

export default CardDetails;
