import { Icons } from "../constants/Icons";
import { formatAmount, formatDateAndTime } from "../utils/Utils";

export const dash_trnx_columns = [

  {
    title: 'Holder Name',
    dataIndex: 'card_holder_name',
    render: card_holder_name => `${card_holder_name ? card_holder_name : "N/A"}`,
    sorter: (a, b) => a.card_holder_name - b.card_holder_name,
  },

    {
      title: 'Time / Date',
      dataIndex: 'transaction_created_at',
      key: 'transaction_created_at',
      render: formatDateAndTime,
      sorter: (a, b) => a.transaction_created_at - b.transaction_created_at,
    },

    {
      title: 'Card Number',
      dataIndex: 'card_card_number',
      render: card_card_number => (card_card_number ? card_card_number : "N/A"),
      sorter: (a, b) => a.card_card_number - b.card_card_number,      
    },
    

    {
      title: 'Amount',
      dataIndex: 'transaction_amount',
      key: 'transaction_amount',
      render: formatAmount,
      sorter: (a, b) => a.amount - b.amount,
    },

    {
      title: 'Till',
      dataIndex: 'till',
      sorter: (a, b) => a.till - b.till,
      width: "50px"     
    },

    {
      title: 'Status',
      dataIndex: 'transaction_status',
      key: 'transaction_status',
      render: transaction_status => <span className={`status-before-content ${transaction_status}`}>{transaction_status.toLowerCase()}</span>,
      sorter: (a, b) => a.transaction_status- b.transaction_status,
      width: "110px"
    },

    {
      title: "Actions",
      dataIndex: 'actions',
      key: 'actions',
      render: actions => <Icons.ActionDot size={20} />,
      align: 'center',
    }

  ];



  export const kyc_data = [
    {
      _id: 1,
      icon: <Icons.ActivateBusinessIcon />,
      title: "Activate Your Business",
      desc: "Update the merchant profile",
      step: <Icons.Number1 />,
    },

    {
      _id: 2,
      icon: <Icons.PersonalDetailIcon />,
      title: "Personal Details",
      desc: "Update the personal details",
      step: <Icons.Number2 />,
    },

    {
      _id: 3,
      icon: <Icons.DashboardTourIcon />,
      title: "Dashboard Tour",
      desc: "Explore dashboard tour",
      step: <Icons.Number3 />,
    }
  ]



  export const tour_steps = [
    {
      title: 'Quickly access all content',
      description: (
        <>
          <div>You can find data, transactions, reports, stories, and other items by opening your dashboard.</div>
        <div>Search using keywords to quickly find what you’re looking for</div>
        </>
      ),
      placement: 'topRight',
    },

    {
      title: 'Right',
      description: 'On the right of target.',
      placement: 'top',

    },
  ];