import { formatAmount, formatDateAndTime } from "../utils/Utils";

export const trnx_columns = [
  {
    title: "ID Number",
    dataIndex: "transaction_id",
    sorter: (a, b) => a.transaction_id - b.transaction_id,
  },

  {
    title: "Date/Time",
    dataIndex: "transaction_created_at",
    render: formatDateAndTime,
    sorter: (a, b) => a.transaction_created_at - b.transaction_created_at,
  },

  {
    title: "Holder Name",
    dataIndex: "card_holder_name",
    key: "card_holder_name",
    render: (card_holder_name) =>
      `${card_holder_name ? card_holder_name : "N/A"}`,
    sorter: (a, b) => a.card_holder_name - b.card_holder_name,
  },

  {
    title: "Card Number",
    dataIndex: "card_card_number",
    render: (card_card_number) => (card_card_number ? card_card_number : "N/A"),
    sorter: (a, b) => a.card_card_number - b.card_card_number,
  },

  {
    title: "Partner",
    dataIndex: "partner",
    render: (partner) => (partner ? partner : "N/A"),
    sorter: (a, b) => a.partner - b.partner,
  },

  {
    title: "Amount",
    dataIndex: "transaction_amount",
    key: "transaction_amount",
    render: formatAmount,
    sorter: (a, b) => a.amount - b.amount,
  },

  {
    title: "Till",
    dataIndex: "till",
    render: (till) => (till ? till : "N/A"),
    sorter: (a, b) => a.till - b.till,
  },

  {
    title: "Status",
    dataIndex: "transaction_status",
    key: "transaction_status",
    render: (transaction_status) => (
      <span className={`status-before-content ${transaction_status}`}>
        {transaction_status.toLowerCase()}
      </span>
    ),
    sorter: (a, b) => a.status - b.status,
    align: "center",
  },
];
