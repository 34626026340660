import React from 'react';

const Stepper = ({ steps, currentStep }) => {
  const currentStepData = steps[currentStep - 1];

  return (
    <div className="w-full">
      <div className="step-header">
        {steps.map((stepData, index) => (
          <div
            key={index}
            className={`custom__step ${currentStep >= index + 1 ? 'steps__active' : ''}`}
          >
            {stepData.title}
          </div>
        ))}
      </div>

      <div className='pb-6'>
        {currentStepData.content}
      </div>
    </div>
  );
};

export default Stepper;
