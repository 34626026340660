import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  userRequest } from '../app/config';

const initialState = {
  physicalPayment: [],
  loading: false,
  error: null,
};

export const physicalCheckout = createAsyncThunk('merchant/checkout/physical',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userRequest.post(`/merchant/checkout`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const physicalCheckoutSlice = createSlice({
  name: 'physicalPayment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(physicalCheckout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(physicalCheckout.fulfilled, (state, action) => {
        state.loading = false;
        state.physicalPayment = action.payload;
      })
      .addCase(physicalCheckout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })   
  },
});

export default physicalCheckoutSlice.reducer;
