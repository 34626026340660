import React, {  useEffect, useState } from "react";
import { Styled } from "../../constants/Styled";
import { Icons } from "../../constants/Icons";
import { menuItems } from "../../data/Data";
import Navbar from "../navbar/Navbar";
import { Images } from "../../constants/Images";


const Sidebar = ({ children }) => {
    const [ open, setOpen ] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setOpen(window.innerWidth > 768);
    };
    
    handleResize(); 
    
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


    return (
      <Styled.Wrapper sx="sidebar__new">
            <Styled.Section sx={`sidebar__new__side ${open ? "expand" : "collapsed"}`}>
            <Icons.ToggleIcon
                sx={`sidebar__new__toggle hidden ${!open && "rotate-180"}`}
                onClick={() => setOpen(!open)}
            />
             <Styled.Section sx={open ? "sidebar__logo" : "block"}>
                <Styled.Img img={Images.TingoLogo} sx="w-[45px] h-[34px]"/>
                <Styled.Text sx={!open && "scale-0"}><Styled.Img img={Images.TingoMerchant} alt="" width="130" /></Styled.Text>
            </Styled.Section>

                <Styled.List sx={`sidebar__new__menu__item ${open ? "pt-6" : "pt-3"}`}>
                {menuItems?.map((menu, i) => (
                    <Styled.Linked
                        as={menu?.link}
                        key={i}
                        sx={`group ${window.location.pathname === menu.link ? "active" : "sidebar__new__menu__item"}`}>
                        <>{open ? menu.icon : <Styled.Span>{menu?.icon}</Styled.Span>}</>
                        <Styled.Span
                            style={{
                                transitionDelay: `${i + 3}00ms`,
                            }}
                            sx={`${!open && " opacity-0 translate-x-28 overflow-hidden"
                                }`}
                        >
                            {menu?.title}
                        </Styled.Span>
                        <Styled.Span sx={`${open && "hidden"} drop-shadow-lg 
                            group-hover:px-2 group-hover:py-1 group-hover:left-[5.5rem] group-hover:duration-300 group-hover:w-fit
                            `}
                        >
                            {menu?.title}
                        </Styled.Span>
                    </Styled.Linked>
                ))}
            </Styled.List>
            </Styled.Section>
            
                <Styled.Section sx={`sidebar__new__content w-full ${open ? "pl-[14.5rem]" : "pl-[5rem]"}`}>
                    <Styled.Card sx="navbar__new__header">
                        <Navbar />
                    </Styled.Card>              
                        {children}
                </Styled.Section>
            
      </Styled.Wrapper>
    );
};
export default Sidebar;
