import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  userRequest } from '../app/config';

const initialState = {
  transactionAnalytics: [],
  loading: false,
  error: null,
};

export const fetchTransactionAnalytics = createAsyncThunk('/transaction/analytics', async (_, { rejectWithValue }) => {
  try {
    const response = await userRequest.get(`/merchant/dashboard/analytics/transaction`,);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});



const transactionAnalyticsSlice = createSlice({
  name: 'transactionAnalytics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactionAnalytics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTransactionAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.transactionAnalytics = action.payload;
      })
      .addCase(fetchTransactionAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});


export default transactionAnalyticsSlice.reducer;
