import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from '../app/config';


const initialState = {
  walletTransactions: [],
  loading: false,
  error: null,
};


export const fetchWalletTransactions = createAsyncThunk('merchant/wallet/transactions',
  async ({page, limit}, { rejectWithValue }) => {
    try {
      const response = await userRequest.get(`/merchant/wallet/transactions/?page=${page}&limit=${limit}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const walletTransactionsSlice = createSlice({
  name: 'walletTransactions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWalletTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWalletTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.walletTransactions = action.payload;
      })
      .addCase(fetchWalletTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })  
  },
});

export default walletTransactionsSlice.reducer;
