import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from '../app/config';


const initialState = {
  pendingInvites: [],
  loading: false,
  error: null,
};

export const fetchAllPendingInvites = createAsyncThunk('merchant/all/roles',
  async ({page, limit}, { rejectWithValue }) => {
    try {
      const response = await userRequest.get(`/merchant/staffs/invite?page=${page}&limit=${limit}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



const pendingInvitesSlice = createSlice({
  name: 'pendingInvites',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPendingInvites.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllPendingInvites.fulfilled, (state, action) => {
        state.loading = false;
        state.pendingInvites = action.payload;
      })
      .addCase(fetchAllPendingInvites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }) 
  },
});

export default pendingInvitesSlice.reducer;
