import React, { useEffect, useState } from "react";
import { DashboardLayout, PhysicalCard, QRCodePayment } from "../../components";
import { Styled } from "../../constants/Styled";
import { Input, Select, Table } from "antd";
import { checkoutCol } from "../../data/checkoutHistory.Data";
import { useDispatch, useSelector } from "react-redux";
import { fetchCheckoutHistory } from "../../state/checkHistory.Slice";
import { options } from "../../data/Data";
import { Icons } from "../../constants/Icons";

const CheckOut = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { checkoutHistory, loading } = useSelector((state) => state.checkoutHistory);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCheckoutHistory({ page: currentPage, limit: pageSize }));
  }, [dispatch, currentPage, pageSize]);

  const checkedData = checkoutHistory?.data?.checkouts;

  // pagination
  const handlePageChange = (page, newPageSize) => {
    setCurrentPage(page);
    setPageSize(newPageSize);
  };

  return (
    <>
      <DashboardLayout>
       <Styled.InnerWrapper>
       <Styled.Wrapper sx="physical__card__wrapper pt-[24px]">
          <PhysicalCard />
          <QRCodePayment />
        </Styled.Wrapper>

        <Styled.Wrapper className="pt-5">
          <Input
            className="trnx__search__input"
            type="search"
            size="large"
            prefix={<Icons.IconSearch />}
            placeholder="Search Transactions"
          />

          <Select
            className="trnx__search__select"
            defaultValue="Filter By"
            size="large"
            options={options}
            bordered={false}
          />
         
        </Styled.Wrapper>
        <Styled.Wrapper sx="table__box">
          <Table
            columns={checkoutCol}
            dataSource={checkedData}
            loading={loading}
            rowKey="id"
            rowSelection="checkbox"
            pagination={{
              total: pageSize * checkoutHistory?.data?.totalPages,
              pageSize: pageSize,
              current: currentPage,
              onChange: handlePageChange,
              pageSizeOptions: ["10", "20", "30", "40", "50", "60"]
            }}
            className="centered-pagination"
            scroll={{ x: true}}
          />
        </Styled.Wrapper>
       </Styled.InnerWrapper>
      </DashboardLayout>
    </>
  );
};

export default CheckOut;
