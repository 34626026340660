import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Styled } from "../../constants/Styled";

const RegisterSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const userEmail = location.state && location.state.company_email;

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      navigate("/");
    }, 10 * 1000);

    return () => {
      clearTimeout(redirectTimeout);
    };
  }, [navigate]);

  return (
    <>
      <Styled.Wrapper sx="register__success__wrapper">
        {userEmail ? (
          <Styled.Section sx="register__success__content">
            <Styled.Text sx="head">Verify your Email Address</Styled.Text>
            <Styled.Text sx="desc">
              A link has been sent to <Styled.Span>{userEmail}</Styled.Span>{" "}
              <br />
              Click on the link sent to verify your email
            </Styled.Text>
          </Styled.Section>
        ) : (
          <Styled.Section sx="register__success__content">
            <Styled.Text sx="head">No Active User</Styled.Text>
            <Styled.Text sx="desc">
              OOPS!!!... You're not accessing right url
            </Styled.Text>
          </Styled.Section>
        )}
      </Styled.Wrapper>
    </>
  );
};

export default RegisterSuccess;
