import React, { useState } from "react";
import { Styled } from "../../../constants/Styled";
import { bank__list } from "../../../data/bank.Data";
import { Button, message } from "antd";

const AddBank = ({ closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isClose, setIsClose] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      message.success("Account added successfully");
      setIsClose(closeModal);
    }, 3000);
  };

  return (
    <>
      <Styled.Wrapper sx="pt-8">
        {isClose ? "" : ""}
        <Styled.Section sx="new__auth__wrapper">
          <Styled.Span>Bank</Styled.Span>
          <Styled.Select>
            {bank__list.map((bank, i) => (
              <option value={bank.value} key={i}>
                {bank.bank_name}
              </option>
            ))}
          </Styled.Select>
        </Styled.Section>

        <Styled.Section sx="new__auth__wrapper pt-3">
          <Styled.Span>Account Number</Styled.Span>
          <Styled.Input placeholder="Account Number" />
        </Styled.Section>

        <Styled.Section sx="new__auth__wrapper pt-3">
          <Styled.Span>Account Name</Styled.Span>
          <Button size="large" className="w-full" disabled>
            
          </Button>
        </Styled.Section>

        <Button 
        type="primary" 
        size="large" 
        className="cashout__btn"
        onClick={handleSubmit}
        loading={isLoading}
        >
          Save Bank
        </Button>
      </Styled.Wrapper>
    </>
  );
};

export default AddBank;
