import React, { useEffect, useState } from "react";
import {
  CardRecordTransaction,
  DashboardLayout,
  TransactionDetails,
} from "../../components";
import { Drawer, Dropdown, Input, Menu, Select, Space, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions } from "../../state/transactionSlice";
import { trnx_columns } from "../../data/transactionData";
import { Icons } from "../../constants/Icons";
import { Styled } from "../../constants/Styled";
import { CustomDateRangePicker } from "../../common";
import { options } from "../../data/Data";
import { exportToPDF } from "../../utils/Utils";

const Transactions = () => {
  // drawers
  const [ selectedTrnx, setSelectedTrnx ] = useState(null);
  const [ trnxDrawerVisible, setTrnxDrawerVisible] = useState(false);

  const dispatch = useDispatch();
  const { transactions, loading } = useSelector((state) => state.transactions);
  const [ currentPage, setCurrentPage] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);

  useEffect(() => {
    dispatch(fetchTransactions({ page: currentPage, limit: pageSize }));
  }, [dispatch, currentPage, pageSize]);

  const allCustomers = transactions?.data?.transactions;

  const handlePageChange = (page, newPageSize) => {
    setCurrentPage(page);
    setPageSize(newPageSize);
  };

  const handleMenuClick = async ({ key, trnxId }) => {
    if (key === "view") {
      const customer = allCustomers.find((cust) => cust.transaction_id === trnxId);
      setSelectedTrnx(customer);
      setTrnxDrawerVisible(true);
    }
  };

  const renderActionMenu = (trnxId) => (
    <Menu onClick={({ key }) => handleMenuClick({ key, trnxId })}>
      <Menu.Item key="view">View</Menu.Item>
    </Menu>
  );

  const actionColumn = {
    title: "Actions",
    dataIndex: "transaction_id",
    key: "actions",
    render: (_, record) => (
      <Dropdown
        overlay={renderActionMenu(record.transaction_id)}
        placement="bottomRight"
        arrow
      >
        <Icons.ActionDot size={20} onClick={(e) => e.preventDefault()} />
      </Dropdown>
    ),
    align: "center",
  };

  const trnx__action = [...trnx_columns, actionColumn];

  const closeDrawer = () => {
    setSelectedTrnx(null);
    setTrnxDrawerVisible(false);
  };

  return (
    <DashboardLayout>
      <Styled.Wrapper sx="pb-[24px]">
        <CardRecordTransaction />
      </Styled.Wrapper>

      <Styled.InnerWrapper>
        <Styled.Wrapper>
          <Styled.Section sx="merchant__filters">
            <Input
              className="trnx__search__input"
              type="search"
              size="large"
              prefix={<Icons.IconSearch />}
              placeholder="Search Transactions"
            />

            <Styled.RightButtonWrapper>
              <Select
                className="trnx__search__select"
                defaultValue="Filter By"
                size="large"
                options={options}
                bordered={false}
              />
              <CustomDateRangePicker />
              <Styled.ExportButtonWrapper>
                <Icons.ReadPDF onClick={() => exportToPDF(allCustomers, 'Tingo Mobile', 'Transaction Report', trnx__action, 'landscape')}/>
                <Icons.ReadExcel />
              </Styled.ExportButtonWrapper>
            </Styled.RightButtonWrapper>
          </Styled.Section>
        </Styled.Wrapper>

        <Styled.Wrapper sx="table__box">
          <Table
            dataSource={allCustomers}
            columns={trnx__action}
            rowKey="id"
            rowSelection="checkbox"
            loading={loading}
            pagination={{
              total: pageSize * transactions?.data?.totalPages,
              pageSize: pageSize,
              current: currentPage,
              showSizeChanger: true,
              onChange: handlePageChange,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} transactions`,
              pageSizeOptions: ["10", "20", "30", "40", "50", "60", "70", "80", "100"],
            }}
            className="centered-pagination"
            scroll={{ x: true}}
          />
        </Styled.Wrapper>
      </Styled.InnerWrapper>

      {/* view single transaction details */}
      {selectedTrnx && (
           <Drawer
           title="Transaction Details"
           placement="right"
           width={500}
           open={trnxDrawerVisible}
           closable={false}
           onClose={closeDrawer}
           extra={
             <Space>
               <Icons.CancelApproIcon onClick={closeDrawer} />
             </Space>
           }
         >
           <TransactionDetails trnxId={selectedTrnx} />
         </Drawer>
      )}
     
    </DashboardLayout>
  );
};

export default Transactions;
