import React from "react";
import { ComplaintHelp, DashboardLayout, HelpCard, QuickHelp } from "../../components";
import { Styled } from "../../constants/Styled";
import {  Input } from "antd";
import { Icons } from "../../constants/Icons";
import { faq, get_started } from "../../data/helpCenter.Data";
import { HelpFaq, HelpLabel } from "../../common";

const Help = () => {
  return (
    <>
      <DashboardLayout>

        {/* help center */}
        <Styled.Wrapper sx="help__center__head">
          <Styled.Text>Help Center</Styled.Text>
          <Input
            className="trnx__search__input"
            type="search"
            size="large"
            prefix={<Icons.IconSearch />}
            placeholder="Search "
          />
        </Styled.Wrapper>

        <Styled.InnerWrapper>

          
      
      <HelpLabel title="Getting Started" />
        <Styled.Section sx="help__get__start">
            {get_started.map((item, i)=> (
                <HelpCard key={i} icon={item.icon} title={item.title} desc={item.desc} />
            ))}
        </Styled.Section>
        
        <HelpLabel title="How To" clx="pt-[32px]"/>
        <Styled.Section sx="help__get__start">
            {get_started.map((item, i)=> (
                <HelpCard key={i} icon={item.icon} title={item.title} desc={item.desc} />
            ))}
        </Styled.Section>

        <HelpLabel title="FAQ" clx="pt-[32px]"/>
        <Styled.Text sx="help__line"></Styled.Text>

          <Styled.Section sx="help__faq__wrapper">
            {faq.map((item, index) => (
              <HelpFaq key={index} desc={item.desc}/>
            ))}
          </Styled.Section>

          <Styled.Wrapper sx="help__form">
              <ComplaintHelp />
              <QuickHelp />
          </Styled.Wrapper>
        </Styled.InnerWrapper>
      </DashboardLayout>
    </>
  );
};

export default Help;
