import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from '../app/config';


const initialState = {
  deleteRole: [],
  loading: false,
  error: null,
};

export const deleteCreatedRole = createAsyncThunk('merchant/delete/created/role',
  async (id, { rejectWithValue }) => {
    try {
      const response = await userRequest.delete(`/merchant/role/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



const deleteRoleSlice = createSlice({
  name: 'deleteRole',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteCreatedRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCreatedRole.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteRole = action.payload;
      })
      .addCase(deleteCreatedRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }) 
  },
});

export default deleteRoleSlice.reducer;
