import React, { useState } from "react";
import { Styled } from "../../../constants/Styled";
import { Button, Divider } from "antd";
import { ConfirmTrnxDesc, OTPInputs } from "../../../common";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { bulkCardsStep3 } from "../../../state/bulkCard3.Slice";
import { useFormik } from "formik";
import { schema } from "../../../schema/Schema";
import { fetchCards } from "../../../state/cardsSlice";
import { formatAmount, showCustomSuccessModal } from "../../../utils/Utils"

const ConfirmTransactionBulks = ({ close }) => {
  const dispatch = useDispatch();
  const [ closeModal, setCloseModal ] = useState(false);
  const { bulkcards2 } = useSelector((state) => state.bulkcards2);
  const [ loading, setLoading ] = useState(false);


  const formik = useFormik({
    initialValues: {
      batchCode: bulkcards2?.data?.batchCode,
      pin: ""
    },

    validationSchema: schema.bulkGenerate3,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await dispatch(bulkCardsStep3(values)).unwrap();
        showCustomSuccessModal(response?.message, 5000, "Success");
        setLoading(false);
        setCloseModal(close)
        dispatch(fetchCards({ page: 1, limit: 10 }));
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Styled.Form sx="px-[8px] pb-[28px] pt-2" onSubmit={formik.handleSubmit}>
      {closeModal ? "" : ""}
      <Divider className="mt-[10px] mb-[32px]" />
      <Styled.Wrapper sx="confirm__bulk">
        <ConfirmTrnxDesc
          title="Batch Number"
          value={bulkcards2?.data?.batchCode}
        />
        <ConfirmTrnxDesc
          title="Batch Name"
          value={bulkcards2?.data?.batchName}
        />
        <ConfirmTrnxDesc
          title="Cards Quantity"
          value={bulkcards2?.data?.cardsQuantity}
        />
        <ConfirmTrnxDesc
          title="Captured Mobile Numbers"
          value={bulkcards2?.data?.capturedPhoneNumber}
        />
        <ConfirmTrnxDesc
          title="Single Card Value"
          value={formatAmount(bulkcards2?.data?.cardValue)}
        />
        <ConfirmTrnxDesc
          title="Total Amount"
          value={formatAmount(bulkcards2?.data?.totalAmt)}
        />
      </Styled.Wrapper>

      <Styled.Span sx="trnx__tag">Enter Transaction Pin</Styled.Span>
      <Styled.Section sx="trnx__pin__input">
          <OTPInputs
            length={4}
            value={formik.values.pin}
            onChange={(otpValue) => formik.setFieldValue("pin", otpValue)}
            onBlur={formik.handleBlur}
          />
           <Styled.Section sx="error">
          {formik.touched.pin && formik.errors.pin && formik.errors.pin}
        </Styled.Section>
          </Styled.Section>

      <Styled.Section sx="trnx__pay__btn">
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          loading={loading}
        >
          Confirm
        </Button>
      </Styled.Section>
    </Styled.Form>
  );
};

export default ConfirmTransactionBulks;
