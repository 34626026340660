import React from 'react'
import { Styled } from '../../../../constants/Styled'
import { Button } from 'antd'
import { useSelector } from 'react-redux';
import { Icons } from '../../../../constants/Icons';

const PersonalProfile = ({edit }) => {
    const { user } = useSelector((state) => state.auth);
  
    
  return (
    <div>
         <Styled.Wrapper sx="border__inner mt-5">
          <Styled.Section sx="merchant__overview__header__wrapper mb-[24px]">
            <Styled.Section>
              <Styled.Text sx="info__text">Personal Information</Styled.Text>
            </Styled.Section>
            <Button size="large" onClick={edit} className="btn__icon__gap__2">
              Edit <Icons.EditIcon />
            </Button>
          </Styled.Section>

          {/* first and last name */}
          <Styled.Wrapper sx="profile__wrapper__content">
            <Styled.Section>
              <Styled.Span>First Name</Styled.Span>
              <Styled.Text>{user?.profile?.first_name ? user?.profile?.first_name : "N/A"}</Styled.Text>
            </Styled.Section>

            <Styled.Section>
              <Styled.Span>Last Name</Styled.Span>
              <Styled.Text sx="">
                {user?.profile?.last_name || "N/A"}
              </Styled.Text>
            </Styled.Section>
          </Styled.Wrapper>

          {/* email and phone number */}
          <Styled.Wrapper sx="profile__wrapper__content">
            <Styled.Section>
              <Styled.Span>Email address</Styled.Span>
              <Styled.Text>
                {user?.profile?.email_address || "N/A"}
              </Styled.Text>
            </Styled.Section>

            <Styled.Wrapper>
              <Styled.Span>Phone Number</Styled.Span>
              <Styled.Text>
                {user?.profile?.phone_number || "N/A"}
              </Styled.Text>
            </Styled.Wrapper>
          </Styled.Wrapper>

          {/* role and address */}
          <Styled.Wrapper sx="profile__wrapper__content">
            <Styled.Section>
              <Styled.Span>Role</Styled.Span>
              <Styled.Text>
                {user?.profile?.role || "N/A"}
              </Styled.Text>
            </Styled.Section>

            <Styled.Section>
              <Styled.Span >Address</Styled.Span>
              <Styled.Text >
                {user?.profile?.office_address || "N/A"}
              </Styled.Text>
            </Styled.Section>
          </Styled.Wrapper>
        </Styled.Wrapper>
    </div>
  )
}

export default PersonalProfile