import React from "react";
import { Styled } from "../../../constants/Styled";
import { Button } from "antd";

const ComplaintHelp = () => {
  return (
    <Styled.Form>
      <Styled.Text>Complaints/Enquiry</Styled.Text>

      <Styled.Section sx="new__auth__wrapper">
        <Styled.Span>Subject</Styled.Span>
        <Styled.Input type="text" placeholder="Subject" name="" />
      </Styled.Section>

      <Styled.Section sx="new__auth__wrapper pt-3">
        <Styled.Span>Description</Styled.Span>
        <Styled.Message type="text" placeholder="Description" name="" />
      </Styled.Section>

      <Button type="primary">Submit</Button>
    </Styled.Form>
  );
};

export default ComplaintHelp;
