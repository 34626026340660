
import { configureStore } from '@reduxjs/toolkit';
import authReducer from "../state/authSlice";
import customersReducer from "../state/customerSlice";
import transactionsReducer from "../state/transactionSlice";
import cardsReducer from "../state/cardsSlice";
import customerCardsReducer from "../state/customerCardsSlice";
import customerTnxReducer from "../state/customerTrnxSlice";
import registerReducer from "../state/registerSlice";
import cardGenerateSingleReducer from "../state/cardGenerateSingle.Slice";
import physicalCheckoutReducer from '../state/physicalCheckout.Slice';
import bulkCardReducer from "../state/bulkCard.Slice";
import bulkcards2Reducer from "../state/bulkCard2.Slice";
import bulkcards3Reducer from "../state/bulkCard3.Slice";
import checkoutHistoryReducer from "../state/checkHistory.Slice";
import physicalOTPReducer from "../state/physicalOTP.Slice";
import walletReducer from "../state/wallet.Slice";
import walletTransactionsReducer from "../state/walletTransactions.Slice";
import verifyEmailReducer from "../state/emailVerify.Slice";
import forgotPasswordReducer from "../state/forgotPassword.Slice";
import verifyForgotPasswordReducer from "../state/verifyForgotPassword.Slice";
import resendForgotPasswordTokenReducer from "../state/resendForgotPasswordToken.Slice"; 
import rolesReducer from "../state/roles.Slice";
import inviteStaffReducer from "../state/inviteStaff.Slice";
import pendingInvitesReducer from '../state/pendingInvite.Slice';
import resendInviteReducer from "../state/resendInvite.Slice";
import revokeInviteReducer from "../state/revokeInvite.Slice";
import deleteRoleReducer from "../state/deleteRole.Slice";
import acceptInvitesReducer from "../state/acceptInvite.Slice";
import updateStaffReducer from "../state/updateStaffProfile.Slice";
import auditLogReducer from "../state/auditLog.Slice";
import acceptedStaffReducer from "../state/activeMember.Slice";
import createRoleReducer from "../state/createRole.Slice";
import industryTypeReducer from "../state/industryType.Slice";
import jobDesignationReducer from "../state/jobDesignation.Slice";
import singleCardTrnxReducer from "../state/singleCardTrn.Slice";
import createPinReducer from "../state/createPin.Slice";
import updateCreatePinReducer from "../state/updateCreatePin.Slice";
import updatePasswordReducer from "../state/updateAuthPassword.Slice";
import merchantInfoReducer from "../state/settingMerchantDetail.Slice";
import dashboardAnalyticsReducer from "../state/dashboardAnalytics.Slice";
import transactionAnalyticsReducer from "../state/transactionAnalytics.Slice";
import flagCardReducer from "../state/flagCard.Slice";
import singlecustomerReducer from "../state/singleCustomerSlice";
import flagCustomerReducer from "../state/flagCustomer.Slice";



const store = configureStore ({
    reducer : {
        auth: authReducer,
        customers: customersReducer,
        transactions: transactionsReducer,
        cards: cardsReducer,
        customercards: customerCardsReducer,
        customertnx: customerTnxReducer,
        singlecustomer: singlecustomerReducer,
        register: registerReducer,
        cardGenerate: cardGenerateSingleReducer,
        physicalPayment: physicalCheckoutReducer,
        bulkcards: bulkCardReducer,
        bulkcards2: bulkcards2Reducer,
        bulkcards3: bulkcards3Reducer,
        checkoutHistory: checkoutHistoryReducer,
        otp: physicalOTPReducer,
        wallet: walletReducer,
        walletTransactions: walletTransactionsReducer,
        verifyEmail: verifyEmailReducer,
        forgotPassword: forgotPasswordReducer,
        verifyForgotPassword: verifyForgotPasswordReducer,
        resendPasswordToken: resendForgotPasswordTokenReducer,
        roles: rolesReducer,
        pendingInvites: pendingInvitesReducer,
        inviteStaff: inviteStaffReducer,
        resendInvite: resendInviteReducer,
        revokeInvite: revokeInviteReducer,
        deleteRole: deleteRoleReducer,
        acceptInvites: acceptInvitesReducer,
        updateStaff: updateStaffReducer,
        auditLog: auditLogReducer,
        acceptedStaff: acceptedStaffReducer,
        createRole: createRoleReducer,
        industryType: industryTypeReducer,
        jobDesignation: jobDesignationReducer,
        singleCardTrnx: singleCardTrnxReducer,
        createPin: createPinReducer,
        updateCreatePin: updateCreatePinReducer,
        updatePassword: updatePasswordReducer,
        merchantInfo: merchantInfoReducer,
        dashboardAnalytics: dashboardAnalyticsReducer,
        transactionAnalytics: transactionAnalyticsReducer,
        flagCard: flagCardReducer,
        flagCustomer: flagCustomerReducer,
    }
});


export default store