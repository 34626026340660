import axios from "axios";

export const apiUrl = process.env.REACT_APP_BASE_URL;
export const apiKey = process.env.REACT_APP_API_KEY;

export const publicRequest = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export const userRequest = axios.create({
  baseURL: apiUrl,
});

export const setAuthToken = (TOKEN) => {
  userRequest.defaults.headers.Authorization = `Bearer ${TOKEN}`;
};

const TOKEN = JSON.parse(localStorage.getItem("user"))?.accessToken;

if (TOKEN) {
  setAuthToken(TOKEN);
}

