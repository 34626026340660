import React from "react";
import { Styled } from "../../../constants/Styled";
import SecurityPasswordUpdate from "./profile/Security.PasswordUpdate";
import SecurityNotification from "./profile/Security.Notification";
import Security2Factor from "./profile/Security.2Factor";
import SecurityPinUpdate from "./profile/Security.PinUpdate";

const SettingSecurity = () => {
  return (
    <>
      <Styled.Wrapper sx="merchant__header security__height">
        <Styled.Section sx="change__pswrd__pin">
          <SecurityPasswordUpdate />
          <SecurityPinUpdate />
        </Styled.Section>

        <Styled.Section sx="change__pswrd__pin">
          <SecurityNotification />
          <Security2Factor />
        </Styled.Section>
      </Styled.Wrapper>
    </>
  );
};

export default SettingSecurity;
