import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { publicRequest } from "../app/config";

const initialState = {
  jobDesignation: [],
  loading: false,
  error: null,
};

export const fetchAllJobDesignation = createAsyncThunk(
  "customers/all/job/designation",
  async (_, { rejectWithValue }) => {
    try {
      const response = await publicRequest.get(`/merchant/designations`,);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const jobDesignationSlice = createSlice({
  name: "jobDesignation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // customers cards
      .addCase(fetchAllJobDesignation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllJobDesignation.fulfilled, (state, action) => {
        state.loading = false;
        state.jobDesignation = action.payload;
      })
      .addCase(fetchAllJobDesignation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default jobDesignationSlice.reducer;
