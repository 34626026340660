import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  userRequest } from '../app/config';

const initialState = {
  transactions: [],
  loading: false,
  error: null,
};

export const fetchTransactions = createAsyncThunk('admin/fetchTransactions', async ({page, limit}, { rejectWithValue }) => {
  try {
    const response = await userRequest.get(`/merchant/card/transactions/?page=${page}&limit=${limit}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.transactions = action.payload;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});


export default transactionsSlice.reducer;
