import React, { useState } from "react";
import { Styled } from "../../../constants/Styled";
import { Button, Modal } from "antd";
import { ModalTitle } from "../../../common";
import ConfirmPaymentCheckout from "./Confirm.Payment.Checkout";
import { useFormik } from "formik";
import { schema } from "../../../schema/Schema";
import { useDispatch } from "react-redux";
import { physicalCheckout } from "../../../state/physicalCheckout.Slice";
import { toast } from "react-toastify";

const PhysicalCard = () => {
  const [confirmPay, setConfirmPay] = useState(false);
  const [loading, setLoading] = useState(false);
  

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      amount: "",
      voucher_card_number: "",
      checkout_method: "VOUCHER_CODE",
    },

    validationSchema: schema.physicalSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await dispatch(physicalCheckout(values)).unwrap();
        setLoading(false);
        setConfirmPay(true);
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <Styled.Wrapper sx="physical__card">
        <Styled.Text sx="checkout__title">Pay with Physical Card</Styled.Text>
        <Styled.Section sx="physical__card__content">
          <Styled.Form onSubmit={formik.handleSubmit}>
            <Styled.Section sx="new__auth__wrapper">
              <Styled.Span>Amount</Styled.Span>
              <Styled.Input
                type="number"
                placeholder="N 0.00"
                name="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              <Styled.Section sx="error">
                {formik.touched.amount &&
                  formik.errors.amount &&
                  formik.errors.amount}
              </Styled.Section>
            </Styled.Section>

            <Styled.Section sx="new__auth__wrapper pt-5">
              <Styled.Span>Voucher Code</Styled.Span>
              <Styled.Input
                type="text"
                placeholder="xxxx xxxx xxxx"
                name="voucher_card_number"
                value={formik.voucher_card_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              <Styled.Section sx="error">
                {formik.touched.voucher_card_number &&
                  formik.errors.voucher_card_number &&
                  formik.errors.voucher_card_number}
              </Styled.Section>
            </Styled.Section>

            <Button
              type="primary"
              loading={loading}
              htmlType="submit"
              className="mt-8"
              size="large"
            >
              Make Payment
            </Button>
          </Styled.Form>
        </Styled.Section>
      </Styled.Wrapper>

      {confirmPay && (
        <Modal
          title={
            <ModalTitle
              title="Confirm Payment"
              close={() => setConfirmPay(false)}
            />
          }
          open={() => setConfirmPay(true)}
          closable={false}
          footer={null}
          width={400}
        >
          <ConfirmPaymentCheckout close={() => setConfirmPay(false)}/>
        </Modal>
      )}
    </>
  );
};

export default PhysicalCard;
