import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  userRequest } from '../app/config';

const initialState = {
  createRole: [],
  loading: false,
  error: null,
};

export const createNewRole = createAsyncThunk('merchant/owner/create/role',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userRequest.post(`/merchant/role`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const createRoleSlice = createSlice({
  name: 'createRole',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createNewRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewRole.fulfilled, (state, action) => {
        state.loading = false;
        state.createRole = action.payload;
      })
      .addCase(createNewRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })   
  },
});

export default createRoleSlice.reducer;
