import React, { useEffect } from "react";
import { Styled } from "../../constants/Styled";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllJobDesignation } from "../../state/jobDesignation.Slice";

const PersonalDetails = ({ formik }) => {
  const dispatch = useDispatch();
  const { jobDesignation } = useSelector((state) => state.jobDesignation);

  useEffect(() => {
    dispatch(fetchAllJobDesignation());
  },[dispatch])

  const job_des = jobDesignation?.data;
  
  console.log("ALL JOB DESCRIPTIONS:", job_des)
  return (
    <Styled.Wrapper>
      <Styled.Section sx="new__auth__wrapper ">
        <Styled.Span>First Name</Styled.Span>
        <Styled.Input
          type="text"
          placeholder="First Name"
          name="first_name"
          value={formik.values.first_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Styled.Section sx="error">
          {formik.touched.first_name &&
            formik.errors.first_name &&
            formik.errors.first_name}
        </Styled.Section>
      </Styled.Section>

      <Styled.Section sx="new__auth__wrapper pt-5">
        <Styled.Span>Last Name</Styled.Span>
        <Styled.Input
          type="text"
          placeholder="Last Name"
          name="last_name"
          value={formik.values.last_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Styled.Section sx="error">
          {formik.touched.last_name &&
            formik.errors.last_name &&
            formik.errors.last_name}
        </Styled.Section>
      </Styled.Section>

      <Styled.Section sx="new__auth__wrapper pt-5">
        <Styled.Span>Phone Number</Styled.Span>
        <Styled.Input
          type="text"
          placeholder="Phone Number"
          name="company_phone_number"
          value={formik.values.company_phone_number}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          maxLength={11}
        />
        <Styled.Section sx="error">
          {formik.touched.company_phone_number &&
            formik.errors.company_phone_number &&
            formik.errors.company_phone_number}
        </Styled.Section>
      </Styled.Section>

      <Styled.Section sx="new__auth__wrapper pt-5">
        <Styled.Span>Email Address</Styled.Span>
        <Styled.Input
          type="email"
          placeholder="Email Address"
          name="company_email"
          value={formik.values.company_email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Styled.Section sx="error">
          {formik.touched.company_email && formik.errors.company_email && formik.errors.company_email}
        </Styled.Section>
      </Styled.Section>

      <Styled.Section sx="new__auth__wrapper pt-5">
        <Styled.Span>Job Description</Styled.Span>
        <Styled.Select
          type="text"
          name="job_designation"
          value={formik.values.job_designation}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          <option value="">Select a Job Description</option>
          {job_des?.map((item) => (
            <option value={item?.slug} key={item?.id}>{item?.name}</option>
          ))} 
        </Styled.Select>

        <Styled.Section sx="error">
          {formik.touched.job_designation &&
            formik.errors.job_designation &&
            formik.errors.job_designation}
        </Styled.Section>
      </Styled.Section>
      
    </Styled.Wrapper>
  );
};

export default PersonalDetails;
