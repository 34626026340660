import React from "react";
import { Styled } from "../constants/Styled";

const CardsRecord = (props) => {
  return (
    <>
      <Styled.Section
        sx="dashboard__card__content flex justify-between"
        key={props.index}
      >
        <Styled.Wrapper sx="flex justify-center items-center">
          {props.icon}
          <Styled.Card>
            <Styled.Text>{props.title}</Styled.Text>

            <Styled.Span>
              {props.currency} {props.amount}
            </Styled.Span>
          </Styled.Card>
        </Styled.Wrapper>
        <Styled.Text sx="card__view" onClick={props.onClick}>
          {props.view_more}
        </Styled.Text>
      </Styled.Section>
    </>
  );
};

export default CardsRecord;
