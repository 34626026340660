import React, { useState } from "react";
import { Styled } from "../../../../constants/Styled";
import { Images } from "../../../../constants/Images";
import { Button, Upload } from "antd";
import { Icons } from "../../../../constants/Icons";
import { useSelector } from "react-redux";

const MerchantLogoProfile = () => {
  const { user } = useSelector((state) => state.auth);
  const [uploadedImage, setUploadedImage] = useState(null);

  const handleImageUpload = (file) => {
    setUploadedImage(file);
  };
  return (
    <div>
      {/* merchant image */}
      <Styled.Wrapper sx="merchant__image__upload">
        <Styled.Card sx="merchant__overview__header">
          {uploadedImage ? (
            <Styled.Img
              img={URL.createObjectURL(uploadedImage)}
              alt={user?.merchant?.first_name}
            />
          ) : (
            <Styled.Img img={Images.UserImg} alt={user?.merchant?.first_name} />
          )}
        </Styled.Card>
        <Upload beforeUpload={handleImageUpload}>
          <Button size="large" className="btn__icon__gap">
            Update Image <Icons.EditIcon />
          </Button>
        </Upload>
      </Styled.Wrapper>
    </div>
  );
};

export default MerchantLogoProfile;
