import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from '../app/config';

const initialState = {
  cards: [],
  loading: false,
  error: null,
};

export const fetchCards = createAsyncThunk('cards/fetchCards', async ({ page, limit }, { rejectWithValue }) => {
  try {
    const response = await userRequest.get(`/cards?page=${page}&limit=${limit}`,);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});


export const flagCustomerCards = createAsyncThunk(
  'cards/flagCards',
  async ({cardId, reason}, { rejectWithValue }) => {
    try {
      const response = await userRequest.patch(`/cards/flag/${cardId}`, { reason } );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const cardsSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCards.fulfilled, (state, action) => {
        state.loading = false;
        state.cards = action.payload;
      })
      .addCase(fetchCards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(flagCustomerCards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(flagCustomerCards.fulfilled, (state, action) => {
        state.loading = false;
        const updatedCustomer = action.payload;
        state.cards = {
          ...state.cards,
          usersCards: {
            ...state.cards.data?.usersData,
            status: updatedCustomer.status,
          },
        };
        state.error = null;    
      })
      
      .addCase(flagCustomerCards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});


export default cardsSlice.reducer;
