import { Modal, message } from "antd";
import { Icons } from "../constants/Icons";
import { Styled } from "../constants/Styled";
import jsPDF from "jspdf";
import 'jspdf-autotable';


// a custom function to copy text
export const handleCopyClick = (copyId) => {
  navigator.clipboard.writeText(copyId);
  message.success("Copied Successfully");
};

// a custom function to handle multiple tabs
export const renderSegmentedTab = (component) => {
  if (component) {
    return component;
  }
  return null;
};


// a custom function to format date, time and year
export const formatDateAndTime = (created_at) => {
  const dateObj = new Date(created_at);
  const timeString = dateObj.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
  const dateString = dateObj.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });

  return `${timeString} ${dateString}`;
};



// a custom function to format amount
export const formatAmount = (amount) => {
  amount = Number(amount);
  if (isNaN(amount)) {
    return 'Invalid Amount';
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(amount);
};



// a custom success modal
export const showCustomSuccessModal = (message, autoCloseDelay = 5000, title) => {
     Modal.success({
      icon: null,
      content: (
        <>
          <Styled.Wrapper sx="custom__success__content">
              <Icons.SuccessIcon />
              <Styled.Text sx="title">{title}</Styled.Text>
              <Styled.Text sx="desc">{message}</Styled.Text>
          </Styled.Wrapper>
        </>
      ),
      footer: null,
      className: 'custom__success__modal',
    });

    setTimeout(() => {
      Modal.destroyAll(); 
    }, autoCloseDelay);
  };



// jspdf-autotable jspdf


// custom pdf export function
export const exportToPDF = (tableData, pdfTitle, merchantName, columns, layout = 'portrait') => {

  const pdf = new jsPDF({
    orientation: layout,
  });

  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split('T')[0];

  const pdfColumns = columns?.map((column) => ({
    header: column.title,
    dataKey: column.dataIndex,
  }));

  const pdfData = tableData?.map((row) =>
    columns.reduce((rowData, column) => {
      rowData[column.dataIndex] = row[column.dataIndex];
      return rowData;
    }, {})
  );

  const title = `${merchantName} -------  ${pdfTitle}  ------- ${formattedDate}`;
  const xTitle = 10;
  const yTitle = 10;

  pdf.setFontSize(16);
  pdf.text(xTitle, yTitle, title);

  pdf.autoTable({
    columns: pdfColumns,
    body: pdfData,
    startY: 20,
  });

  pdf.save(`${pdfTitle.toLowerCase().replace(/\s/g, '_')}.pdf`);
};







