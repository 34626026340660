import React, { useState } from "react";
import { Styled } from "../../../constants/Styled";
import { Icons } from "../../../constants/Icons";
import { Button, Modal } from "antd";
import { ModalTitle } from "../../../common";
import CreatePin from "./CreatePin";

const ProfileBanner = () => {
  const [trnxPin, setTrnxPin] = useState(false);

  return (
    <>
      <Styled.Wrapper sx="profile__banner">
        <Styled.Section sx="profile__banner__left">
          <Icons.CreatePinIcon />
          <Styled.Card>
            <Styled.Text>Setup Transaction PIN</Styled.Text>
            <Styled.Span>
              Create a transaction PIN to enhance the security of all your
              transactions
            </Styled.Span>
          </Styled.Card>
        </Styled.Section>

        <Button
          type="primary"
          size="large"
          className="text-[20px] font-semibold"
          onClick={() => setTrnxPin(true)}
        >
          Create Pin
        </Button>
      </Styled.Wrapper>

      {trnxPin && (
        <Modal
          title={
            <ModalTitle close={() => setTrnxPin(false)} />
          }
          open={() => setTrnxPin(true)}
          closable={false}
          footer={null}
          width={400}
        >
          <CreatePin close={() => setTrnxPin(false)} />
        </Modal>
      )}
    </>
  );
};

export default ProfileBanner;
