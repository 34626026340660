import React, { useState } from "react";
import { Styled } from "../../../constants/Styled";
import { Tabs } from "antd";
import RoleMgrUser from "./profile/RoleMgr.User";
import RoleMgrRole from "./profile/RoleMgr.Role";

const SettingRoleMgr = () => {
  const [ switchTab, setSwitchTab ] = useState("1")

  const handleTabChange = (key) => {
    setSwitchTab(key);
  }

  const items = [
    {
      key: 1,
      label: "USER",
      children: <RoleMgrUser />,
    },
    {
      key: 2,
      label: "ROLE",
      children: <RoleMgrRole />,
    },
  ];

  return (
    <>
      <Styled.Wrapper sx="merchant__header__role">
        <Tabs
          defaultActiveKey={switchTab}
          type="card"
          items={items}
          onChange={handleTabChange}
        />
      </Styled.Wrapper>
    </>
  );
};

export default SettingRoleMgr;
