import React, { useEffect, useRef, useState } from 'react';
import { Styled } from '../constants/Styled';

const OTPInput = ({ length, value, onChange }) => {
  const inputsRef = useRef([]);
  const [ activeOtp, setActiveOtp ] = useState(0);

  const handleInput = (index, event) => {
    const input = event.target;
    const nextInput = inputsRef.current[index + 1];
    const otpArray = [...value];

    otpArray[index] = input.value.slice(0, 1);

    onChange(otpArray.join(''));

    if (input.value && nextInput) {
      setActiveOtp(index + 1);
    }
  };

  const handleKeyDown = (index, event) => {
    const input = event.target;
    const previousInput = inputsRef.current[index - 1];
    const nextInput = inputsRef.current[index + 1];

    if (event.key === 'Backspace' && !input.value && previousInput) {
      setActiveOtp(index - 1);
    } else if (event.key === 'ArrowLeft' && previousInput) {
      setActiveOtp(index - 1);
    } else if (event.key === 'ArrowRight' && nextInput) {
      setActiveOtp(index + 1);
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text/plain');
    const otpArray = clipboardData.split('').slice(0, length);
    otpArray.forEach((value, index) => {
      inputsRef.current[index].value = value;
      if (index < length - 1) {
        setActiveOtp(index + 1);
      }
    });
    onChange(otpArray.join(''));
  };


  const handleInputFocus = (index) => {
    setActiveOtp(index);
  };
  

  useEffect(() => {
    if (inputsRef.current[activeOtp]) {
      inputsRef.current[activeOtp].focus();
    }
  }, [activeOtp]);



  return (
    <Styled.Wrapper sx="otp-container">
      {[...Array(length).keys()].map((index) => (
        <input
          key={index}
          type="password"
          className="otp-input"
          ref={(ref) => (inputsRef.current[index] = ref)}
          maxLength={1}
          value={value[index] || ''}
          onChange={(event) => handleInput(index, event)} 
          onKeyDown={(event) => handleKeyDown(index, event)}
          onPaste={handlePaste}
          onFocus={() => handleInputFocus(index)}
          autoFocus={activeOtp === index}
        />
      ))}
    </Styled.Wrapper>
  );
};

export default OTPInput;


