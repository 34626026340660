import React, { useState } from "react";
import { Styled } from "../../../constants/Styled";
import { Button, Upload, } from "antd";
import { Icons } from "../../../constants/Icons";
import { toast } from "react-toastify";

const ActivateMerchant = ({ closeModal }) => {
  const { Dragger } = Upload;

  const [isLoading, setIsLoading] = useState(false);
  const [isClose, setIsClose] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      toast.success("Merchant Details Updated Successfully");
      setIsClose(closeModal);
    }, 3000);
  };

  return (
    <>
      <Styled.Form>
        <Styled.Text> {isClose ? "X" : ""}</Styled.Text>
        <Styled.Wrapper sx="pt-5">
          <Styled.Section sx="new__auth__wrapper ">
            <Styled.Span>Company Name</Styled.Span>
            <Styled.Input type="text" placeholder="Company Name" name="" />
          </Styled.Section>

          <Styled.Section sx="new__auth__wrapper pt-3">
            <Styled.Span>Company Email Address</Styled.Span>
            <Styled.Input
              type="email"
              placeholder="info@merchant.com"
              name=""
            />
          </Styled.Section>

          <Styled.Wrapper sx="activate__merchant__input">
            <Styled.Section sx="new__auth__wrapper pt-3">
              <Styled.Span>Company Contact Number</Styled.Span>
              <Styled.Input type="text" placeholder="+2348138885517" name="" />
            </Styled.Section>
            <Styled.Section sx="new__auth__wrapper pt-3">
              <Styled.Span>Industry Type</Styled.Span>
              <Styled.Input type="text" placeholder="Industry Type" name="" />
            </Styled.Section>
          </Styled.Wrapper>

          <Styled.Section sx="new__auth__wrapper pt-3">
            <Styled.Span>CAC Registration Number</Styled.Span>
            <Styled.Input
              type="text"
              placeholder="CAC Registration Number"
              name=""
            />
          </Styled.Section>

          <Styled.Section sx="new__auth__wrapper pt-3">
            <Styled.Span>CAC Document</Styled.Span>
            <Dragger>
              <Styled.Text sx="">
                <Icons.UploadIcon />
              </Styled.Text>
              <Styled.Text sx="upload__title__doc">
                Click or drag file to this area to upload
              </Styled.Text>
              <Styled.Text sx="upload__desc__doc">
                (JPG, PNG, PDF Max 3MB)
              </Styled.Text>
            </Dragger>
          </Styled.Section>

          <Styled.Section sx="new__auth__wrapper pt-3">
            <Styled.Span>Office Address</Styled.Span>
            <Styled.Input
              type="text"
              placeholder="95 Broad Street Marina, Lagos Island
"
              name=""
            />
          </Styled.Section>

          <Button
            size="large"
            className="mt-6 w-full text-[20px] font-semibold"
            type="primary"
            loading={isLoading}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Styled.Wrapper>
      </Styled.Form>
    </>
  );
};

export default ActivateMerchant;
