import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { publicRequest } from '../app/config';

const initialState = {
  register: [],
  loading: false,
  error: null,
};

export const registerUser = createAsyncThunk('merchant/auth/register',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await publicRequest.post(`/merchant/auth/signup`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.register = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })   
  },
});

export default registerSlice.reducer;
