import React, { useEffect } from "react";
import { Styled } from "../../../constants/Styled";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { dash_trnx_columns } from "../../../data/DashboardData";
import { fetchTransactions } from "../../../state/transactionSlice";
// import DataInflow from "./DataInflow";
// import DashboardRadialBarChart from "./RadialBarChart";

const DashboardTable = () => {
  const dispatch = useDispatch();
  const { transactions, loading } = useSelector((state) => state.transactions);

  useEffect(() => {
    dispatch(fetchTransactions({ page: 1, limit: 10}));
  }, [dispatch]);

  const allTrnx = transactions?.data?.transactions;

  return (
    <>
      <Styled.Wrapper sx="dashboard__table mb-10">
            <Styled.Wrapper sx="box__shadow admin__left__box h-[31.5rem]">
              <Styled.Wrapper sx="recent__trnx">
                <Styled.Text>Recent Transactions</Styled.Text>
                <Styled.Linked as="/dashboard/transactions">
                  View All
                </Styled.Linked>
              </Styled.Wrapper>
              <Table
                dataSource={allTrnx}
                columns={dash_trnx_columns}
                loading={loading}
                rowKey="id"
                pagination={{
                  total: 1,
                  pageSize: 6,
                  hideOnSinglePage: true, 
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  showSizeChanger: true,
                }}
                responsive
                scroll={{ x: true}}
              />
            </Styled.Wrapper>




           {/* <Styled.Wrapper sx="box__shadow admin__right__box">
              <DashboardRadialBarChart />
           </Styled.Wrapper> */}
            {/* <DataInflow /> */}

      </Styled.Wrapper>
    </>
  );
};

export default DashboardTable;
