import React, { useState } from "react";
import { Styled } from "../../../constants/Styled";
import { Button, Input } from "antd";
import { useFormik } from "formik";
import { createTransactionPin } from "../../../state/createPin.Slice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { schema } from "../../../schema/Schema";
import { updatePinStatus } from "../../../state/authSlice";

const CreatePin = ({ close }) => {
  const dispatch = useDispatch();
  const [ closeModal, setCloseModal ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);

  const formik = useFormik({
    initialValues: {
      pin: "",
      confirm_pin: "",
    },

    validationSchema: schema.createPinSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const response = await dispatch(createTransactionPin(values)).unwrap();
        setIsLoading(false);
        toast.success(response.message);
        dispatch(updatePinStatus());
        formik.resetForm();
        setCloseModal(close)
      } catch (err) {
        toast.error(err.message);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const isPasswordMatch = formik.values.pin === formik.values.confirm_pin;

  return (
    <>
      <Styled.Wrapper sx="create__pin">
        {closeModal ? "" : ""}
        <Styled.Text sx="title">Create Transaction Pin</Styled.Text>
        <Styled.Span sx="desc">
          Set up a unique PIN, ensuring that every transaction you make is safe
          and secure.
        </Styled.Span>

        <Styled.Form onSubmit={formik.handleSubmit}>
        <Styled.Section sx="password__auth pt-10">
        <Styled.Span>Create Pin</Styled.Span>
        <Input.Password
          className="tingo__input"
          type="password"
          placeholder="pin"
          name="pin"
          value={formik.values.pin}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Styled.Section sx="error">
          {formik.touched.pin &&
            formik.errors.pin &&
            formik.errors.pin}
        </Styled.Section>
      </Styled.Section>

      <Styled.Section sx="password__auth pt-5">
        <Styled.Span>Confirm Pin</Styled.Span>
        <Input.Password
          className="tingo__input"
          type="password"
          placeholder="Confirm Pin"
          name="confirm_pin"
          value={formik.values.confirm_pin}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Styled.Section sx="error">
          {formik.touched.confirm_pin &&
            formik.errors.confirm_pin &&
            isPasswordMatch === false && <> {formik.errors.confirm_pin}</>}
        </Styled.Section>
      </Styled.Section>

          <Button 
            type="primary" 
            size="large"
            className="mt-10 w-full text-[20px] font-semibold"
            loading={isLoading}
            htmlType="submit"
          >
            Set Pin
          </Button>
        </Styled.Form>
      </Styled.Wrapper>
    </>
  );
};

export default CreatePin;
