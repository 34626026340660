export const role_mgr_role_columns = [
    {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => a.name - b.name,
    },


    {
        title: "Description",
        dataIndex: "description",
        sorter: (a, b) => a.description - b.description,
        width: "339px"
    },

    {
        title: "User",
        dataIndex: "userCount",
        render: userCount => `${userCount ? userCount : "0"}`,
        sorter: (a, b) => a.userCount - b.userCount,
    },
]