import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from '../app/config';

const initialState = {
  createPin: [],
  loading: false,
  error: null,
};

export const createTransactionPin = createAsyncThunk('merchant/auth/create/trnx/pin',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userRequest.post(`/merchant/pin`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const createPinSlice = createSlice({
  name: 'createPin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createTransactionPin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTransactionPin.fulfilled, (state, action) => {
        state.loading = false;
        state.createPin = action.payload;
      })
      .addCase(createTransactionPin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })   
  },
});

export default createPinSlice.reducer;
