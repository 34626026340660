import { Input, Select,  Table } from "antd";
import React from "react";
import { Icons } from "../../../constants/Icons";
import { options } from "../../../data/Data";
import { Styled } from "../../../constants/Styled";
import { flagged_col } from "../../../data/flaggedCards.Data";
import { CustomDateRangePicker } from "../../../common";

const FlaggedCards = () => {
  return (
    <div>
      <Styled.Section sx="flex justify-between items-center">
        <Styled.Wrapper sx="gap-4">
          <Input
            className="trnx__search__input"
            type="search"
            size="large"
            prefix={<Icons.IconSearch />}
            placeholder="Search by ID"
            style={{
              width: "175px",
              marginRight: "8px",
            }}
          />
          <Select
            className="trnx__search__select"
            defaultValue="Filter By"
            size="large"
            options={options}
            bordered={false}
          />
        </Styled.Wrapper>
        <Styled.RightButtonWrapper>
        <CustomDateRangePicker />
        <Styled.ExportButtonWrapper>
          <Icons.ReadPDF />
          <Icons.ReadExcel />
        </Styled.ExportButtonWrapper>
        </Styled.RightButtonWrapper>
      </Styled.Section>

      {/* TABLE */}
      <Styled.Wrapper sx="table__box pt-3">
        <Table 
          columns={flagged_col} 
          className="centered-pagination"
          />
      </Styled.Wrapper>
    </div>
  );
};

export default FlaggedCards;
