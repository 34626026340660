import React from 'react'
import { Styled } from '../../../constants/Styled'

const HelpCard = (props) => {
  return (
    <>
         <Styled.Wrapper sx="help__card" key={props.key}>
            {props.icon}
            <Styled.Text>{props.title}</Styled.Text>
            <Styled.Span>{props.desc}</Styled.Span>
        </Styled.Wrapper>
    </>
  )
}

export default HelpCard