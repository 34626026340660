import React, { useState } from "react";
import { Styled } from "../../../../constants/Styled";
import { Button, Modal, Space } from "antd";
import { Icons } from "../../../../constants/Icons";
import { QRCodeSVG } from "qrcode.react";
import { ModalTitle } from "../../../../common";
import { toast } from "react-toastify";

const Security2Factor = () => {
  const [twoFactor, setTwoFactor] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setTwoFactor(false);
      toast.success("Two Factor Authentication Success");
    }, 3000);
  };

  return (
    <>
      <Styled.Section sx="pt-10">
        <Styled.Caption title="Two-Factor Authentication (2FA)" />
        <Styled.Wrapper sx="security__wrapper">
          <Styled.Section sx="border__inner notify__pass p-8">
            <Styled.Section sx="check__notify">
              Use an authenticator app to enhance the security of your account.
              When enabled, you’ll need a verification code to login
            </Styled.Section>

            <Button
              size="large"
              className="btn__icon__gap mt-4 w-full"
              onClick={() => setTwoFactor(true)}
            >
              Setup two-factor authentication
              <Icons.NextIcon />
            </Button>
          </Styled.Section>
        </Styled.Wrapper>
      </Styled.Section>

      {twoFactor && (
        <Modal
          centered
          title={
            <ModalTitle
              title="Set Up Two Factor Authentication"
              close={() => setTwoFactor(false)}
            />
          }
          open={twoFactor}
          footer={null}
          bodyStyle={{ height: "590px" }}
          closable={false}
        >
          <Styled.Form>
            <Styled.Section sx="border__inner qrcode mt-10">
              <Styled.Text sx="title pb-4">
                Open your Authenticator app and scan this QR Code
              </Styled.Text>

              <QRCodeSVG
                renderAs="svg"
                bgColor="transparent"
                size={120}
                bordered={false}
              />

              <Styled.Span>
                or manually enter the following code on Authenticator app
              </Styled.Span>

              <Styled.Text sx="qrcode__auth pt-2">
                YE65 URY6 HRYR 84NM JDDM 6453 KJKK YYTR
              </Styled.Text>
            </Styled.Section>

            {/* DOWNLOAD URL */}
            <Styled.Section sx="qrcode">
              <Styled.Text sx="title pt-5">
                Don’t have an authenticator app?
              </Styled.Text>
              <Styled.Span>
                Google Authenticator is a free Two -factor Authenticator app.
                Download it on your iOS or Android device
              </Styled.Span>

              <Space className="pt-5 download__link">
                <Icons.GooglePlayIcon />
                <Icons.ApplePlayIcon />
              </Space>
            </Styled.Section>

            <Styled.Wrapper sx="add__affiliates__button__wrapper mt-[5rem]">
              <Button onClick={() => setTwoFactor(false)}>Cancel</Button>
              <Button type="primary" onClick={handleOk} loading={loading}>
                Save
              </Button>
            </Styled.Wrapper>
          </Styled.Form>
        </Modal>
      )}
    </>
  );
};

export default Security2Factor;
