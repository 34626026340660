import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from '../app/config';

const initialState = {
  updatePassword: [],
  loading: false,
  error: null,
};

export const updateAuthPassword = createAsyncThunk('merchant/auth/update/password',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userRequest.patch(`/merchant/password/update`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const updatePasswordSlice = createSlice({
  name: 'updatePassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateAuthPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAuthPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.updatePassword = action.payload;
      })
      .addCase(updateAuthPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })   
  },
});

export default updatePasswordSlice.reducer;
