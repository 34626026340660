import React, { useEffect, useState } from "react";
import { Styled } from "../../../constants/Styled";
import { Table } from "antd";
import { cards_trnx_columns } from "../../../data/cardsData";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleCardTransaction } from "../../../state/singleCardTrn.Slice";


const CardTransactions = ({cardId}) => {

  const dispatch = useDispatch();
  const { singleCardTrnx } = useSelector(state => state.singleCardTrnx);
    const [ currentPage, setCurrentPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);

  useEffect(() => {
    dispatch(fetchSingleCardTransaction({ cardId: cardId?.card_number, page: currentPage, limit: pageSize }))
  }, [dispatch, cardId, currentPage, pageSize]);


  const transaction = singleCardTrnx?.data?.transactions

    // pagination
  const handlePageChange = (page, newPageSize) => {
    setCurrentPage(page);
    setPageSize(newPageSize);
  };

  return (
    <>
    <Styled.Wrapper sx="table__box shadow-md">
      <Styled.Text sx="table__box__trnx">Transactions on this card</Styled.Text>
        <Table 
            columns={cards_trnx_columns}
            dataSource={transaction}
              pagination={{
              total: pageSize * singleCardTrnx?.data?.totalPages,
              pageSize: pageSize,
              current: currentPage,
              showSizeChanger: true,
              onChange: handlePageChange,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} Card Transactions`,
              pageSizeOptions: ["10", "20", "30", "40", "50", "60", "70", "80", "100"],
            }}
            className="centered-pagination"
        />
    </Styled.Wrapper>
    </>
  );
};

export default CardTransactions;
