import { Icons } from "../constants/Icons";


export const get_started = [
    {
        icon: <Icons.HelpCenterIcon />,
        title: "Dashboard Tour",
        desc: "See what you can do on the dashboard"
    },

    {
        icon: <Icons.HelpCenterIcon />,
        title: "Team Admin",
        desc: "Learn how to set up and manage your team"
    },

    {
        icon: <Icons.HelpCenterIcon />,
        title: "Dashboard Tour",
        desc: "See what you can do on the dashboard"
    },

    {
        icon: <Icons.HelpCenterIcon />,
        title: "Dashboard Tour",
        desc: "See what you can do on the dashboard"
    },
] 


export const faq = [
    {
        desc: "See what you can do on the dashboard"
    },

    {
        desc: "See what you can do on the dashboard"
    },

    {
        desc: "See what you can do on the dashboard"
    },

    {
        desc: "See what you can do on the dashboard"
    },

    {
        desc: "See what you can do on the dashboard"
    },

    {
        desc: "See what you can do on the dashboard"
    },
]