import React, { useState } from "react";
import { Styled } from "../../../constants/Styled";
import { Button, Divider, Radio } from "antd";
import { ModalTitle } from "../../../common";
import ConfirmTransactionBulks from "./confirmTransaction.Bulks";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { schema } from "../../../schema/Schema";
import { bulkCardsStep2 } from "../../../state/bulkCard2.Slice";

const BulkCardDetails = ({ close }) => {
  const dispatch = useDispatch();
  const [isConfirm, setIsConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const { bulkcards } = useSelector((state) => state.bulkcards);

  const formik = useFormik({
    initialValues: {
      batchCode: bulkcards?.data?.batchId,
      amount: "",
      batchName: "",
      totalAmount: "",
      oneTimeUse: false
    },

    validationSchema: schema.bulkGenerate2,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await dispatch(bulkCardsStep2(values)).unwrap();
        toast.info(response.message);
        setIsConfirm(true);
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    },
  });

  formik.values.totalAmount = formik.values.amount * bulkcards?.data?.card_quantity;

  return (
    <>
      <Styled.Form sx="px-[8px] pb-[28px] pt-2" onSubmit={formik.handleSubmit}>
        <Divider className="mt-[10px] mb-[32px]" />
        <Styled.Wrapper sx="bulk__batch">
          <Styled.Text>
            BATCH CODE : <Styled.Span> {bulkcards?.data?.batchId}</Styled.Span>
          </Styled.Text>
          <Styled.Text>
            CARD QUANTITY :{" "}
            <Styled.Span> {bulkcards?.data?.card_quantity}</Styled.Span>
          </Styled.Text>
        </Styled.Wrapper>
        <Styled.Section sx="new__auth__wrapper">
          <Styled.Span>Batch Name</Styled.Span>
          <Styled.Input
            type="text"
            placeholder="City Transport"
            name="batchName"
            value={formik.values.batchName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Styled.Section sx="error">
            {formik.touched.batchName &&
              formik.errors.batchName &&
              formik.errors.batchName}
          </Styled.Section>
        </Styled.Section>

        <Styled.Section sx="new__auth__wrapper pt-3">
          <Styled.Span>Single Card Value</Styled.Span>
          <Styled.Input
            type="number"
            placeholder="₦ (Amount)"
            name="amount"
            value={formik.values.amount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Styled.Section sx="error">
            {formik.touched.amount &&
              formik.errors.amount &&
              formik.errors.amount}
          </Styled.Section>
        </Styled.Section>

        <Styled.Section sx="new__auth__wrapper pt-3">
          <Styled.Span>Total Amount</Styled.Span>
          <Styled.Input
            type="number"
            placeholder={formik.values.totalAmount}
            name="totalAmount"
            value={formik.values.totalAmount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          />
          <Styled.Section sx="error">
            {formik.touched.totalAmount &&
              formik.errors.totalAmount &&
              formik.errors.totalAmount}
          </Styled.Section>
        </Styled.Section>

        <Styled.Section sx="pt-3">
          <Radio.Group
          className="one__time__use" 
          name="oneTimeUse" 
          value={formik.values.oneTimeUse}
          onChange={formik.handleChange}

          >
            <Radio value={true}>One Time Use</Radio>
            <Radio value={false}>Multiple Use</Radio>
          </Radio.Group>

          <Styled.Section sx="error">
            {formik.touched.oneTimeUse && formik.errors.oneTimeUse && formik.errors.oneTimeUse}
          </Styled.Section>
        </Styled.Section>

        <Styled.Wrapper sx="bulk__batch__btn">
          <Button type="" onClick={close}>
            Back
          </Button>
          <Button 
          type="primary"
          htmlType="submit"
          loading={loading}
          >
            Submit
          </Button>
        </Styled.Wrapper>
      </Styled.Form>

      {isConfirm && (
        <Styled.TgModal
          title={
            <ModalTitle
              title="Confirm Transaction"
              close={() => setIsConfirm(false)}
            />
          }
          open={isConfirm}
          closable={false}
          width={512}
          bodyStyle={{ height: "600px" }}
          footer={null}
        >
          <ConfirmTransactionBulks close={close} />
        </Styled.TgModal>
      )}
    </>
  );
};

export default BulkCardDetails;
