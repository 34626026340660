import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from '../app/config';

const initialState = {
  inviteStaff: [],
  loading: false,
  error: null,
};

export const inviteStaffRole = createAsyncThunk('merchant/invite/staff',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userRequest.post(`/merchant/staffs/invite`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const inviteStaffSlice = createSlice({
  name: 'inviteStaff',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(inviteStaffRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(inviteStaffRole.fulfilled, (state, action) => {
        state.loading = false;
        state.inviteStaff = action.payload;
      })
      .addCase(inviteStaffRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })   
  },
});

export default inviteStaffSlice.reducer;
