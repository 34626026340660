import React from "react";
import { Images } from "../constants/Images";
import { Styled } from "../constants/Styled";



const AuthLayout = (props) => {


  return (
    <>
       <Styled.Wrapper sx='new__login__wrapper'>
        <Styled.Wrapper sx="new__login__left">
                <Styled.Img img={Images.SignUpBar1} alt=""/> 
        </Styled.Wrapper>
        <Styled.Wrapper sx="new__login__right">   
            {props.children}
        </Styled.Wrapper>
    </Styled.Wrapper>
    </>
  );
};

export default AuthLayout;
