import React, { useState } from "react";
import {
  DashboardLayout,

} from "../../components";
import {  Tabs } from "antd";
import { Styled } from "../../constants/Styled";
import { options } from "../../data/settingTab.Data";

const Settings = () => {
  const [ switchTab, setSwitchTab ] = useState("1")

  const handleTabChange = (key) => {
    setSwitchTab(key);
  }

  return (
    <DashboardLayout>
    <Styled.InnerWrapper sx="pt-[32px]">
      <Tabs
          defaultActiveKey={switchTab}
          items={options}
          onChange={handleTabChange}
          centered={false}
          className="pt-[20px]"
        />
    </Styled.InnerWrapper>
    </DashboardLayout>
  );
};

export default Settings;
