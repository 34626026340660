import { Icons } from "../constants/Icons";
import { Images } from "../constants/Images";


export const bank__data = [
    {
        bank__icon: Images.AccessBank,
        bank_ac: "0127005772",
        bank_name: "Godwin Ahua",
        icon: <Icons.EditIcon />
    }
]

export const bank__list = [
    {
        bank_name: "GTBank",
        value: "gtbank",
    },

    {
        bank_name: "Access Bank",
        value: "access",
    },

    {
        bank_name: "UBA",
        value: "uba",
    }
]