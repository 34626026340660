import * as yup from "yup";

export const registerSchema = yup.object({
  company_name:  yup.string().required("Company is required"),
  industry_type:  yup.string().required("Industry Type is required"),
  cac_number:  yup.string().required("CAC Number is required"),
  office_address: yup.string().required("Office Address is required"),
  first_name:  yup.string().required("First Name is required"),
  last_name:  yup.string().required("Last Name is required"),
  company_email:  yup.string().email("Invalid email address").required("Email is required"),
  company_phone_number:  yup.string().required("Phone Number is required"),
  job_designation:  yup.string().required("Job Description is required"),
  password:  yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must be at least 8 characters long and include one uppercase letter, one lowercase letter, one digit, and one special character."
    ),
    confirm_password:  yup
    .string()
    .required("Password confirmation is required")
    .oneOf([yup.ref('password'), null], "Password do not match"),
});


export const loginSchema = yup.object({
  email: yup.string().required("Email is required").email(),
  password: yup.string().required("Password is required"),
});


export const cardGenerateSingleSchema = yup.object({
  cardPurpose: yup.string().required("Card Purpose is required"),
  holderName: yup.string().required("Holder Name is required"),
  contactNumber: yup.string().required("Phone Number is required"),
  amount: yup
    .string()
    .required("Amount is required")
    .test('is-greater-than-1000', 'Amount must not be less than 1000', value => {
      if (!value) return true; 
      return parseInt(value, 10) >= 1000;
    }),
  email: yup.string().email("Invalid email address").required("Email is required"),
  pin: yup.string().required("Pin is required"),
  oneTimeUse: yup.boolean().required("One Time Use is required").default(false),
});



export const physicalSchema = yup.object({
  voucher_card_number:  yup.string().required("Voucher Number is required"),
  amount:  yup.string().required("Amount is required"),
});


export const bulkGenerate = yup.object().shape({
  file: yup.mixed()
    .test("fileType", "Only Excel files are allowed", (value) => {
      if (!value) return true; // If no file is selected, consider it valid.
      return (
        value.type === "application/vnd.ms-excel" || // For .xls files
        value.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" // For .xlsx files
      );
    })
    .required("Please upload a Spreadsheet file"),
    validity: yup.string().required("Validity Period is required"),
});

export const bulkGenerate2 = yup.object().shape({
  amount: yup
    .string()
    .required("Amount is required")
    .test('is-greater-than-1000', 'Amount must not be less than 1000', value => {
      if (!value) return true; 
      return parseInt(value, 10) >= 1000;
    })
    .test('is-number', 'Amount must be a number conforming to the specified constraints', value => {
      if (!value) return true;
      return !isNaN(parseFloat(value)) && isFinite(value);
    }),
    batchName: yup.string().required("Batch Name is required"),
    totalAmount: yup.string().required("Total Amount is required"),
    oneTimeUse: yup.boolean().required("One Time Use is required").default(false),
});

export const bulkGenerate3 = yup.object().shape({
    batchCode: yup.string().required("Batch Code is required"),
    pin: yup.string().required("Pin is required"),
});


export const otpSchema = yup.object({
  otp:  yup.string().required("OTP is required"),
});

export const forgotPasswordSchema = yup.object({
  email:  yup.string().required("Email is required"),
});


export const changePasswordSchema = yup.object({
  new_password:  yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must be at least 8 characters long and include one uppercase letter, one lowercase letter, one digit, and one special character."
    ),
    confirm_password:  yup
    .string()
    .required("Password confirmation is required")
    .oneOf([yup.ref('password'), null], "Password do not match"),
});




export const inviteStaffSchema = yup.object({
  first_name:  yup.string().required("First Name is required"),
  last_name:  yup.string().required("Last Name is required"),
  email_address:  yup.string().email("Invalid email address").required("Email is required"),
  phone_number: yup
  .string()
  .test("max-length", "Phone Number must be shorter than or equal to 11 characters", value => {
    if (value) {
      return value.length <= 11;
    }
    return true;
  })
  .required("Phone Number is required"),
  job_designation:  yup.string().required("Job Description is required"),
  role_id:  yup.string().required("Role is required"),
});



export const acceptInviteSchema = yup.object({
  password:  yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must be at least 8 characters long and include one uppercase letter, one lowercase letter, one digit, and one special character."
    ),
  confirm_password:  yup
  .string()
  .required("Password confirmation is required")
  .oneOf([yup.ref('password'), null], "Password do not match"),
});


export const updateStaffSchema = yup.object({
  first_name:  yup.string().required("First name is required"),
  last_name:  yup.string().required("Last name is required"),
  email_address:  yup.string().required("Email is required"),
  job_deesignation:  yup.string().required("Job designation is required"),
});


export const createRoleSchema = yup.object({
  name:  yup.string().required("Name is required"),
  description:  yup.string().required("Description name is required"),
});



export const createPinSchema = yup.object({
  pin: yup
    .string()
    .required("PIN is required")
    .matches(/^\d{4}$/, "PIN must be exactly four digits")
    .test('is-numeric', 'PIN must contain only numeric digits', (value) => {
      return /^\d+$/.test(value);
    }),
    confirm_pin: yup
    .string()
    .required("PIN confirmation is required")
    .oneOf([yup.ref('pin'), null], "PINs do not match"),
});


export const updateCreatePinSchema = yup.object({
  old_pin: yup
    .string()
    .required("Old Pin is required")
    .matches(/^\d{4}$/, "Old PIN must be exactly four digits")
    .test('is-numeric', 'Old PIN must contain only numeric digits', (value) => {
      return /^\d+$/.test(value);
    }),

  new_pin: yup
    .string()
    .required("New PIN is required")
    .matches(/^\d{4}$/, "New PIN must be exactly four digits")
    .test('is-numeric', 'New PIN must contain only numeric digits', (value) => {
      return /^\d+$/.test(value);
    }),

  confirm_new_pin: yup
    .string()
    .required("PIN confirmation is required")
    .oneOf([yup.ref('new_pin'), null], "PINs do not match"),
});



export const updateAuthPasswordSchema = yup.object({
  old_password:  yup.string().required("Old Password is required"),

    new_password:  yup
    .string()
    .required("New Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "New Password must be at least 8 characters long and include one uppercase letter, one lowercase letter, one digit, and one special character."
    ),
    confirm_new_password:  yup
    .string()
    .required("Password confirmation is required")
    .oneOf([yup.ref('new_password'), null], "Password do not match"),
});


export const flagCardSchema = yup.object({
  reason:  yup.string().required("Reason is required"),
});




export const schema = {
  registerSchema,
  loginSchema,
  cardGenerateSingleSchema,
  physicalSchema,
  bulkGenerate,
  otpSchema,
  forgotPasswordSchema,
  changePasswordSchema,
  bulkGenerate2,
  bulkGenerate3,
  inviteStaffSchema,
  acceptInviteSchema,
  updateStaffSchema,
  createRoleSchema,
  createPinSchema,
  updateCreatePinSchema,
  updateAuthPasswordSchema,
  flagCardSchema
};

