import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  userRequest } from '../app/config';

const initialState = {
    customercards: [],
  loading: false,
  error: null,
};



export const fetchCustomerCards = createAsyncThunk('customers/fetchCustomersCards', async ({cardId}, { rejectWithValue }) => {
  try {
    const response = await userRequest.get(`/customers/cards/${cardId}`,);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const customercardsSlice = createSlice({
  name: 'customercards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // customers cards
      .addCase(fetchCustomerCards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerCards.fulfilled, (state, action) => {
        state.loading = false;
        state.customercards = action.payload;
      })
      .addCase(fetchCustomerCards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});


export default customercardsSlice.reducer;
