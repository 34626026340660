import React, { useEffect, useState } from "react";
import {
  CardCards,
  CardDetails,
  CardTransactions,
  DashboardLayout,
} from "../../components";
import { Button, Drawer, Dropdown, Input, Menu, Select, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchCards } from "../../state/cardsSlice";
import { cards_columns } from "../../data/cardsData";
import { Icons } from "../../constants/Icons";
import { Styled } from "../../constants/Styled";
import { ModalTitle } from "../../common";
import { options } from "../../data/Data";
import GenerateVoucherCards from "../../components/dashboard/cards/generateVoucherCards";
import FlagFormCard from "../../components/dashboard/cards/flagForm.Card";

const Cards = () => {
  const dispatch = useDispatch();
  const { cards, loading } = useSelector((state) => state.cards);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [cardRequest, setCardRequest] = useState(false);
  const [isFlagCard, setIsFlagCard] = useState(false);

  useEffect(() => {
    dispatch(fetchCards({ page: currentPage, limit: pageSize }));
  }, [dispatch, currentPage, pageSize]);

  const allCards = cards?.data?.cards;

  // pagination
  const handlePageChange = (page, newPageSize) => {
    setCurrentPage(page);
    setPageSize(newPageSize);
  };

  // handle card flag
  const handleFlagCard = (cardNumber) => {
    setSelectedCardId(cardNumber);
    setIsFlagCard(true);
  };

  // a function to show and close the card details
  const showDrawer = (cardId) => {
    setSelectedCardId(cardId);
    setIsCardVisible(true);
  };

  const closeDrawer = () => {
    setIsCardVisible(false);
  };

  const isCardSelected = allCards?.find(
    (cust) => cust?.card_number === selectedCardId
  );
  // end

  const flaggedStatus = allCards?.map((card) => card.flagged);

  const renderActionMenu = (cardId) => {
    return (
      <Menu>
        <Menu.Item key="view" onClick={() => showDrawer(cardId)}>
          <Styled.ViewDetail text="View" />
        </Menu.Item>
      </Menu>
    );
  };

  const actionColumn = {
    title: "Action",
    dataIndex: "id",
    key: "actions",
    render: (_, record, index) => (
      <>
        <Styled.Section
          key="flagged"
          onClick={() => handleFlagCard(record?.card_number)}
        >
          {flaggedStatus[index] === true ? (
            <Styled.Unflagged text="Unflag" />
          ) : (
            <Styled.Flagged text="Flag" />
          )}
        </Styled.Section>
      </>
    ),
    width: "120px",
    align: "center",
  };

  const moreColumn = {
    title: "More",
    dataIndex: "card_number",
    key: "more",
    render: (_, record, index) => (
      <Dropdown
        overlay={renderActionMenu(record.card_number, index)}
        placement="bottomRight"
        arrow
      >
        <Icons.ActionDot
          size={20}
          color="#72747D"
          width="15.12px"
          height="15.12px"
          onClick={(e) => e.preventDefault()}
        />
      </Dropdown>
    ),
    align: "center",
  };

  const card_columns = [...cards_columns, actionColumn, moreColumn];

  return (
    <>
      <DashboardLayout>
        <Styled.Wrapper sx="pb-[24px]">
          <CardCards />
        </Styled.Wrapper>

        <Styled.InnerWrapper>
          <Styled.Wrapper>
            <Styled.Section sx="merchant__filters">
              <Input
                className="trnx__search__input"
                type="search"
                size="large"
                prefix={<Icons.IconSearch />}
                placeholder="Search Transactions"
              />

              <Styled.RightButtonWrapper>
                <Select
                  className="trnx__search__select"
                  defaultValue="Filter By"
                  size="large"
                  options={options}
                  bordered={false}
                />
                <Styled.ExportButtonWrapper>
                  <Icons.ReadPDF />
                  <Icons.ReadExcel />
                </Styled.ExportButtonWrapper>
                <Styled.ExportButtonWrapper>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => setCardRequest(true)}
                    className="flex justify-center items-center gap-3 mt-2 sm:mt-2 md:mt-2 lg:mt-0 xl:mt-0 2xl:mt-0"
                  >
                    Generate Card <Icons.AddMerchant />
                  </Button>
                </Styled.ExportButtonWrapper>
              </Styled.RightButtonWrapper>
            </Styled.Section>
          </Styled.Wrapper>

          <Styled.Wrapper sx="table__box">
            <Table
              dataSource={allCards}
              columns={card_columns}
              rowKey="id"
              rowSelection="checkbox"
              loading={loading}
              pagination={{
                total: pageSize * cards?.data?.totalPages,
                pageSize: pageSize,
                current: currentPage,
                showSizeChanger: true,
                onChange: handlePageChange,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} cards`,
                pageSizeOptions: [ "10", "20", "30", "40", "50", "60", "70", "80", "100",
                ],
              }}
              className="centered-pagination"
              scroll={{ x: true}}
            />
          </Styled.Wrapper>
        </Styled.InnerWrapper>

        {/* card details by user */}
        <Drawer
          title="Card Transactions"
          placement="right"
          open={isCardVisible}
          onClose={closeDrawer}
          closable={false}
          width={700}
          extra={<Icons.CancelApproIcon onClick={closeDrawer} />}
        >
          <>
            {isCardSelected && <CardDetails cardId={isCardSelected} />}
            {isCardSelected && <CardTransactions cardId={isCardSelected} />}
          </>
        </Drawer>
      </DashboardLayout>

      {cardRequest && (
        <Styled.TgModal
          title={<ModalTitle close={() => setCardRequest(false)} />}
          open={() => setCardRequest(true)}
          closable={false}
          footer={null}
          width="512px"
        >
          <GenerateVoucherCards close={() => setCardRequest(false)} />
        </Styled.TgModal>
      )}

      {isFlagCard && selectedCardId && (
        <Styled.TgModal
          title={
            <ModalTitle
              title={`Flag - ${selectedCardId}`}
              close={() => setIsFlagCard(false)}
            />
          }
          open={isFlagCard}
          width={512}
          footer={null}
          closable={false}
        >
          <FlagFormCard
            cardNumber={selectedCardId}
            close={() => setIsFlagCard(false)}
          />
        </Styled.TgModal>
      )}
    </>
  );
};

export default Cards;
