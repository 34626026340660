import React, { useState } from "react";
import { Styled } from "../../constants/Styled";
import { Images } from "../../constants/Images";
import { AuthLayout } from "../../common";
import { Button } from "antd";
import { useFormik } from "formik";
import { schema } from "../../schema/Schema";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { userForgotPassword } from "../../state/forgotPassword.Slice";

const Forgot = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: schema.forgotPasswordSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const response = await dispatch(userForgotPassword(values)).unwrap();
        toast.success(response.message);
        setIsLoading(false);
      } catch (err) {
        toast.error(err.message);
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <AuthLayout>
        <center>
          <Styled.Img img={Images.Logo} alt="" />
        </center>
        <Styled.Text sx="reset__password__title">RESET PASSWORD</Styled.Text>
        <Styled.Text sx="new__auth__desc">
          Please enter your email to reset password
        </Styled.Text>
        <Styled.Form sx="new__login__input" onSubmit={formik.handleSubmit}>
          <Styled.Section sx="new__auth__wrapper pt-10">
            <Styled.Span>Email Address</Styled.Span>
            <Styled.Input 
            type="email" 
            placeholder="Email" 
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
             <Styled.Section sx="error">
              {formik.touched.email &&
                formik.errors.email &&
                formik.errors.email}
            </Styled.Section>
          </Styled.Section>

          <Button
            type="primary"
            size="large"
            className="w-full mt-14 text-[20px] font-semibold"
            htmlType="submit"
            loading={isLoading}
          >
            Reset
          </Button>

          <Styled.Text sx="auth__sign__link mt-2">
            Remembered Password?
            <Styled.Linked as="/">Login</Styled.Linked>
          </Styled.Text>
        </Styled.Form>
      </AuthLayout>
    </>
  );
};

export default Forgot;
