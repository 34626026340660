import React, { useState } from "react";
import ProfileDetails from "./profile/Profile.Detail";
import { Modal } from "antd";
import ProfileEdit from "./profile/Profile.Edit";
import { ModalTitle } from "../../../common";

const SettingProfile = () => {
  const [editProfile, setEditProfile] = useState(false);

  return (
    <>
      <ProfileDetails edit={() => setEditProfile(true)} />

      {editProfile && (
        <Modal
          title={
            <ModalTitle
              title="Edit Personal Profile"
              close={() => setEditProfile(false)}
            />
          }
          centered
          open={editProfile}
          footer={null}
          closable={false}
          width={512}
        >
          <ProfileEdit close={() => setEditProfile(false)} />
        </Modal>
      )}
    </>
  );
};

export default SettingProfile;
