import React from "react";
import { Styled } from "../constants/Styled";

const ModalTitle = (props) => {
  return (
    <Styled.Wrapper sx="modal__title">
      <Styled.Text>{props.title}</Styled.Text>{" "}
      <Styled.Text onClick={props.close}>X</Styled.Text>
    </Styled.Wrapper>
  );
};

export default ModalTitle;
