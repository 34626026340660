import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { Styled } from "../../../constants/Styled";
import { CardsRecord, ModalTitle } from "../../../common"
import TrnxDisputes from "./Trnx.Disputes";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactionAnalytics } from "../../../state/transactionAnalytics.Slice";
import { Icons } from "../../../constants/Icons";
import CurrencyFormat from "react-currency-format";


const CardRecordTransaction = () => {
  const dispatch = useDispatch();
  const [isViewFlagged, setIsViewFlagged] = useState(false);
  const { transactionAnalytics } = useSelector((state) => state.transactionAnalytics);

  useEffect(() => {
    dispatch(fetchTransactionAnalytics());
  }, [ dispatch])

  const trnx = transactionAnalytics?.data;

  const record = [
    {
        title: "SUCCESSFUL TRANSACTIONS",
        icon: <Icons.DashboardCardIcon />,
        value: <CurrencyFormat value={trnx?.transactionCount ?? 0 } thousandSeparator={true} displayType="text" />,
    },

    {
      title: "FAILED TRANSACTIONS",
      icon: <Icons.DashboardPendingCardIcon />,
      value: <CurrencyFormat value={trnx?.failTransactionCount ?? 0} thousandSeparator={true} displayType="text" />,
  },
  
    {
        title: "PENDING TRANSACTIONS",
        icon: <Icons.DashboardPendingCardIcon />,
        value: <CurrencyFormat value={trnx?.pendingTransactionCount ?? 0} thousandSeparator={true} displayType="text" />,
    },
  
    {
        title: "DISPUTED TRANSACTIONS",
        icon: <Icons.DashboardFlagCardIcon />,
        value: <CurrencyFormat value={trnx?.disputeTransactionCount ?? 0} thousandSeparator={true} displayType="text" />,
        view: "View"
    },
  ]

  return (
    <>
      <Styled.Wrapper sx="card__card__wrapper">
        {record.map((item, index) => (
           <CardsRecord
              index={index}
              icon={item.icon}
              title={item.title}
              currency={item.currency}
              amount={item.value}
              view_more={item.view}
              onClick={() => setIsViewFlagged(true)}
           />
        ))}
      </Styled.Wrapper>

      {isViewFlagged && (
        <Drawer
          title={<ModalTitle title="Disputes" close={() => setIsViewFlagged(false)} />}
          open={() => setIsViewFlagged(true)}
          placement="right"
          closable={false}
          size="large"
        >
           <TrnxDisputes />
        </Drawer>
      )}
    </>
  );
};

export default CardRecordTransaction;
