// ALL IMAGES

import AuthBg from "../assets/img/auth__bg.jpg";
import UserImg from "../assets/img/Avatar.png";
import TingoLogo from "../assets/img/tingo.png";
import Logo from "../assets/img/logo.png";
import MerchantLogo from "../assets/img/MerchantLogo.png";
import AuthCard from "../assets/img/authcard.png";
import AccessBank from "../assets/img/accessBank.png";
import TingoMerchant from "../assets/img/TingoPay_Merchant.png";
import SignUpBar1 from "../assets/img/SignUpBar1.png";
import SuccessIcon from "../assets/img/successIcon.svg";

export const Images = {
  AuthBg,
  UserImg,
  TingoLogo,
  Logo,
  MerchantLogo,
  AuthCard,
  AccessBank,
  TingoMerchant,
  SignUpBar1,
  SuccessIcon
};
