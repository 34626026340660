import React, { useState } from "react";
import {
  ActivateMerchant,
  DashboardCard,
  DashboardLayout,
  DashboardTable,
  ProfileBanner,
  UpdateProfile,
} from "../../components";
import { Styled } from "../../constants/Styled";
import { Button, Tour } from "antd";
import { kyc_data, tour_steps } from "../../data/DashboardData";
import { useSelector } from "react-redux";

const Overview = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); //kyc modal
  const [isMerchant, setIsMerchant] = useState(false);
  const [isMerchant1Completed, setIsMerchant1Completed] = useState(false);
  const [isMerchant2Completed, setIsMerchant2Completed] = useState(false);
  const { user } = useSelector((state) => state.auth);


  const openMerchant = (id) => {
    if (id === 1) {
      setIsMerchant1Completed(true);
      setIsMerchant(id);
    } else if (id === 2 && isMerchant1Completed) {
      setIsMerchant2Completed(true);
      setIsMerchant(id);
    } else if (id === 3 && isMerchant1Completed) {
      setIsMerchant(id);
    }
  };


  return (
    <>
      <div>
        <DashboardLayout>
          <Styled.Section sx="overview__card__dashboard">
          {user?.profile?.hasPin === true ? null : <ProfileBanner />}
            <DashboardCard />
          </Styled.Section>
          <Styled.InnerWrapper>
            <DashboardTable />
          </Styled.InnerWrapper>
        </DashboardLayout>

        <Styled.TgModal
          centered
          open={isModalOpen}
          closeIcon={false}
          closable={false}
          footer={null}
          width={900}
        >
          <Styled.Wrapper sx="kyc__modal">
            <Styled.Text>
              Hi, {user?.merchant?.first_name} - Welcome to [Partner] Voucher
              Service
            </Styled.Text>
            <Styled.Span sx="kyc__modal__desc">
              Activate your business for customers to start making payments
              using voucher cards. The guides below will show you how to do this
            </Styled.Span>

            <Styled.Wrapper sx="kyc__ver__wrapper">
              {kyc_data.map((item) => (
                <Styled.Section
                  sx="kyc__verification__stages"
                  key={item._id}
                  onClick={() => openMerchant(item._id)}
                >
                  {item.icon}
                  <Styled.Text>{item.title}</Styled.Text>
                  <Styled.Span>{item.desc}</Styled.Span>
                  {item.step}
                </Styled.Section>
              ))}
            </Styled.Wrapper>

            <Button
              type="primary"
              size="large"
              className="text-[20px] font-semibold"
              disabled={isMerchant === 2 || !isMerchant2Completed}
              onClick={() => setIsModalOpen(false)}
            >
              Continue to Dashboard
            </Button>
          </Styled.Wrapper>
        </Styled.TgModal>
      </div>

      {isMerchant === 1 && (
        <Styled.TgModal
          title="Merchant Profile"
          open={isMerchant === 1}
          closable={false}
          footer={null}
          width={450}
        >
          <ActivateMerchant closeModal={() => setIsMerchant(false)} />
        </Styled.TgModal>
      )}

      {isMerchant === 2 && (
        <Styled.TgModal
          // title={kyc_data[1].title}
          title="Personal Profile"
          open={isMerchant === 2}
          closable={false}
          footer={null}
          width={450}
        >
          <UpdateProfile closeModal={() => setIsMerchant(false)} />
        </Styled.TgModal>
      )}

      {isMerchant === 3 && (
        <Tour
          open={isMerchant}
          onClose={() => setIsMerchant(false)}
          steps={tour_steps}
          width={100}
        />
      )}
    </>
  );
};

export default Overview;
