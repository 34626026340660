import React, { useState } from "react";
import { Styled } from "../../../../constants/Styled";
import UploadPhotoProfile from "./UploadPhoto.Profile";
import PersonalProfile from "./Personal.Profile";
import MerchantInfoProfile from "./MerchantInfo.Profile";
import { ModalTitle } from "../../../../common";
import EditMerchantInfoProfile from "./Edit.Merchant.Profile";

const ProfileDetails = ({ edit }) => {
  const [ isEditMerchant, setIsEditMerchant ] = useState(false);

  return (
    <>
      <Styled.Wrapper sx="merchant__header">
        {/* user name, email and pic */}
        <UploadPhotoProfile />
        {/* user personal information */}
        <PersonalProfile edit={edit} />
        <MerchantInfoProfile edit={() => setIsEditMerchant(true)} />
      </Styled.Wrapper>

     {isEditMerchant && (
       <Styled.TgModal
        title={<ModalTitle title="Edit Merchant Profile" close={() => setIsEditMerchant(false)} />}
        open={isEditMerchant}
        closable={false}
        footer={null}
        width={512}
        bodyStyle={{ height: "600px"}}
       >
        <EditMerchantInfoProfile />
       </Styled.TgModal>
     )}
    </>
  );
};

export default ProfileDetails;
