import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from '../app/config';

const initialState = {
  flagCustomer: [],
  loading: false,
  error: null,
};


export const flagCustomerCustomer = createAsyncThunk(
  '/customer/customer/flagCustomer',
  async ({cardId, reason}, { rejectWithValue }) => {
    try {
      const response = await userRequest.patch(`/customers/flag/${cardId}`, { reason } );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const flagCustomerSlice = createSlice({
  name: 'flagCustomer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(flagCustomerCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(flagCustomerCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.flagCustomer = action.payload;
      })
      .addCase(flagCustomerCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});


export default flagCustomerSlice.reducer;
