import React from 'react';
import { ChangePassword } from '../components';


const PasswordChange = () => {
  return (
    <div>
        <ChangePassword />
    </div>
  )
}

export default PasswordChange