import React from "react";
import { Styled } from "../../../../constants/Styled";
import RoleMgrUserActiveMember from "./RoleMgr.User.ActiveMember";
import { Divider } from "antd";
import RoleMgrUserPendingInvite from "./RoleMgr.User.PendingInvite";



const RoleMgrUser = () => {


  return (
    <>
        <Styled.Wrapper>
            <RoleMgrUserActiveMember />
            <Divider />
            <RoleMgrUserPendingInvite />
        </Styled.Wrapper>
    </>
  );
};

export default RoleMgrUser;
