import { formatAmount, formatDateAndTime } from "../utils/Utils";


export const cus_trnx_columns = [

    {
        title: 'Card Number',
        dataIndex: 'card_number',
        sorter: (a, b) => a.card_number - b.card_number,
      },

      {
        title: 'Time/Date',
        dataIndex: 'created_at',
        render: formatDateAndTime,
        sorter: (a, b) => a.created_at - b.created_at,
      },

    {
      title: 'Amount',
      dataIndex: 'amount',
      render: formatAmount,
      sorter: (a, b) => a.amount - b.amount,
    },

   

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => <span className={`status-before-content ${status ? status : status}`}>{status.toLowerCase()}</span>,
      sorter: (a, b) => a.status - b.status,
    },

  ];
