

import { Icons } from '../constants/Icons';

export const menuItems = [
    {
        icon: <Icons.DashboardIcon style={{ fontSize: "32px"}} />,
        title: "Dashboard",
        link: "/dashboard/overview"
    },

    {
        icon: <Icons.WalletIcon style={{ fontSize: "32px"}} />,
        title: "Wallet",
        link: "/dashboard/wallet"
    },

    {
        icon: <Icons.WalletIcon style={{ fontSize: "32px"}} />,
        title: "Checkout",
        link: "/dashboard/checkout"
    },

    {
        icon: <Icons.TransactionsIcon />,
        title: "Transactions",
        link: "/dashboard/transactions"
    },

    {
        icon: <Icons.CustomersIcon />,
        title: "Customers",
        link: "/dashboard/customers"
    },

    {
        icon: <Icons.PaymentIcon />,
        title: "Cards",
        link: "/dashboard/cards"
    },

    {
        icon: <Icons.SettingIcon />,
        title: "Settings",
        link: "/dashboard/settings"
    },

    {
        icon: <Icons.ComplianceIcon />,
        title: "Help",
        link: "/dashboard/help"
    },

]

// export const returnSidebar = () => {
//     return menuItems.filter(item => {
//         if(['Help', 'Dashboard', 'Settings'].includes(item.title)) return true;
//         // 
//         if([].includes(item.title)) return true;
//         else return false;
//     })
// }



export const dashboard_card_inflow = [
    {
        title: "INFLOW",
        icon: <Icons.DashboardCardInflowIcon />
    },

    {
        title: "OUTFLOW",
        icon: <Icons.DashboardCardOutflowIcon />
    },

    {
        title: "TRANSACTION COUNT",
        icon: <Icons.DashboardCardTrnxCountIcon />
    },

    {
        title: "SUCCESS TRANSACTION",
        icon: <Icons.DashboardCardTrnxCountIcon />
    },

    {
        title: "PENDING TRANSACTION",
        icon: <Icons.DashboardCardTrnxCountIcon />
    },

    {
        title: "FAILED TRANSACTION",
        icon: <Icons.DashboardCardTrnxCountIcon />
    },
    


]


export const options = [
    {
      value: "merchants",
      label: "Merchants",
    },

    {
      value: "partners",
      label: "Partners",
    },
  ]



  export const affiliates__overview = [
    {
      value: "overview",
      label: "Overview",
    },

    {
      value: "transactions",
      label: "Transactions",
    },

    {
        value: "cards",
        label: "Cards",
      },

      {
        value: "customers",
        label: "Customers",
      },
  ]


  export const customers__overview = [
    {
      value: "overview",
      label: "Overview",
    },

    {
      value: "transactions",
      label: "Transactions",
    },

    {
        value: "cards",
        label: "Cards",
      },

  ]


  export const monthValidity = [
    {
        value: "jan",
        label: "January",
    },

    {
        value: "feb",
        label: "February",
    },

    {
        value: "mar",
        label: "March",
    }
]

export const yearValidity = [
    {
        value: "2023",
        label: "2023",
    },

    {
        value: "2024",
        label: "2024"
    },

    {
        value: "2025",
        label: "2025"
    }
]