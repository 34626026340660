import { Button, Divider, Modal, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { Styled } from "../../../../constants/Styled";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllRoles } from "../../../../state/roles.Slice";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { inviteStaffRole } from "../../../../state/inviteStaff.Slice";
import { schema } from "../../../../schema/Schema";
import { fetchAllPendingInvites } from "../../../../state/pendingInvite.Slice";
import { fetchAllJobDesignation } from "../../../../state/jobDesignation.Slice";

const InviteMember = ({ open, cancel, userId }) => {
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state.roles);
  const [closeModal, setCloseModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { jobDesignation } = useSelector((state) => state.jobDesignation);

  useEffect(() => {
    dispatch(fetchAllJobDesignation())
  }, [ dispatch])

  const job_desc = jobDesignation?.data || [];

  // to fetch all roles
  useEffect(() => {
    dispatch(fetchAllRoles());
  }, [dispatch]);

  const allRoles = roles?.data || [];
  // all roles end

  // a function to invite a staff
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email_address: "",
      role_id: "",
      job_designation: "",
      phone_number: "",
    },

    validationSchema: schema.inviteStaffSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await dispatch(inviteStaffRole(values)).unwrap();
        setLoading(false);
        toast.success(response?.message);
        dispatch(fetchAllPendingInvites({ page: 1, limit: 10}));
        setCloseModal(cancel);
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    },
  });

  // invite end

  return (
    <Modal title="Invite Member" open={open} onCancel={cancel} footer={null}>
      {/* merchant name */}
      <Styled.Form onSubmit={formik.handleSubmit}>
        {closeModal ? " " : ""}
        <Styled.Wrapper sx="flex justify-between items-center gap-4">
          <Styled.Section sx="new__auth__wrapper pt-5">
            <Styled.Span>First Name</Styled.Span>
            <Styled.Input
              type="text"
              placeholder="first name"
              name="first_name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Styled.Section sx="error">
              {formik.touched.first_name &&
                formik.errors.first_name &&
                formik.errors.first_name}
            </Styled.Section>
          </Styled.Section>

          {/* last name */}
          <Styled.Section sx="new__auth__wrapper pt-5">
            <Styled.Span>Last Name</Styled.Span>
            <Styled.Input
              type="text"
              placeholder="Last Name"
              name="last_name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Styled.Section sx="error">
              {formik.touched.last_name &&
                formik.errors.last_name &&
                formik.errors.last_name}
            </Styled.Section>
          </Styled.Section>
        </Styled.Wrapper>

        <Styled.Section sx="new__auth__wrapper pt-5">
          <Styled.Span>Email Address</Styled.Span>
          <Styled.Input
            type="email"
            placeholder="email address"
            name="email_address"
            value={formik.values.email_address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Styled.Section>

        <Divider />

        {/* ROLES */}
        <Styled.Caption title="Role" />
        <Styled.Wrapper>
          <Radio.Group
            name="role_id"
            value={formik.values.role_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {Array.isArray(allRoles) &&
              allRoles.map((item) => (
                <Radio value={item?.id} key={item?.id}>
                  {item?.name}
                </Radio>
              ))}      
          </Radio.Group>
          <Styled.Section sx="error">
            {formik.touched.role_id &&
              formik.errors.role_id &&
              formik.errors.role_id}
          </Styled.Section>
        </Styled.Wrapper>

        <Styled.Section sx="new__auth__wrapper pt-5">
          <Styled.Span>Job Description</Styled.Span>
          <Styled.Select
            type="text"
            name="job_designation"
            value={formik.values.job_designation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="">Select a Job Description</option>
            {job_desc.map((item) => (
                <option value={item?.slug} key={item?.id}>{item?.name}</option>
              ))}
          </Styled.Select>

          <Styled.Section sx="error">
            {formik.touched.job_designation &&
              formik.errors.job_designation &&
              formik.errors.job_designation}
          </Styled.Section>
        </Styled.Section>

        <Styled.Section sx="new__auth__wrapper pt-5">
          <Styled.Span>Phone Number</Styled.Span>
          <Styled.Input
            type="text"
            placeholder="Phone Number"
            name="phone_number"
            value={formik.values.phone_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            maxLength={15}
          />
          <Styled.Section sx="error">
            {formik.touched.phone_number &&
              formik.errors.phone_number &&
              formik.errors.phone_number}
          </Styled.Section>
        </Styled.Section>

        <>
          <Styled.Wrapper sx="add__affiliates__button__wrapper pt-4">
            <Button onClick={cancel} size="large" className="w-full">
              Cancel
            </Button>
            <Button
              type="primary"
              size="large"
              className="w-full"
              htmlType="submit"
              loading={loading}
            >
              Send Invite
            </Button>
          </Styled.Wrapper>
        </>
      </Styled.Form>
    </Modal>
  );
};

export default InviteMember;
