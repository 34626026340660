import React, { useState } from "react";
import { Styled } from "../../../constants/Styled";
import { Button, message } from "antd";
import { trnxPinData } from "../../../data/transactionPin.Details";

const TransactionPinWallet = ({ close }) => {
  const [isClose, setIsClose] = useState(false);
  const [pinDigit1, setPinDigit1] = useState("");
  const [pinDigit2, setPinDigit2] = useState("");
  const [pinDigit3, setPinDigit3] = useState("");
  const [pinDigit4, setPinDigit4] = useState("");

  const handlePinSubmit = () => {
    const transactionPin = pinDigit1 + pinDigit2 + pinDigit3 + pinDigit4;
    const correctPin = "1234";
    if (transactionPin === correctPin) {
      message.success("You have successfully made a cash out to your bank Account"); 
      setIsClose(close);
    } else {
      message.error("Incorrect pin, please try again!");
      setPinDigit1("");
      setPinDigit2("");
      setPinDigit3("");
      setPinDigit4("");
    }
  };

  return (
    <>
      <Styled.Section sx="new__auth__wrapper mt-9">
        {/* transaction details */}
        {trnxPinData.map((item, i) => (
          <Styled.Wrapper sx="trnx__details" key={i}>
            <Styled.Span>{item.title}</Styled.Span>
            <Styled.Span sx="trnx__details__right">{item.desc}</Styled.Span>
          </Styled.Wrapper>
        ))}

        {isClose ? "Close" : ""}
        <Styled.Span sx="trnx__tag">Enter Transaction Pin</Styled.Span>
        <Styled.Section sx="trnx__pin__input">
          <Styled.Input
            type="password"
            maxLength={1}
            placeholder="0"
            value={pinDigit1}
            onChange={(e) => setPinDigit1(e.target.value)}
          />

          <Styled.Input
            type="password"
            maxLength={1}
            placeholder="0"
            value={pinDigit2}
            onChange={(e) => setPinDigit2(e.target.value)}
          />

          <Styled.Input
            type="password"
            maxLength={1}
            placeholder="0"
            value={pinDigit3}
            onChange={(e) => setPinDigit3(e.target.value)}
          />

          <Styled.Input
            type="password"
            maxLength={1}
            placeholder="0"
            value={pinDigit4}
            onChange={(e) => setPinDigit4(e.target.value)}
          />
        </Styled.Section>
      </Styled.Section>

      <Styled.Section sx="trnx__pay__btn">
        <Button 
        type="primary" 
        onClick={handlePinSubmit}
        >
          Withdraw
        </Button>
      </Styled.Section>
    </>
  );
};

export default TransactionPinWallet;
