import {
  Logo2,
  EasyCheckoutIcon,
  CheckIcon,
  NavIcon,
  PaymentLinkIcon,
  RecurringIcon,
  CheckoutIcon,
  ApiIcon,
  ArrowRight,
  Security,
  Facebook,
  Instagram,
  Linkedin,
  PinTress,
  DashboardIcon,
  ComplianceIcon,
  InvoiceIcon,
  CustomersIcon,
  PaymentIcon,
  TransactionsIcon,
  BalanceIcon,
  DocumentIcon,
  SupportIcon,
  SettingIcon,
  ToggleIcon,
  SearchIcon,
  BellIcon,
  HelpIcon,
  ChevronDropdown,
  AdminIcon2,
  DashboardProfileIcon,
  DashboardCardIcon,
  DashboardCardActiveIcon,
  DashboardCardBalIcon,
  DashboardCardCustomerIcon,
  DashboardCardMerchantIcon,
  DashboardCardTrnxIcon,
  DashboardCardPartnerIcon,
  DashboardCardInflowIcon,
  DashboardCardOutflowIcon,
  DashboardCardTrnxCountIcon,
  ReadPDF,
  ReadExcel,
  AddMerchant,
  Flag,
  BlockIcon,
  ActivateIcon,
  CopyIcon,
  EditIcon,
  GooglePlayIcon,
  ApplePlayIcon,
  DeleteIcon,
  DangerIcon,
  ActivateBusinessIcon,
  Number1,
  PersonalDetailIcon,
  DashboardTourIcon,
  Number2,
  Number3,
  WalletIcon,
  CashOutIcon,
  CreatePinIcon,
  IconSearch,
  ViewIcon,
  FlagIcon,
  DashboardFlagCardIcon,
  DashboardPendingCardIcon,
  DashboardCardTrnxCount,
  DashboardCardCheckoutInflow,
  DownloadCardTemplate,
  InfoIcon,
  UploadXLSIcon,
  SelectedFileIcon,
  FlagCardBtn,
  UnflagCardBtn,
  HelpCenterIcon,
  HelpLabelIcon,
  HelpFaqIcon,
  EmailIcon,
  CallIcon,
  SuccessIcon
} from "../assets/icons/icons";
// import { GoPrimitiveDot as Dot } from "react-icons/go";
import { MdOutlineMenuOpen as MenuIcon } from "react-icons/md";
import { RiMenuFoldLine as MenuIcon2 } from "react-icons/ri";
import { RiMenuUnfoldLine as MenuIcon3 } from "react-icons/ri";
import { ImSpinner3 as Spinner } from "react-icons/im";
import {
  BsCheckCircleFill as ApprovedIcon,
  BsChevronRight as NextIcon,
  BsFillCloudUploadFill as UploadIcon,
  BsThreeDotsVertical as ActionDot
} from "react-icons/bs";
import { FaLockOpen as BlockOpen } from "react-icons/fa";
import {
  CheckOutlined as CheckApproIcon,
  CloseOutlined as CancelApproIcon,
} from "@ant-design/icons";
import {
  AiOutlineCheck as CheckNotifyApprovedIcon,
  AiOutlineClose as CheckNotifyCancelIcon,
} from "react-icons/ai";

export const Icons = {
  DeleteIcon,
  GooglePlayIcon,
  ApplePlayIcon,
  NextIcon,
  CheckNotifyApprovedIcon,
  CheckNotifyCancelIcon,
  EditIcon,
  CopyIcon,
  ActivateIcon,
  BlockIcon,
  Flag,
  AddMerchant,
  DashboardCardInflowIcon,
  DashboardCardOutflowIcon,
  DashboardCardTrnxCountIcon,
  DashboardCardPartnerIcon,
  DashboardCardActiveIcon,
  DashboardCardBalIcon,
  DashboardCardCustomerIcon,
  DashboardCardMerchantIcon,
  DashboardCardTrnxIcon,
  DashboardCardIcon,
  DashboardProfileIcon,
  CancelApproIcon,
  CheckApproIcon,
  BlockOpen,
  ApprovedIcon,
  ActionDot,
  Spinner,
  AdminIcon2,
  NavIcon,
  PaymentLinkIcon,
  RecurringIcon,
  CheckoutIcon,
  ApiIcon,
  EasyCheckoutIcon,
  // Dot,
  CheckIcon,
  ArrowRight,
  Security,
  Logo2,
  Facebook,
  Instagram,
  Linkedin,
  PinTress,
  MenuIcon,
  MenuIcon2,
  MenuIcon3,
  DashboardIcon,
  ComplianceIcon,
  InvoiceIcon,
  CustomersIcon,
  PaymentIcon,
  TransactionsIcon,
  BalanceIcon,
  DocumentIcon,
  SupportIcon,
  SettingIcon,
  ToggleIcon,
  SearchIcon,
  BellIcon,
  HelpIcon,
  ChevronDropdown,
  ReadPDF,
  ReadExcel,
  DangerIcon,
  ActivateBusinessIcon,
  Number1,
  Number2,
  Number3,
  PersonalDetailIcon,
  DashboardTourIcon,
  UploadIcon,
  WalletIcon,
  CashOutIcon,
  CreatePinIcon,
  IconSearch,
  ViewIcon,
  FlagIcon,
  DashboardFlagCardIcon,
  DashboardPendingCardIcon,
  DashboardCardTrnxCount,
  DashboardCardCheckoutInflow,
  DownloadCardTemplate,
  InfoIcon,
  UploadXLSIcon,
  SelectedFileIcon,
  FlagCardBtn,
  HelpCenterIcon,
  HelpLabelIcon,
  HelpFaqIcon,
  EmailIcon,
  CallIcon,
  SuccessIcon,
  UnflagCardBtn
};
