import React from "react";
import { Styled } from "../../../../constants/Styled";
import { Switch } from "antd";

const SecurityNotification = () => {
 
  return (
    <>
      <Styled.Section sx="pt-10">
        <Styled.Caption title="Notifications" />
        <Styled.Wrapper sx="security__wrapper">
          <Styled.Section sx="border__inner notify__pass p-8">
            <Styled.Form>

                <Styled.Section sx="check__notify">
                    Receive Email Notification
                    <Switch />
                </Styled.Section>

                <Styled.Section sx="check__notify pt-3">
                    Push Notification
                    <Switch defaultChecked />
                </Styled.Section>

                <Styled.Section sx="check__notify pt-3">
                    Third Party Apps Notifications
                    <Switch />
                </Styled.Section>

            </Styled.Form>
          </Styled.Section>
        </Styled.Wrapper>
      </Styled.Section>
    </>
  );
};

export default SecurityNotification;
