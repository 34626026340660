import React from "react";
import { Styled } from "../constants/Styled";

const ConfirmTrnxDesc = (props) => {
  return (
    <>
      <Styled.Section sx="confirm__bulk__list">
        <Styled.Text>{props.title}</Styled.Text>
        <Styled.Text>{props.value}</Styled.Text>
      </Styled.Section>
    </>
  );
};

export default ConfirmTrnxDesc;
