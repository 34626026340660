import {
  Container,
  Content,
  Wrapper,
  Layout,
  Section,
  Aside,
  Text,
  Span,
  Linked,
  List,
  ListItem,
  Card,
  Form,
  Input,
  Header,
  Img,
  Select,
  Block,
  Activate,
  Message,
  Caption,
  TgModal,
  ViewDetail,
  InnerWrapper,
  ExportButtonWrapper,
  RightButtonWrapper,
  Flagged,
  Unflagged
} from "../common/Styled/Styled";

export const Styled = {
  Caption,
  Activate,
  Block,
  Container,
  Wrapper,
  Section,
  Text,
  Span,
  Linked,
  List,
  ListItem,
  Card,
  Form,
  Input,
  Header,
  Img,
  Select,
  Aside,
  Layout,
  Content,
  Message,
  TgModal,
  ViewDetail,
  InnerWrapper,
  ExportButtonWrapper,
  RightButtonWrapper,
  Flagged,
  Unflagged
};
