
import React, { useEffect, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerTnx } from "../../../state/customerTrnxSlice"
import { cus_trnx_columns } from "../../../data/customerTrnxData";
import { Styled } from "../../../constants/Styled";
import { Table } from "antd";




const CustomerTrnx = ({ customerId }) => {
  const dispatch = useDispatch();
  const { customertnx, loading } = useSelector((state) => state.customertnx);

  useEffect(() => {
    dispatch(fetchCustomerTnx({ cardId: customerId?.phone_number}))
  }, [dispatch, customerId]);

  const cusTrnx = customertnx?.data;

  return (
    <>

     {/* table */}
     <Styled.Wrapper sx="table__box">
          <Table 
            dataSource={cusTrnx}
            columns={cus_trnx_columns}
            loading={loading}
            pagination={true}
          />
     </Styled.Wrapper>
    </>
  );
};

export default CustomerTrnx;
