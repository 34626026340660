import React from 'react'
import { RegisterSuccess } from '../components'

const SignUpSuccess = () => {
  return (
    <div>
        <RegisterSuccess />
    </div>
  )
}

export default SignUpSuccess