import React, { useState } from "react";
import { Styled } from "../../../../constants/Styled";
import { Button, Upload } from "antd";
import { Icons } from "../../../../constants/Icons";
import { useSelector } from "react-redux";
import { Images } from "../../../../constants/Images";

const UploadPhotoProfile = () => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const { user } = useSelector((state) => state.auth);

  const handleImageUpload = (file) => {
    setUploadedImage(file);
  };

  return (
    <Styled.Section sx="merchant__overview__header__wrapper border__inner">
      <Styled.Card sx="merchant__overview__header">
        {uploadedImage ? (
          <Styled.Img
            img={URL.createObjectURL(uploadedImage)}
            alt={user?.profile?.first_name}
          />
        ) : (
          <Styled.Img img={Images.UserImg} alt={user?.profile?.first_name} />
        )}
        <Styled.Section>
          <Styled.Text>
            {`${
              user?.profile?.first_name
            } ${user?.profile?.last_name }`}
          </Styled.Text>
          <Styled.Span>
            {user?.profile?.email_address}
          </Styled.Span>
        </Styled.Section>
      </Styled.Card>
      <Upload beforeUpload={handleImageUpload}>
        <Button size="large" className="btn__icon__gap">
          Update Image <Icons.EditIcon />
        </Button>
      </Upload>
    </Styled.Section>
  );
};

export default UploadPhotoProfile;
