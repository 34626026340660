import React, { useEffect, useState } from "react";
import { Styled } from "../../../constants/Styled";
import { Input, Select, Table } from "antd";
import { CustomDateRangePicker } from "../../../common";
import { Icons } from "../../../constants/Icons";
import { options } from "../../../data/Data";
import { audit_columns } from "../../../data/auditLogData";
import { useDispatch, useSelector } from "react-redux";
import { fetchAuditLog } from "../../../state/auditLog.Slice";

const SettingAuditLog = () => {
  const dispatch = useDispatch();
  const { auditLog, loading } = useSelector((state) => state.auditLog);
   const [ currentPage, setCurrentPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);

  useEffect(() => {
    dispatch(fetchAuditLog({page : currentPage, limit: pageSize}));
  },[dispatch, currentPage, pageSize])

  const allLog =  auditLog?.data?.auditLogs;

   // pagination
  const handlePageChange = (page, newPageSize) => {
    setCurrentPage(page);
    setPageSize(newPageSize);
  };


  const moreColumn = {
    title: "More Action",
    dataIndex: "id",
    key: "actions",
    render: (_, record) => (

        <Icons.ActionDot
          size={20}
          color="#72747D"
          width="15.12px"
          height="15.12px"
          onClick={(e) => e.preventDefault()}
        />
    ),
    align: "center",
  };

  const audit__action = [ ...audit_columns, moreColumn]

  return (
    <>
        <Styled.Wrapper>
          <Styled.Section sx="merchant__filters">
            <Input
              className="trnx__search__input"
              type="search"
              size="large"
              prefix={<Icons.IconSearch />}
              placeholder="Search Transactions"
              style={{
                width: "300px",
              }}
            />

            <Styled.RightButtonWrapper>
              <Select
                className="trnx__search__select"
                defaultValue="Filter By"
                size="large"
                options={options}
                bordered={false}
              />
              <CustomDateRangePicker />
              <Styled.ExportButtonWrapper>
                <Icons.ReadPDF />
                <Icons.ReadExcel />
              </Styled.ExportButtonWrapper>
            </Styled.RightButtonWrapper>
          </Styled.Section>
        </Styled.Wrapper>

        <Styled.Wrapper sx="table__box">
          <Table 
          columns={audit__action} 
          dataSource={allLog}
          loading={loading}
          pagination={{
              total: pageSize * auditLog?.data?.totalPages,
              pageSize: pageSize,
              current: currentPage,
              showSizeChanger: true,
              onChange: handlePageChange,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} logs`,
              pageSizeOptions: ["10", "20", "30", "40", "50", "60", "70", "80", "100"],
            }}
            className="centered-pagination"
           />
        </Styled.Wrapper>
    </>
  );
};

export default SettingAuditLog;
