import React from "react";
import { Styled } from "../../constants/Styled";
import { Button, Dropdown } from "antd";
import { Icons } from "../../constants/Icons";
import { Images } from "../../constants/Images";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../state/authSlice";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const location = useLocation();

  const items = [
    {
      label :  (
       <Styled.Linked as="/" onClick={() => dispatch(logoutUser())}>Logout</Styled.Linked>
      ),
    }
  ]

  return (
    <Styled.Wrapper sx="navbar__wrapper">
        <Styled.Text sx="navbar__path">
        {location.pathname.split("/")[2]}
      </Styled.Text>

      <Styled.Section sx="navbar__right">
        <Icons.BellIcon />
        <Styled.Img img={Images.UserImg} alt="" />
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
          arrow
        >
          <Button className="nav__item__user__details">
            <Styled.Section sx="block">
                <Styled.Text>{user?.profile?.first_name} {user?.profile?.last_name} </Styled.Text>
                <Styled.Text> {user?.profile?.email_address} </Styled.Text>
            </Styled.Section>
            <Icons.ChevronDropdown />
          </Button>
        </Dropdown>
      </Styled.Section>
    </Styled.Wrapper>
  );
};

export default Navbar;
