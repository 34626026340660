import React, { useEffect } from "react";
import { Styled } from "../../../constants/Styled";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardAnalytics } from "../../../state/dashboardAnalytics.Slice";
import { Icons } from "../../../constants/Icons";
import CurrencyFormat from "react-currency-format";


const DashboardCard = () => {

  const dispatch = useDispatch();
  const { dashboardAnalytics } = useSelector((state) => state.dashboardAnalytics);

  useEffect(() => {
    dispatch(fetchDashboardAnalytics());
  }, [dispatch])


  const record = [
    {
        title: "CHECKOUT INFLOW",
        icon: <Icons.DashboardCardCheckoutInflow />,
        value: <CurrencyFormat value={dashboardAnalytics?.data?.checkoutInflow ?? 0 } thousandSeparator={true} displayType={'text'} prefix="₦ " />
        
    },

    {
        title: "TRANSACTION COUNT",
        icon: <Icons.DashboardCardTrnxCount />,
        value: dashboardAnalytics?.data?.transactionCount,
    },

    {
        title: "ALL CUSTOMERS",
        icon: <Icons.DashboardCardCustomerIcon />,
        value: dashboardAnalytics?.data?.allCustomers,
    },

    {
        title: "PROSPECTIVE CUSTOMERS",
        icon: <Icons.DashboardCardPartnerIcon />,
        value: <CurrencyFormat value={dashboardAnalytics?.data?.prospectiveCustomers ?? 0 } thousandSeparator={true} displayType={'text'} prefix="₦ " />
    },
]


  return (
    <Styled.Wrapper sx="dashboard__card">
      {record.map((dash) => (
        <Styled.Section sx="dashboard__card__content" key={dash.index}>
          {dash.icon}
          <Styled.Card>
            <Styled.Text>{dash.title}</Styled.Text>
            {dash.showCurrency ? (
              <Styled.Span>{dash.showCurrency} {dash.value}</Styled.Span>
            ) : (
              <Styled.Span>{dash.value}</Styled.Span>
            )}
          </Styled.Card>
        </Styled.Section>
      ))}
    </Styled.Wrapper>
  );
};

export default DashboardCard;
