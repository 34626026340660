import React, { useState } from "react";
import { Styled } from "../../../constants/Styled";
import { Button, Modal } from "antd";
import GenerateQrCode from "./Generate.QrCode";
import { ModalTitle } from "../../../common";

const QRCodePayment = () => {
    const [ isGenerated, setIsGenerated ] = useState(false);

    const handleGenerateQRCodePayment = () => {
        setIsGenerated(true);
    }

  return ( 
    <>
      <Styled.Wrapper sx="physical__card">
        <Styled.Text sx="checkout__title">Pay with QR Code</Styled.Text>
        <Styled.Section sx="physical__card__content">
          <Styled.Form>
            <Styled.Section sx="new__auth__wrapper">
              <Styled.Span>Amount</Styled.Span>
              <Styled.Input placeholder="₦ 0.00" />
            </Styled.Section>

            <Button 
            type="primary" 
            size="large" 
            className="mt-28"
            onClick={handleGenerateQRCodePayment}
            >
              Generate Code
            </Button>
          </Styled.Form>
        </Styled.Section>
      </Styled.Wrapper>

      {isGenerated && (
        <Modal 
            title={<ModalTitle close={() => setIsGenerated(false)} />}
            open={handleGenerateQRCodePayment}
            closable={false}
            footer={false}
        >
            <GenerateQrCode />
        </Modal>
      )
      }
    </>
  );
};

export default QRCodePayment;
