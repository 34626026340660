import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from '../app/config';

const initialState = {
  cardGenerate: [],
  loading: false,
  error: null,
};

export const cardGenerateSingle = createAsyncThunk('merchant/card/generate/single',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userRequest.post(`/cards/generate/single`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const cardGenerateSingleSlice = createSlice({
  name: 'cardGenerate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(cardGenerateSingle.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(cardGenerateSingle.fulfilled, (state, action) => {
        state.loading = false;
        state.cardGenerate = action.payload;
      })
      .addCase(cardGenerateSingle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })   
  },
});

export default cardGenerateSingleSlice.reducer;
