import { Drawer } from "antd";
import React, { useState } from "react";
import { Styled } from "../../../constants/Styled";
import { card_dashboard } from "../../../data/cardsData";
import { CardsRecord, ModalTitle } from "../../../common"
import FlaggedCards from "./flagged.Cards";

const CardCards = () => {
  const [isViewFlagged, setIsViewFlagged] = useState(false);

  return (
    <>
      <Styled.Wrapper sx="card__card__wrapper">
        {card_dashboard.map((item, index) => (
           <CardsRecord
              index={index}
              icon={item.icon}
              title={item.title}
              currency={item.currency}
              amount={item.value}
              view_more={item.view}
              onClick={() => setIsViewFlagged(true)}
           />
        ))}
      </Styled.Wrapper>

      {isViewFlagged && (
        <Drawer
          title={<ModalTitle title="Flagged" close={() => setIsViewFlagged(false)} />}
          open={() => setIsViewFlagged(true)}
          placement="right"
          closable={false}
          width={730}
        >
           <FlaggedCards />
        </Drawer>
      )}
    </>
  );
};

export default CardCards;
