import React, { useState } from "react";
import { Styled } from "../../constants/Styled";
import { Images } from "../../constants/Images";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { schema } from "../../schema/Schema";
import { registerUser } from "../../state/registerSlice";
import {
  AuthLayout,
  CreatePasswordForm,
  MerchantDetailsForm,
  PersonalDetailsForm,
  SignUpMultiple,
} from "../../common";
import { Button } from "antd";
import { toast } from "react-toastify";

const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async () => {
    if (!formik.values.acceptTerms) {
      formik.setFieldError(
        "acceptTerms",
        "Please accept the terms and conditions."
      );
      return;
    }

    setLoading(true);
    try {
      const response = await dispatch(registerUser(formik.values)).unwrap();
      toast.success(response.message);
      setLoading(false);
      navigate("/merchant/signup/verification", {
        state: { company_email: formik.values.company_email },
      });
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      company_name: "",
      industry_type: "",
      cac_number: "",
      office_address: "",
      first_name: "",
      last_name: "",
      company_email: "",
      company_phone_number: "",
      job_designation: "",
      password: "",
      confirm_password: "",
      acceptTerms: false,
    },
    validationSchema: schema.registerSchema,
    onSubmit,
  });

  const steps = [
    {
      title: "Merchant Details",
      content: <MerchantDetailsForm formik={formik} />,
    },

    {
      title: "Personal Details",
      content: <PersonalDetailsForm formik={formik} />,
    },

    {
      title: "Create Password",
      content: <CreatePasswordForm formik={formik} />,
    },
  ];

  return (
    <>
      <AuthLayout>
        <center>
          <Styled.Img img={Images.Logo} alt="" />
        </center>
        <Styled.Text sx="reset__password__title">Sign Up</Styled.Text>
        <Styled.Form sx="new__login__input" onSubmit={formik.handleSubmit}>
          <SignUpMultiple steps={steps} currentStep={currentStep} />
          <Styled.Section sx="flex justify-center">
            {currentStep > 1 && (
              <Button
                size="large"
                type="primary"
                onClick={handlePrevStep}
                className="w-full text-[20px] font-semibold mr-2"
              >
                Previous
              </Button>
            )}
            {currentStep < steps.length && (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                onClick={handleNextStep}
                className="w-full text-[20px] font-semibold"
              >
                Next
              </Button>
            )}

            {currentStep === steps.length && (
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                className="w-full text-[20px] font-semibold"
                loading={loading}
              >
                Submit
              </Button>
            )}
          </Styled.Section>

          <Styled.Text sx="auth__sign__link">
            Already have an account?
            <Styled.Linked as="/">Login</Styled.Linked>
          </Styled.Text>
        </Styled.Form>
      </AuthLayout>
    </>
  );
};

export default SignUp;
