import React from "react";
import { Button } from "antd";
import { Styled } from "../../../constants/Styled";
import { OTPInputs } from "../../../common";

const ConfirmCardRequestPin = ({ formik, loading }) => {
  return (
    <>
    <Styled.Form onSubmit={formik.handleSubmit}>
    <Styled.Section sx="trnx__pin__input flex-col create__pin">
      <Styled.Text sx="title">Enter Transaction Pin</Styled.Text>
        <Styled.Span sx="desc">
          To ensure that every transaction you make is safe
          and secure.
        </Styled.Span>

        <OTPInputs
          length={4}
          value={formik.values.pin}
          onChange={(pinValue) => formik.setFieldValue("pin", pinValue)}
          onBlur={formik.handleBlur}
        />
        <Styled.Section sx="error">
          {formik.touched.pin && formik.errors.pin && formik.errors.pin}
        </Styled.Section>
      </Styled.Section>

      <Styled.Section sx="flex justify-center items-center">
      <Button
        className="mt-8"
        htmlType="submit"
        type="primary"
        loading={loading}
      >
        Confirm
      </Button>
      </Styled.Section>
    </Styled.Form>
    </>
  );
};

export default ConfirmCardRequestPin;
