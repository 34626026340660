import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userRequest } from "../app/config";

const initialState = {
  singlecustomer: [],
  loading: false,
  error: null,
};

export const fetchSingleCustomer = createAsyncThunk("/merchant/customer/information",
  async ({ cardId }, { rejectWithValue }) => {
    try {
      const response = await userRequest.get(`/customers/${cardId}`, null);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const updateCustomerStatus = createAsyncThunk('/merchant/customers/update/status',
    async ({phone}, { rejectWithValue }) => {
      try {
        const response = await userRequest.patch(`/customers/status/${phone}`, null);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

const singleCustomerSlice = createSlice({
  name: "singlecustomer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSingleCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.singlecustomer = action.payload;
      })
      .addCase(fetchSingleCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateCustomerStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCustomerStatus.fulfilled, (state, action) => {
        state.loading = false;
        const updatedCustomer = action.payload;
        state.singlecustomer = {
          ...state.singlecustomer,
          user: {
            ...state.singlecustomer.user,
            status: updatedCustomer.status,
          },
        };
        state.error = null;    
      })
      
      .addCase(updateCustomerStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export default singleCustomerSlice.reducer;
