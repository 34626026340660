import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from '../app/config';


const initialState = {
  auditLog: [],
  loading: false,
  error: null,
};




export const fetchAuditLog = createAsyncThunk('merchant/auditLog/activities',
  async ({page, limit}, { rejectWithValue }) => {
    try {
      const response = await userRequest.get(`/merchant/audit-log/?page=${page}&limit=${limit}`,);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const auditLogSlice = createSlice({
  name: 'auditLog',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuditLog.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAuditLog.fulfilled, (state, action) => {
        state.loading = false;
        state.auditLog = action.payload;
      })
      .addCase(fetchAuditLog.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })  
  },
});

export default auditLogSlice.reducer;
