
import React from "react";
import { AcceptInvite, ChangePassword, Checkout, Customers, Dashboard,  EmailVerified,  ForgotPassword,  HelpCenter,  LoginPage, PaymentLinks, Settings, SignUpSuccess, Transactions, UserSignUp, Wallet } from "./pages";
import { BrowserRouter as Router, Route, Routes,  } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { ProtectedRoute } from "./app/ProtectedRoute";


const App = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" exact index element={<LoginPage />} />
          <Route path="/forgot-password" exact index element={<ForgotPassword />} />
          <Route path="/change-password/:token" exact index element={<ChangePassword />} />
          <Route path="/merchant-signup" exact index element={<UserSignUp />} />
          <Route path="/merchant/signup/verification" exact index element={<SignUpSuccess />} />
          <Route path="/merchant/verify/:verification_code" exact index element={<EmailVerified />} />
          <Route path="/merchant/user/accept/invite/:token" exact index element={<AcceptInvite />} />
          <Route element={<ProtectedRoute user={user} redirect="/"/>}>
              <Route path="/dashboard/overview" exact element={<Dashboard />} />
              <Route path="/dashboard/wallet" exact element={<Wallet />} />
              <Route path="/dashboard/checkout" exact element={<Checkout />} />
              <Route path="/dashboard/transactions" exact element={<Transactions />} />
              <Route path="/dashboard/customers" exact element={<Customers />} />
              <Route path="/dashboard/cards" exact element={<PaymentLinks />} />
              <Route path="/dashboard/settings" exact element={<Settings />} />
              <Route path="/dashboard/help" exact element={<HelpCenter />} />
          </Route>
        </Routes>
      </Router>
      <ToastContainer position="top-right" autoClose={6000}/>
    </>
  )
};

export default App;
