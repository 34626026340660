import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from '../app/config';


const initialState = {
  roles: [],
  loading: false,
  error: null,
};

export const fetchAllRoles = createAsyncThunk('merchant/user/all/roles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await userRequest.get(`/merchant/role`,);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRoles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.roles = action.payload;
      })
      .addCase(fetchAllRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }) 
  },
});

export default rolesSlice.reducer;
