import { Button, Checkbox, Divider, Drawer, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Styled } from "../../../../constants/Styled";
import { ModalTitle, TableRoleHeader } from "../../../../common";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { schema } from "../../../../schema/Schema";
import { toast } from "react-toastify";
import { createNewRole } from "../../../../state/createRole.Slice";
import { fetchAllRoles } from "../../../../state/roles.Slice";

const AddNewRole = ({ open, cancel }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      permissions: {
        Customers: {
          Read: false,
          Create: false,
          Edit: false,
          Delete: false,
          Update: false,
          Approve: false,
          Export: false,
        },
        Transactions: {
          Read: false,
          Create: false,
          Edit: false,
          Delete: false,
          Update: false,
          Approve: false,
          Export: false,
        },
        Cards: {
          Read: false,
          Create: false,
          Edit: false,
          Delete: false,
          Update: false,
          Approve: false,
          Export: false,
        },
        Partners: {
          Read: false,
          Create: false,
          Edit: false,
          Delete: false,
          Update: false,
          Approve: false,
          Export: false,
        },
        Merchants: {
          Read: false,
          Create: false,
          Edit: false,
          Delete: false,
          Update: false,
          Approve: false,
          Export: false,
        },
        Wallet: {
          Read: false,
          Create: false,
          Edit: false,
          Delete: false,
          Update: false,
          Approve: false,
          Export: false,
        },
        Checkout: {
          Read: false,
          Create: false,
          Edit: false,
          Delete: false,
          Update: false,
          Approve: false,
          Export: false,
        },
        Audit_Log: {
          Read: false,
          Create: false,
          Edit: false,
          Delete: false,
          Update: false,
          Approve: false,
          Export: false,
        },
        Role_Management: {
          Read: false,
          Create: false,
          Edit: false,
          Delete: false,
          Update: false,
          Approve: false,
          Export: false,
        },
      },
    },
    validationSchema: schema.createRoleSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const data__row = Object.keys(values.permissions).map((key) => ({
          id: key,
          ...values.permissions[key],
        }));

        const permissionsObject = {};
        data__row.forEach((row) => {
          permissionsObject[row.id] = { ...row };
          delete permissionsObject[row.id].id;
        });

        const payload = {
          name: values.name,
          description: values.description,
          permissions: permissionsObject,
        };

        const response = await dispatch(createNewRole(payload)).unwrap();
        setIsLoading(false);
        toast.success(response?.message);
        formik.resetForm();
        cancel();
        dispatch(fetchAllRoles());
      } catch (err) {
        setIsLoading(false);
        toast.error(err.message);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const initialDataRows = [
    {
      id: "Customers",
      Read: false,
      Create: false,
      Edit: false,
      Delete: false,
      Update: false,
      Approve: false,
      Export: false,
    },

    {
      id: "Transactions",
      Read: false,
      Create: false,
      Edit: false,
      Delete: false,
      Update: false,
      Approve: false,
      Export: false,
    },

    {
      id: "Cards",
      Read: false,
      Create: false,
      Edit: false,
      Delete: false,
      Update: false,
      Approve: false,
      Export: false,
    },

    {
      id: "Partners",
      Read: false,
      Create: false,
      Edit: false,
      Delete: false,
      Update: false,
      Approve: false,
      Export: false,
    },

    {
      id: "Merchants",
      Read: false,
      Create: false,
      Edit: false,
      Delete: false,
      Update: false,
      Approve: false,
      Export: false,
    },

    {
      id: "Wallet",
      Read: false,
      Create: false,
      Edit: false,
      Delete: false,
      Update: false,
      Approve: false,
      Export: false,
    },

    {
      id: "Checkout",
      Read: false,
      Create: false,
      Edit: false,
      Delete: false,
      Update: false,
      Approve: false,
      Export: false,
    },

    {
      id: "Audit Log",
      Read: false,
      Create: false,
      Edit: false,
      Delete: false,
      Update: false,
      Approve: false,
      Export: false,
    },

    {
      id: "Role Management",
      Read: false,
      Create: false,
      Edit: false,
      Delete: false,
      Update: false,
      Approve: false,
      Export: false,
    },
  ];

  const [data__row, setDataRows] = useState(initialDataRows);
  const [permissionsChecked, setPermissionsChecked] = useState({
    Read: false,
    Create: false,
    Edit: false,
    Delete: false,
    Update: false,
    Approve: false,
    Export: false,
  });

  useEffect(() => {
    const allReadChecked = data__row.every((row) => row.Read);
    const allCreateChecked = data__row.every((row) => row.Create);
    const allEditChecked = data__row.every((row) => row.Edit);
    const allDeleteChecked = data__row.every((row) => row.Delete);
    const allUpdateChecked = data__row.every((row) => row.Update);
    const allApproveChecked = data__row.every((row) => row.Approve);
    const allExportChecked = data__row.every((row) => row.Export);

    setPermissionsChecked((prevState) => ({
      ...prevState,
      Read: allReadChecked,
      Create: allCreateChecked,
      Edit: allEditChecked,
      Delete: allDeleteChecked,
      Update: allUpdateChecked,
      Approve: allApproveChecked,
      Export: allExportChecked,
    }));
  }, [data__row]);

  const handlePermissionCheckboxChange = (permission, e) => {
    const checked = e.target.checked;
    const updatedDataRows = data__row.map((row) => ({
      ...row,
      [permission]: checked,
    }));

    setDataRows(updatedDataRows);

    formik.setValues((prevValues) => ({
      ...prevValues,
      permissions: {
        ...Object.keys(prevValues.permissions).reduce((acc, key) => {
          acc[key] = {
            ...prevValues.permissions[key],
            [permission]: checked,
          };
          return acc;
        }, {}),
      },
    }));
  };

  const handleIndividualCheckboxChange = (rowId, permission, e) => {
    const checked = e.target.checked;

    const updatedDataRows = data__row.map((row) =>
      row.id === rowId ? { ...row, [permission]: checked } : row
    );
    setDataRows(updatedDataRows);

    formik.setValues((prevValues) => ({
      ...prevValues,
      permissions: {
        ...prevValues.permissions,
        [rowId]: {
          ...prevValues.permissions[rowId],
          [permission]: checked,
        },
      },
    }));
  };

  const role__columns = [
    {
      title: "",
      dataIndex: "id",
      key: "id",
    },

    {
      title: (
        <TableRoleHeader>
          <Typography>Read</Typography>
          <Checkbox
            checked={permissionsChecked.Read}
            onChange={(e) => handlePermissionCheckboxChange("Read", e)}
          />
        </TableRoleHeader>
      ),
      dataIndex: "Read",
      key: "Read",
      render: (_, record) => (
        <Checkbox
          checked={record.Read}
          onChange={(e) => handleIndividualCheckboxChange(record.id, "Read", e)}
        />
      ),
      align: "center",
    },

    {
      title: (
        <TableRoleHeader>
          <Typography>Create</Typography>
          <Checkbox
            checked={permissionsChecked.Create}
            onChange={(e) => handlePermissionCheckboxChange("Create", e)}
          />
        </TableRoleHeader>
      ),
      dataIndex: "Create",
      key: "Create",
      render: (_, record) => (
        <Checkbox
          checked={record.Create}
          onChange={(e) =>
            handleIndividualCheckboxChange(record.id, "Create", e)
          }
        />
      ),
      align: "center",
    },
    {
      title: (
        <TableRoleHeader>
          <Typography>Edit</Typography>
          <Checkbox
            checked={permissionsChecked.Edit}
            onChange={(e) => handlePermissionCheckboxChange("Edit", e)}
          />
        </TableRoleHeader>
      ),
      dataIndex: "Edit",
      key: "Edit",
      render: (_, record) => (
        <Checkbox
          checked={record.Edit}
          onChange={(e) => handleIndividualCheckboxChange(record.id, "Edit", e)}
        />
      ),
      align: "center",
    },

    {
      title: (
        <TableRoleHeader>
          <Typography>Delete</Typography>
          <Checkbox
            checked={permissionsChecked.Delete}
            onChange={(e) => handlePermissionCheckboxChange("Delete", e)}
          />
        </TableRoleHeader>
      ),
      dataIndex: "Delete",
      key: "Delete",
      render: (_, record) => (
        <Checkbox
          checked={record.Delete}
          onChange={(e) =>
            handleIndividualCheckboxChange(record.id, "Delete", e)
          }
        />
      ),
      align: "center",
    },

    {
      title: (
        <TableRoleHeader>
          <Typography>Update</Typography>
          <Checkbox
            checked={permissionsChecked.Update}
            onChange={(e) => handlePermissionCheckboxChange("Update", e)}
          />
        </TableRoleHeader>
      ),
      dataIndex: "Update",
      key: "Update",
      render: (_, record) => (
        <Checkbox
          checked={record.Update}
          onChange={(e) =>
            handleIndividualCheckboxChange(record.id, "Update", e)
          }
        />
      ),
      align: "center",
    },

    {
      title: (
        <TableRoleHeader>
          <Typography>Approved</Typography>
          <Checkbox
            checked={permissionsChecked.Approve}
            onChange={(e) => handlePermissionCheckboxChange("Approve", e)}
          />
        </TableRoleHeader>
      ),
      dataIndex: "Approve",
      key: "Approve",
      render: (_, record) => (
        <Checkbox
          checked={record.Approve}
          onChange={(e) =>
            handleIndividualCheckboxChange(record.id, "Approve", e)
          }
        />
      ),
      align: "center",
    },

    {
      title: (
        <TableRoleHeader>
          <Typography>Export</Typography>
          <Checkbox
            checked={permissionsChecked.Export}
            onChange={(e) => handlePermissionCheckboxChange("Export", e)}
          />
        </TableRoleHeader>
      ),
      dataIndex: "Export",
      key: "Export",
      render: (_, record) => (
        <Checkbox
          checked={record.Export}
          onChange={(e) =>
            handleIndividualCheckboxChange(record.id, "Export", e)
          }
        />
      ),
      align: "center",
    },
  ];

  return (
    <Drawer
      title={<ModalTitle title="Add New Role" close={cancel} />}
      open={open}
      closable={false}
      onClose={cancel}
      width={840}
      footer={null}
    >
      <Styled.Form sx="px-[24px]" onSubmit={formik.handleSubmit}>
        {/* merchant name */}
        <Styled.Wrapper sx="flex justify-center items-center gap-4">
          <Styled.Section sx="new__auth__wrapper w-full">
            <Styled.Span>Role Name</Styled.Span>
            <Styled.Input
              sx="w-full"
              type="text"
              placeholder="administrator"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <Styled.Section sx="error">
              {formik.touched.name && formik.errors.name && formik.errors.name}
            </Styled.Section>
          </Styled.Section>

          {/* last name */}
          <Styled.Section sx="new__auth__wrapper w-full">
            <Styled.Span>Role Description</Styled.Span>
            <Styled.Input
              type="text"
              placeholder="Description"
              sx="w-full"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Styled.Section sx="error">
              {formik.touched.description &&
                formik.errors.description &&
                formik.errors.description}
            </Styled.Section>
          </Styled.Section>
        </Styled.Wrapper>

        <Divider />

        <Styled.Wrapper sx="add__role__table__head">
          <Table
            columns={role__columns}
            dataSource={data__row}
            rowSelection={{
              type: "checkbox",
              hideSelectAll: true,
            }}
            hideDefaultSections={false}
            rowKey="id"
            pagination={false}
          />
        </Styled.Wrapper>

        <Styled.Wrapper sx="add__affiliates__button__wrapper pt-4">
          <Button onClick={cancel} size="large" className="w-full">
            Cancel
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            className="w-full"
            loading={isLoading}
          >
            Add Role
          </Button>
        </Styled.Wrapper>
      </Styled.Form>
    </Drawer>
  );
};

export default AddNewRole;
