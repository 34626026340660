import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  userRequest } from '../app/config';

const initialState = {
  dashboardAnalytics: [],
  loading: false,
  error: null,
};

export const fetchDashboardAnalytics = createAsyncThunk('/dashboard/analytics', async (_, { rejectWithValue }) => {
  try {
    const response = await userRequest.get(`/merchant/dashboard`,);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});



const dashboardAnalyticsSlice = createSlice({
  name: 'dashboardAnalytics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardAnalytics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDashboardAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardAnalytics = action.payload;
      })
      .addCase(fetchDashboardAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});


export default dashboardAnalyticsSlice.reducer;
