import React from "react";
import { Styled } from "../../constants/Styled";
import { Checkbox, Input } from "antd";


const CreatePassword = ({ formik }) => {
  const isPasswordMatch = formik.values.password === formik.values.confirm_password;

    const handleCheckboxChange = (event) => {
      formik.setFieldValue("acceptTerms", event.target.checked);
    };

  return (
    <Styled.Wrapper sx="w-full">
      <Styled.Section sx="password__auth">
        <Styled.Span>Create Password</Styled.Span>
        <Input.Password
          className="tingo__input"
          type="password"
          placeholder="Create Password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Styled.Section sx="error">
          {formik.touched.password &&
            formik.errors.password &&
            formik.errors.password}
        </Styled.Section>
      </Styled.Section>

      <Styled.Section sx="password__auth mt-3">
        <Styled.Span>Confirm Password</Styled.Span>
        <Input.Password
          className="tingo__input"
          type="password"
          placeholder="Confirm Password"
          name="confirm_password"
          value={formik.values.confirm_password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <Styled.Section sx="error">
          {formik.touched.confirm_password &&
            formik.errors.confirm_password &&
            isPasswordMatch === false && <> {formik.errors.confirm_password}</>}
        </Styled.Section>
      </Styled.Section>

      <Checkbox
        className="auth__sign__checkbox mt-3"
        checked={formik.values.acceptTerms}
        onChange={handleCheckboxChange}
        onBlur={formik.handleBlur}
        name="acceptTerms"
      >
        I accept the Tingopay Voucher
        <Styled.Span sx="checkbox__desc pl-2">
           Terms and condition
        </Styled.Span> and{" "}
        <Styled.Span sx="checkbox__desc">Privacy Policy</Styled.Span>?
      </Checkbox>

      {formik.touched.acceptTerms && formik.errors.acceptTerms && (
        <Styled.Section sx="error">{formik.errors.acceptTerms}</Styled.Section>
      )}
    </Styled.Wrapper>
  );
};

export default CreatePassword;
