import { Button, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Styled } from "../../../../constants/Styled";
import { role_mgr_user } from "../../../../data/userRole.Data";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllPendingInvites } from "../../../../state/pendingInvite.Slice";
import { toast } from "react-toastify";
import { resendStaffInvites } from "../../../../state/resendInvite.Slice";
import { revokeStaffInvites } from "../../../../state/revokeInvite.Slice";


const RoleMgrUserPendingInvite = () => {
  const dispatch = useDispatch();
  const { pendingInvites, loading } = useSelector((state) => state.pendingInvites)
  const [ isResending, setIsResending ] = useState({});
  const [ isRevoke, setIsRevoke ] = useState(false);


  useEffect(() => {
      dispatch(fetchAllPendingInvites({ page: 1, limit: 10}));
  }, [dispatch])

  const allInvites = pendingInvites?.data;


  // a function to resend invites
  const handleResendInvite = async (inviteId) => {
    setIsResending((prevState) => ({ ...prevState, [inviteId]: true }));
    try {
      const response = await dispatch(resendStaffInvites(inviteId)).unwrap();
      setIsResending((prevState) => ({ ...prevState, [inviteId]: false }));
      toast.success(response?.message);
    } catch (error) {
      setIsResending((prevState) => ({ ...prevState, [inviteId]: false }));
      toast.error(error.message);
    } finally {
      setIsResending((prevState) => ({ ...prevState, [inviteId]: false }));
    }
  };


  // a function to resend invites
  const handleRevokeInvite = async (inviteId) => {
    setIsRevoke((prevState) => ({ ...prevState, [inviteId]: true }));
    try {
      const response = await dispatch(revokeStaffInvites(inviteId)).unwrap();
      setIsRevoke((prevState) => ({ ...prevState, [inviteId]: false }));
      toast.success(response?.message);
    } catch (error) {
      setIsRevoke((prevState) => ({ ...prevState, [inviteId]: false }));
      toast.error(error.message);
    } finally {
      setIsRevoke((prevState) => ({ ...prevState, [inviteId]: false }));
    }
  };

  

  
  const actionColumn = {
    title: "Actions",
    dataIndex: "id",
    key: "id",
    render: (_, record) => (
      <Space size="large">
        <Button type="primary" onClick={() => handleResendInvite(record?.id)} loading={isResending[record?.id]}>
          Resend Invite
        </Button>
        <Button className="revoke__invite__btn" onClick={() => handleRevokeInvite(record?.id)} loading={isRevoke[record?.id]}>Revoke Invite</Button>
      </Space>
    ),
  };


  const user__column = [...role_mgr_user, actionColumn];


  return (
    <>
      <Styled.Wrapper sx="role__mgr__user pt-4">
        <Styled.Section sx="role__mgr__user__left">
          <Styled.Caption title="Pending Invites" />
          <Styled.Text>
            Invited team members will receive and accept invitations via email
            to collaborate, manage their roles and tasks.
          </Styled.Text>
        </Styled.Section>

        <Styled.Section sx="role__mgr__user__right table__box shadow-md">
            <Table loading={loading} columns={user__column} dataSource={allInvites} />
        </Styled.Section>
      </Styled.Wrapper>
    </>
  );
};

export default RoleMgrUserPendingInvite;
