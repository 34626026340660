import { Icons } from "../constants/Icons";
import { formatAmount, formatDateAndTime } from "../utils/Utils";

export const cards_columns = [

  {
    title: "Card Purpose",
    dataIndex: "card_purpose",
    render: card_purpose => `${card_purpose ? card_purpose : "N/A"}`,
    sorter: (a, b) => a.card_purpose - b.card_purpose,
  },

  {
    title: "Holder Name",
    dataIndex: "holder_name",
    render: holder_name => (holder_name ? holder_name : "N/A"),
    sorter: (a, b) => a.holder_name - b.holder_name,
  },

  {
    title: "Card Number",
    dataIndex: "card_number",
    sorter: (a, b) => a.card_number - b.card_number,
  },


  {
    title: "Contact Number",
    dataIndex: "contact_number",
    render: contact_number => `${contact_number ? contact_number : "N/A"}`,
    sorter: (a, b) => a.contact_number - b.contact_number,
  },

  {
    title: "Balance",
    dataIndex: "wallet",
    render: (wallet) => formatAmount(wallet?.balance ? wallet?.balance : "0"),
    sorter: (a, b) => a.wallet?.balance - b.wallet?.balance,
  },


  {
    title: "Status",
    dataIndex: "status",
    render: (status) => (
      <span
        className={`status-before-content sb ${
          status === 1 ? "active" : "in-active"
        }`}
      >
        {status === 1 ? "active" : "in-active"}
      </span>
    ),
    sorter: (a, b) => a.active - b.active,
    width: "120px",
    align: "center"
  },

];



export const cards_trnx_columns = [
  {
    title: "Transaction ID",
    dataIndex: "transaction_id",
    render: transaction_id => `${transaction_id ? transaction_id : "N/A"}`,
    sorter: (a, b) => a._id - b._id,
  },

  {
    title: "Amount",
    dataIndex: "transaction_amount",
    render: formatAmount,
    sorter: (a, b) => a.transaction_amount - b.transaction_amount,
  },

  {
    title: "Date",
    dataIndex: "transaction_created_at",
    render: formatDateAndTime,
    sorter: (a, b) => a.transaction_created_at - b.transaction_created_at,
  },

  {
    title: "Status",
    dataIndex: "transaction_status",
    key: "transaction_status",
    render: (transaction_status) => (
      <span
        className={`status-before-content sb ${transaction_status}`}
      >
        {transaction_status.toLowerCase()}
      </span>
    ),
    sorter: (a, b) => a.transaction_status - b.transaction_status,
    with: "110px",
    align: "center",
  },
];




// showCurrency: "₦ ",
export const card_dashboard = [
  {
      title: "TOTAL NUMBER OF CARDS",
      icon: <Icons.DashboardCardIcon />,
      value: 0,
  },

  {
      title: "PENDING CARDS REQUEST",
      icon: <Icons.DashboardPendingCardIcon />,
      value: 0,
  },

  {
      title: "FLAGGED CARDS",
      icon: <Icons.DashboardFlagCardIcon />,
      value: 0,
      view: "View"
  },
]