import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { publicRequest } from "../app/config";

const initialState = {
  industryType: [],
  loading: false,
  error: null,
};

export const fetchIndustryType = createAsyncThunk(
  "customers/all/industry/type",
  async (_, { rejectWithValue }) => {
    try {
      const response = await publicRequest.get(`/merchant/industry`,);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const industryTypeSlice = createSlice({
  name: "industryType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // customers cards
      .addCase(fetchIndustryType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIndustryType.fulfilled, (state, action) => {
        state.loading = false;
        state.industryType = action.payload;
      })
      .addCase(fetchIndustryType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default industryTypeSlice.reducer;
