import React, { useState } from "react";
import { Styled } from "../../../constants/Styled";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { schema } from "../../../schema/Schema";
import { physicalOTP } from "../../../state/physicalOTP.Slice";
import { toast } from "react-toastify";
import { fetchCheckoutHistory } from "../../../state/checkHistory.Slice";
import { showCustomSuccessModal } from "../../../utils/Utils";
import { OTPInputs } from "../../../common";

const ConfirmPaymentCheckout = ({ close }) => {
  const [isClose, setIsClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const { physicalPayment } = useSelector((state) => state.physicalPayment);
  const dispatch = useDispatch();

  const checkoutId = physicalPayment?.data?.id || "";

  const initialValues = {
    otp: "",
    checkout_id: checkoutId,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema.otpSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await dispatch(physicalOTP(values)).unwrap();
        setLoading(false);
        showCustomSuccessModal(response?.message, 5000, "Success");
        setIsClose(close);
        dispatch(fetchCheckoutHistory({ page: 1, limit: 10 }));
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
  });


  return (
    <>
      <Styled.Form onSubmit={formik.handleSubmit}>
        <Styled.Section sx="mt-9">
          {/* transaction details */}
          <Styled.Wrapper sx="trnx__details__otp text-center">
            <Styled.Span>
              A one-time password (OTP) has been sent to the device linked to
              customers voucher card. Enter the four-digit password to complete
              payment
            </Styled.Span>
          </Styled.Wrapper>

          {isClose ? "Close" : ""}
          <Styled.Span sx="trnx__tag">Enter Transaction Pin</Styled.Span>
          <Styled.Section sx="trnx__pin__input">
            <OTPInputs
              length={4}
              value={formik.values.otp}
              onChange={(otpValue) => formik.setFieldValue("otp", otpValue)}
              onBlur={formik.handleBlur}
            />
            <Styled.Section sx="error">
              {formik.touched.otp && formik.errors.otp && formik.errors.otp}
            </Styled.Section>
          </Styled.Section>
        </Styled.Section>

        <Styled.Section sx="trnx__pay__btn">
          <Button
            onClick={close}
            size="large"
            className="w-[130px] text-[20px] font-semibold"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={loading}
            className="w-[130px] text-[20px] font-semibold"
          >
            Confirm
          </Button>
        </Styled.Section>
      </Styled.Form>
    </>
  );
};

export default ConfirmPaymentCheckout;
