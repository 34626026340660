import React, { useState } from "react";
import { Tabs } from "antd";
import CardRequest from "./cardRequest";
import BulkCard from "./bulkCard";


const GenerateVoucherCards = ({ close }) => {

  const [ switchTab, setSwitchTab ] = useState("1")

  const handleTabChange = (key) => {
    setSwitchTab(key);
  }

const options = [
    {
      key: 1,
      label: "Single Cards",
      children: <CardRequest close={close} />
    },

    {
      key: 2,
      label: "Bulks Cards",
      children: <BulkCard close={close} />,
    },
  ]

  return (
    <>

      <Tabs
          defaultActiveKey={switchTab}
          items={options}
          onChange={handleTabChange}
          centered={true}
        />
    </>
  );
};

export default GenerateVoucherCards;
