import { Button, Divider } from "antd";
import React, { useState } from "react";
import { Styled } from "../../../constants/Styled";
import { useFormik } from "formik";
import { schema } from "../../../schema/Schema";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { flagCardsCustomer } from "../../../state/flagCard.Slice";
import { showCustomSuccessModal } from "../../../utils/Utils";
import { fetchCards } from "../../../state/cardsSlice";

const FlagFormCard = ({ close, cardNumber }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [closeModal, setCloseModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: schema.flagCardSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await dispatch(
          flagCardsCustomer({ cardId: cardNumber, reason: values.reason })
        ).unwrap();
        setLoading(false);
        setCloseModal(close);
        dispatch(fetchCards({ page: 1, limit: 10 }));
        showCustomSuccessModal(response?.message, 5000, "Success");
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <Styled.Form sx="px-[8px] pb-[8px] pt-2" onSubmit={formik.handleSubmit}>
        {closeModal ? "" : ""}
        <Divider className="mt-[10px] mb-[32px]" />
        <Styled.Section sx="new__auth__wrapper flag__msg">
          <Styled.Span>Reason</Styled.Span>
          <Styled.Message
            type="text"
            placeholder="Description"
            name="reason"
            value={formik.values.reason}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
           <Styled.Section sx="error">
              {formik.touched.reason &&
                formik.errors.reason &&
                formik.errors.reason}
            </Styled.Section>
        </Styled.Section>

        <Styled.Wrapper sx="flag__msg__btn">
          <Button type="" onClick={close}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Styled.Wrapper>
      </Styled.Form>
    </>
  );
};

export default FlagFormCard;
