
export const role_mgr_user = [
    {
        title: "Name",
        dataIndex: "first_name",
        render: (text, record) => `${record.first_name} ${record.last_name}`,
        sorter: (a, b) => a.first_name - b.last_name,
    },

    {
        title: "Date Sent",
        dataIndex: "created_at",
        render: (created_at) => {
            const dateObj = new Date(created_at);
            const timeString = dateObj.toLocaleTimeString('en-US', { hour12: false });
            const dateString = dateObj.toLocaleDateString('en-US', { day: '2-digit', month: 'short' });
            return `${timeString} ${dateString}`;
          },
        sorter: (a, b) => a.created_at - b.created_at,
    },


    {
        title: "Assigned Role",
        dataIndex: "role_name",
        render: role_name => `${role_name ? role_name : "N/A"}`,
        sorter: (a, b) => a.role_name - b.role_name
    },
]


export const role_mgr_accepted__staff = [
    {
        title: "Name",
        dataIndex: "first_name",
        render: (text, record) => `${record.first_name} ${record.last_name}`,
        sorter: (a, b) => a.first_name - b.last_name,
    },

    {
        title: "Date Sent",
        dataIndex: "created_at",
        render: (created_at) => {
            const dateObj = new Date(created_at);
            const timeString = dateObj.toLocaleTimeString('en-US', { hour12: false });
            const dateString = dateObj.toLocaleDateString('en-US', { day: '2-digit', month: 'short' });
            return `${timeString} ${dateString}`;
          },
        sorter: (a, b) => a.created_at - b.created_at,
    },


    {
        title: "Assigned Role",
        dataIndex: "role_name",
        render: role_name => `${role_name ? role_name : "N/A"}`,
        sorter: (a, b) => a.role_name - b.role_name
    },
]
