import { Icons } from "../constants/Icons"
import { formatAmount, formatDateAndTime } from "../utils/Utils"


export const wallet_card = [

    {
        title: "LIFETIME PAYOUT",
        showCurrency: "₦ ",
        value: 0,
    },
]



export const wallet_col = [
    {
        title: "Transaction ID",
        dataIndex: "transaction_id",
        sorter: (a, b) => a.transaction_id - b.transaction_id,
    },

    {
        title: "Time/Date",
        dataIndex: "transaction_created_at",
        render: formatDateAndTime,
        sorter: (a, b) => a.transaction_created_at- b.transaction_created_at
    },

    {
        title: "Type",
        dataIndex: "transaction_transaction_type",
        render: transaction_transaction_type => (<div className={`status-before-content ${transaction_transaction_type.toLowerCase()} `}>{transaction_transaction_type.toLowerCase()}</div>),
        sorter: (a, b) => a.transaction_transaction_type - b.transaction_transaction_type
    },

    {
        title: "Description",
        dataIndex: "transaction_mode",
        sorter: (a, b) => a.transaction_mode - b.transaction_mode
    },

    {
        title: "Amount",
        dataIndex: "transaction_amount",
        render: formatAmount,
        sorter: (a, b) => a.transaction_amount - b.transaction_amount
    },

    {
        title: "Status",
        dataIndex: "transaction_status",
        render: (transaction_status) => (
          <span
            className={`status-before-content shape-circle sb ${
              transaction_status.toLowerCase()
            }`}
          >
            {transaction_status.toLowerCase()}
          </span>
        ),
        sorter: (a, b) => a.transaction_status - b.transaction_status,
        width:"110px",
        align: "center",
      },

      {
        title: "Actions",
        dataIndex: "actions",
        render: actions => (<Icons.ActionDot size={20} color="#72747D" width="15.12px" height="15.12px"/>),
        align: "center",
        
      }

]
