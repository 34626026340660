import React, { useState } from "react";
import { Styled } from "../../../constants/Styled";
import { Icons } from "../../../constants/Icons";
import { Button, Input, Radio, Upload } from "antd";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import {  bulkCardsStep1 } from "../../../state/bulkCard.Slice";
import { schema } from "../../../schema/Schema";
import { toast } from "react-toastify";
import { ModalTitle } from "../../../common";
import BulkCardDetails from "./bulkCard.Details";


const BulkCard = ({ close }) => {
  const [ loading, setLoading ] = useState(false);
  const [ cardUsage, setCardUsage ] = useState("one__time");
  const [ isCardDetails, setIsCardDetails ] = useState(false);
  const { Dragger } = Upload;
  const dispatch = useDispatch();
  const handleCardUsageChange = (e) => {
    setCardUsage(e.target.value);
  };

  const formik = useFormik({
    initialValues: {
      file: null,
      validity: "",
    },
    validationSchema: schema.bulkGenerate,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("file", values.file);
        formData.append("validity", values.validity);
        const response = await dispatch(bulkCardsStep1(formData)).unwrap();
        toast.info(response.message)
        setIsCardDetails(true);
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="px-[8px] pb-[8px] pt-2">
        <Styled.Text sx="card__dwn">
          Download details cards template
        </Styled.Text>
        <a  rel="noreferrer" href="https://tingodevbucket.s3.amazonaws.com/card_details_template.xlsx" download><Icons.DownloadCardTemplate sx="cursor-pointer" /></a>
        <Styled.Section sx="card__dwn__desc">
          <Icons.InfoIcon />
          <Styled.Text>
            {" "}
            Single file upload should not be more than 10MB, only the{" "}
            <span>.xls, .xlsx</span> <br />
            file types are allowed
          </Styled.Text>
        </Styled.Section>
        <Dragger
          accept=".xls, .xlsx"
          beforeUpload={(file) => {
            formik.setFieldValue("file", file);
            return false;
          }}
          onChange={(info) => {
            if (info.file.status === "uploading") {
              setLoading(true);
            } else if (
              info.file.status === "done" ||
              info.file.status === "error"
            ) {
              setLoading(false);
            }
          }}
        >
          {formik.values.file ? (
            <Styled.Wrapper sx="selected__file__xls">
              <Icons.SelectedFileIcon />
              <Styled.Text sx="selected__file__name">
                {formik.values.file.name}
              </Styled.Text>
              <Styled.Text sx="upload__title__doc__2">Change File</Styled.Text>
            </Styled.Wrapper>
          ) : (
            <>
              <Styled.Text sx="">
                <Icons.UploadXLSIcon />
              </Styled.Text>
              <Styled.Text sx="upload__title__doc">
                Select file to upload
              </Styled.Text>
            </>
          )}
        </Dragger>

        <Styled.Section sx="error pt-2">
          {formik.touched.file && formik.errors.file && formik.errors.file}
        </Styled.Section>

        {/* check box */}
        <Styled.Wrapper sx="card__usage">
          <Radio
            value="one__time"
            onChange={handleCardUsageChange}
            checked={cardUsage === "one__time"}
          >
            One-time usage
          </Radio>
          <Radio
            value="multiple"
            onChange={handleCardUsageChange}
            checked={cardUsage === "multiple"}
          >
            Multi-Use
          </Radio>
        </Styled.Wrapper>

        {cardUsage === "one__time" && (
          <>
            <Styled.Wrapper sx="card__usage__period">
              <Styled.Text>Validity Period</Styled.Text>

              <Styled.ExportButtonWrapper>
                <Input
                  className="trnx__search__input"
                  bordered={false}
                  type="date"
                  name="validity"
                  value={formik.values.validity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Styled.ExportButtonWrapper>
            </Styled.Wrapper>
            <Styled.Section sx="error">
              {formik.touched.validity &&
                formik.errors.validity &&
                formik.errors.validity}
            </Styled.Section>
          </>
        )}

        {cardUsage === "multiple" && (
          <Styled.Wrapper sx="card__usage__period">
            <Styled.Text>Validity Period</Styled.Text>

            <Styled.ExportButtonWrapper>
              <Input
                className="trnx__search__input"
                bordered={false}
                type="date"
                name="validity"
                value={formik.values.validity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Styled.ExportButtonWrapper>
          </Styled.Wrapper>
        )}

        <Button
          type="primary"
          size="large"
          className="card__single__req__btn"
          htmlType="submit"
          loading={loading}
        >
          Next
        </Button>
      </form>

      {isCardDetails && (
        <Styled.TgModal
          title={
            <ModalTitle
              title="Bulks Cards"
              close={() => setIsCardDetails(false)}
            />
          }
          open={isCardDetails}
          width="512px"
          bodyStyle={{ height: "600px" }}
          footer={null}
          closable={false}
        >
          <BulkCardDetails close={close} />
        </Styled.TgModal>
      )}
    </>
  );
};

export default BulkCard;
