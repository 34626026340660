import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from '../app/config';

const initialState = {
  checkoutHistory: [],
  loading: false,
  error: null,
};

export const fetchCheckoutHistory = createAsyncThunk('merchant/checkout/history',
  async ({page, limit}, { rejectWithValue }) => {
    try {
      const response = await userRequest.get(`/merchant/checkout/history?page=${page}&limit=${limit}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const checkoutHistorySlice = createSlice({
  name: 'checkoutHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCheckoutHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCheckoutHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.checkoutHistory = action.payload;
      })
      .addCase(fetchCheckoutHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })   
  },
});

export default checkoutHistorySlice.reducer;
