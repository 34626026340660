import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from '../app/config';

const initialState = {
  merchantInfo: [],
  loading: false,
  error: null,
};

export const fetchMerchantInfo = createAsyncThunk('merchant/merchant/information',
  async (_, { rejectWithValue }) => {
    try {
      const response = await userRequest.get(`/merchant/details`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const merchantInfoSlice = createSlice({
  name: 'merchantInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchantInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMerchantInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.merchantInfo = action.payload;
      })
      .addCase(fetchMerchantInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })   
  },
});

export default merchantInfoSlice.reducer;
