import { Button, Space, Table } from "antd";
import React, {  useEffect, useState } from "react";
import { Styled } from "../../../../constants/Styled";
import { Icons } from "../../../../constants/Icons";
import AddNewRole from "./AddNewRole";
import { role_mgr_role_columns } from "../../../../data/roles.RoleData";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteCreatedRole } from "../../../../state/deleteRole.Slice"
import { fetchAllRoles } from "../../../../state/roles.Slice";


const RoleMgrRoleActiveRoles = () => {
  const dispatch = useDispatch();
  const [ addRole, setAddRole ] = useState(false);
  const { roles, loading } = useSelector(state => state.roles);
  const [ isLoading, setIsLoading ] = useState(false);

  const allRoles = roles?.data;

  useEffect(() => {
    dispatch(fetchAllRoles());
  }, [dispatch])

  const handleDeleteRole = async (roleId) => {
    setIsLoading(true);
    try {
      const response = await dispatch(deleteCreatedRole(roleId)).unwrap();
      setIsLoading(false);
      toast.success(response?.message);
      dispatch(fetchAllRoles())
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };


  const actionColumn = {
    title: "Actions",
    dataIndex: "id",
    key: "actions",
    render: (_, record) => (
      <Space size="large">
        <Icons.EditIcon onClick={record.id} />
        <Icons.DeleteIcon onClick={() => handleDeleteRole(record?.id)}  loading={isLoading} />
      </Space>
    ),
  };

  const user__column = [...role_mgr_role_columns, actionColumn];
  return (
    <>
      <Styled.Wrapper sx="role__mgr__user pt-4">
        <Styled.Section sx="role__mgr__user__left">
          <Styled.Caption title="Active Roles" />
          <Styled.Text>
          Create and assign roles to your team members. Collaborate and manage their roles and permissions.
          </Styled.Text>

          <Button
            type="primary"
            size="large"
            onClick={() => setAddRole(true)}
            className="add__role"

          >
            Add New Role
            <Icons.AddMerchant />
          </Button>
        </Styled.Section>

        <Styled.Section sx="role__mgr__user__right table__box shadow-md">
          <Table
          columns={user__column} 
          dataSource={allRoles} 
          loading={loading} 
          />
        </Styled.Section>
      </Styled.Wrapper>

      {/* invite member form */}
     {addRole && (
         <AddNewRole open={addRole} cancel={() => setAddRole(false)}/>
     )}
    </>
  );
};

export default RoleMgrRoleActiveRoles;
