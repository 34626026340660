import {  Table } from "antd";
import React, { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { cus_cards_columns } from "../../../data/customerCardsData";
import { fetchCustomerCards } from "../../../state/customerCardsSlice";
import { Styled } from "../../../constants/Styled";


const CustomerCards = ({ customerId }) => {
  const dispatch = useDispatch();
  const { customercards, loading } = useSelector(
    (state) => state.customercards
  );
 

  useEffect(() => {
    dispatch(fetchCustomerCards({ cardId: customerId?.phone_number}));
  }, [dispatch, customerId]);

  const CustomerCards = customercards.data?.cards;

  return (
    <>

        <Styled.Wrapper sx="table__box">
          <Table
            dataSource={CustomerCards}
            columns={cus_cards_columns}
            rowKey="id"
            rowSelection="checkbox"
            loading={loading}
          />
        </Styled.Wrapper>
    </>
  );
};

export default CustomerCards;
