import React from 'react'
import { Styled } from '../../../../constants/Styled'
import RoleMgrRoleActiveRoles from './RoleMgr.Role.ActiveRoles'

const RoleMgrRole = () => {
  return (
   <>
        <Styled.Wrapper>
            <RoleMgrRoleActiveRoles />
        </Styled.Wrapper>
   </>
)}

export default RoleMgrRole