import { SettingAuditLog, SettingProfile, SettingRoleMgr, SettingSecurity } from "../components";

export const options = [
  {
    key: 1,
    label: "Profile",
    children: <SettingProfile />
  },

  {
    key: 2,
    label: "Security",
    children: <SettingSecurity />
  },

  {
    key: 3,
    label: "Role Management",
    children: <SettingRoleMgr />
  },

  {
    key: 4,
    label: "Audit Log",
    children: <SettingAuditLog />
  },

];
