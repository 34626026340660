import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { publicRequest } from '../app/config';


const initialState = {
  resendPasswordToken: [],
  loading: false,
  error: null,
};

export const userForgotPasswordResendToken = createAsyncThunk('merchant/auth/resend/password/token',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await publicRequest.post(`/merchant/forget-password/req/resend`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



const resendEmailTokenSlice = createSlice({
  name: 'resendPasswordToken',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userForgotPasswordResendToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userForgotPasswordResendToken.fulfilled, (state, action) => {
        state.loading = false;
        state.resendPasswordToken = action.payload;
      })
      .addCase(userForgotPasswordResendToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }) 
  },
});

export default resendEmailTokenSlice.reducer;
