export const cus_cards_columns = [
  {
    title: "Card NO",
    dataIndex: "card_number",
  },
  {
    title: "Card Name",
    dataIndex: "card_purpose",
    sorter: (a, b) => a.last_name - b.last_name,
  },

  {
    title: "Card Type",
    dataIndex: "one_time_use",
    render: (one_time_use) => (one_time_use ? "One Time Use" : "Multiple Use"),
    sorter: (a, b) => a.one_time_use - b.one_time_use,
  },

  {
    title: "Status",
    dataIndex: "status",
    render: (status) => (
      <span
        className={`status-before-content ${
          status === 1 ? "active" : "in-active"
        }`}
      >
        {status === 1 ? "Active" : "Inactive"}
      </span>
    ),
    sorter: (a, b) => a.status - b.status,
  },
];
