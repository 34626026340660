import React, { useEffect, useState } from "react";
import { Styled } from "../../constants/Styled";
import { useDispatch, useSelector } from "react-redux";
import { schema } from "../../schema/Schema";
import { loginUser } from "../../state/authSlice";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Images } from "../../constants/Images";
import { Button, Input } from "antd";
import { toast } from "react-toastify";
import { AuthLayout } from "../../common";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { user, error } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: schema.loginSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await dispatch(loginUser(values)).unwrap();
        toast.success(response?.message);
        setLoading(false);
        navigate("/dashboard/overview");
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (user) {
      navigate("/dashboard/overview");
    }
  }, [user, navigate]);

  if (error) {
    <Styled.Span>{error?.message}</Styled.Span>;
  }

  return (
    <>
      <AuthLayout>
        <center>
          <Styled.Img img={Images.Logo} alt="" />
        </center>
        <Styled.Text sx="reset__password__title">Login</Styled.Text>
        <Styled.Form sx="new__login__input" onSubmit={formik.handleSubmit}>
          <Styled.Section sx="new__auth__wrapper pt-10">
            <Styled.Span>Email Address</Styled.Span>
            <Styled.Input
              type="email"
              placeholder="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Styled.Section sx="error">
              {formik.touched.email &&
                formik.errors.email &&
                formik.errors.email}
            </Styled.Section>
          </Styled.Section>

          <Styled.Section sx="password__auth pt-5">
            <Styled.Span>Password</Styled.Span>
            <Input.Password
              type="password"
              placeholder="****"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="tingo__input"
            />
            <Styled.Section sx="error">
              {formik.touched.password &&
                formik.errors.password &&
                formik.errors.password}
            </Styled.Section>
          </Styled.Section>
          
          <Styled.Span sx="new__auth__forgot">
            <Styled.Linked as="/forgot-password">
              Forgot password?
            </Styled.Linked>
          </Styled.Span>

          <Button
            loading={loading}
            htmlType="submit"
            type="primary"
            size="large"
            className="w-full mt-14 text-[20px] font-bold "
          >
            Login
          </Button>

          <Styled.Text sx="auth__sign__link mt-2">
            Don’t have an account?
            <Styled.Linked as="/merchant-signup">Sign Up</Styled.Linked>
          </Styled.Text>
        </Styled.Form>
      </AuthLayout>
    </>
  );
};

export default LoginForm;
