import React, { useEffect, useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import { logoutUser } from "../state/authSlice";

export const ProtectedRoute = ({ user, redirect = "/" }) => {
  const dispatch = useDispatch();
  const userAccessToken = user?.accessToken;

  const currentDate = useMemo(() => new Date(), []);

  useEffect(() => {
    if (userAccessToken) {
      const decod_access = jwtDecode(userAccessToken);
      if (decod_access.exp * 1000 < currentDate.getTime()) {
        dispatch(logoutUser());
      }
    }
  }, [userAccessToken, dispatch, currentDate]);

  if (!user) {
    return <Navigate to={redirect} replace />;
  }

  return <Outlet />;
};

