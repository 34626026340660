import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from '../app/config';

const initialState = {
  singleCardTrnx: [],
  loading: false,
  error: null,
};

export const fetchSingleCardTransaction = createAsyncThunk('cards/single/transaction', async ({ cardId, page, limit }, { rejectWithValue }) => {
  try {
    const response = await userRequest.get(`/cards/transactions/${cardId}?page=${page}&limit=${limit}`,);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});



const singleCardTrnxSlice = createSlice({
  name: 'singleCardTrnx',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleCardTransaction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSingleCardTransaction.fulfilled, (state, action) => {
        state.loading = false;
        state.singleCardTrnx = action.payload;
      })
      .addCase(fetchSingleCardTransaction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});


export default singleCardTrnxSlice.reducer;
