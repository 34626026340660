import React from 'react'

const TableRoleHeader = (props) => {
  return (
    <div className="block justify-center items-center text-center">
        {props.children}
    </div>
  )
}

export default TableRoleHeader 