import React from "react";
import { Styled } from "../../../constants/Styled";
import { Button, Divider } from "antd";

const DisputeTransaction = ({ close }) => {
  return (
    <>
      <form className="px-[8px] pb-[28px] pt-2">
        <Divider className="mt-[10px] mb-[32px]" />

        <Styled.Section sx="new__auth__wrapper">
          <Styled.Span>Subject</Styled.Span>
          <Styled.Input type="text" placeholder="Subject" name="" />
        </Styled.Section>

        <Styled.Section sx="new__auth__wrapper pt-3 flag__msg">
          <Styled.Span>Description</Styled.Span>
          <Styled.Message type="text" placeholder="Description" name="" />
        </Styled.Section>

        <Styled.Wrapper sx="flag__msg__btn">
          <Button type="" onClick={close}>
            Cancel
          </Button>
          <Button type="primary">Submit</Button>
        </Styled.Wrapper>
      </form>
    </>
  );
};

export default DisputeTransaction;
