// CUSTOMIZATION OF STYLED COMPONENTS

import { Link } from "react-router-dom";
import { Modal } from "antd";
import { Styled } from "../../constants/Styled";
import { Icons } from "../../constants/Icons";

export const Container = (props) => {
  return <div className={`app__container ${props.sx}`}>{props.children}</div>;
};

export const Wrapper = (props) => {
  return (
    <div
      className={props.sx}
      onClick={props.onClick}
      key={props.key}
      ref={props.ref}
    >
      {props.children}
    </div>
  );
};

export const Content = (props) => {
  return (
    <div
      className={`main__layout ${props.sx}`}
      onClick={props.onClick}
      key={props.key}
    >
      {props.children}
    </div>
  );
};

export const Aside = (props) => {
  return (
    <aside className={props.sx} onClick={props.onClick} key={props.key}>
      {props.children}
    </aside>
  );
};

export const PublicLayout = (props) => {
  return <div className={`auth__layout ${props.sx}`}>{props.children}</div>;
};

export const Layout = (props) => {
  return (
    <div className={`flex ${props.sx ?? undefined}`}>{props.children}</div>
  );
};

export const Section = (props) => {
  return (
    <section
      className={props.sx}
      onClick={props.onClick}
      onChange={props.onChange}
      style={props.style}
      disabled={props.disabled}
    >
      {props.children}
    </section>
  );
};

export const Select = (props) => {
  return (
    <select
      className={`${props.sx || "auth__input"}`}
      name={props.name}
      id={props.id}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
    >
      {props.children}
    </select>
  );
};

export const Card = (props) => {
  return (
    <div className={`${props.sx}`} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export const Img = (props) => {
  return (
    <img
      src={props.img}
      alt={props.alt}
      className={props.sx}
      title={props.title}
      width={props.width}
      height={props.height}
    />
  );
};

export const Text = (props) => {
  return (
    <p
      className={props.sx ?? undefined}
      onClick={props.onClick}
      key={props.key}
    >
      {props.children}
    </p>
  );
};

export const Message = (props) => {
  return <textarea {...props} className={props.sx} />;
};

export const Header = (props) => {
  return (
    <p className={props.sx} onClick={props.onClick}>
      {props.children}
    </p>
  );
};

export const Span = (props) => {
  return (
    <span className={props.sx} onClick={props.onClick} style={props.style}>
      {props.children}
    </span>
  );
};

export const Input = (props) => {
  return (
    <input
      onKeyUp={props.onKeyUp}
      type={props.type}
      onClick={props.onClick}
      onChange={props.onChange}
      name={props.name ?? undefined}
      id={props.id ?? undefined}
      prefix={props.prefix}
      onBlur={props.onBlur}
      ref={props.ref}
      onKeyDown={props.onKeyDown}
      tabIndex={props.tabIndex}
      value={props.value}
      onFocus={props.focus}
      focusable={props.focusable}
      placeholder={props.placeholder}
      disabled={props.disabled ?? undefined}
      minLength={props.minLength ?? undefined}
      maxLength={props.maxLength ?? undefined}
      className={`${props.sx || "auth__layout__input"}`}
      style={props.style}
      open={props.open}
    />
  );
};

export const Linked = (props) => {
  return (
    <Link to={props.as} className={props.sx} onClick={props.onClick}>
      {props.children}
    </Link>
  );
};

export const List = (props) => {
  return <ul className={props.sx}>{props.children}</ul>;
};

export const ListItem = (props) => {
  return <li className={props.sx}>{props.children}</li>;
};

export const Form = (props) => {
  return (
    <form
      className={props.sx}
      onChange={props.onChange}
      onSubmit={props.onSubmit}
    >
      {props.children}
    </form>
  );
};

export const Block = (props) => {
  return (
    <Styled.Span sx="atn__block">
      <Icons.BlockIcon />
      {props.text}
    </Styled.Span>
  );
};

export const Activate = (props) => {
  return (
    <Styled.Span sx="atn__activate">
      <Icons.ActivateIcon color={props.color} />
      {props.text}
    </Styled.Span>
  );
};

export const ViewDetail = (props) => {
  return (
    <Styled.Span sx="atn__view">
      <Icons.ViewIcon />
      {props.text}
    </Styled.Span>
  );
};

export const Flagged = (props) => {
  return (
    <Styled.Span sx="flag__status">
      <Icons.FlagIcon />
      {props.text}
    </Styled.Span>
  );
};

export const Unflagged = (props) => {
  return <Styled.Span sx="unflag__status">{props.text}</Styled.Span>;
};

export const Caption = (props) => {
  return <Styled.Text sx="header__caption">{props.title}</Styled.Text>;
};

export const TgModal = (props) => {
  return (
    <Modal
      className={props.sx}
      title={props.title}
      centered
      open={props.open}
      closeIcon={props.closeIcon}
      icon={props.icon}
      onOk={props.onOk}
      onCancel={props.onCancel}
      footer={props.footer}
      width={props.width}
      bodyStyle={props.bodyStyle}
      closable={props.closable}
    >
      {props.children}
    </Modal>
  );
};

export const InnerWrapper = (props) => {
  return (
    <div className={`inner__page__content__wrapper  ${props.sx}`}>
      {props.children}
    </div>
  );
};

export const ExportButtonWrapper = (props) => {
  return <div className="print__excel__pdf">{props.children}</div>;
};

export const RightButtonWrapper = (props) => {
  return (
    <div className="block sm:block md:block lg:flex xl:flex 2xl:flex justify-center items-center text-center gap-0 sm:gap-0 md:gap-0 lg:gap-[24px] xl:gap-[24px] 2xl:gap-[24px]">
      {props.children}
    </div>
  );
};
