import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userRequest } from "../app/config";

const initialState = {
  customertnx: [],
  loading: false,
  error: null,
};

export const fetchCustomerTnx = createAsyncThunk(
  "customers/fetchCustomersTnx",
  async ({cardId}, { rejectWithValue }) => {
    try {
      const response = await userRequest.get(`/customers/transactions/${cardId}`, null);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const customerTnxSlice = createSlice({
  name: "customertnx",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // customers cards
      .addCase(fetchCustomerTnx.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerTnx.fulfilled, (state, action) => {
        state.loading = false;
        state.customertnx = action.payload;
      })
      .addCase(fetchCustomerTnx.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default customerTnxSlice.reducer;
