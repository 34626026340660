import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from '../app/config';

const initialState = {
  bulkcards3: [],
  loading: false,
  error: null,
};

export const bulkCardsStep3 = createAsyncThunk('merchant/card/generate/bulk/step3',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userRequest.post(`/cards/bulk/step/3`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const bulkcards3Slice = createSlice({
  name: 'bulkcards3',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(bulkCardsStep3.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(bulkCardsStep3.fulfilled, (state, action) => {
        state.loading = false;
        state.bulkcards3 = action.payload;
      })
      .addCase(bulkCardsStep3.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })   
  },
});

export default bulkcards3Slice.reducer;
