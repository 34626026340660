import { Input, Table, Select } from "antd";
import React, { useEffect, useState } from "react";
import { DashboardLayout, WalletCard } from "../../components";
import { Icons } from "../../constants/Icons";
import { Styled } from "../../constants/Styled";
import { options } from "../../data/Data";
import { wallet_col } from "../../data/walletData";
import { useDispatch, useSelector } from "react-redux";
import { fetchWalletTransactions } from "../../state/walletTransactions.Slice";

const Wallet = () => {
  const dispatch = useDispatch();
  const { walletTransactions, loading } = useSelector((state) => state.walletTransactions);
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);


  useEffect(() => {
    dispatch(fetchWalletTransactions({page: currentPage, limit: pageSize }));
  }, [dispatch, currentPage, pageSize])

  const trnx = walletTransactions?.data?.transactions;

  const handlePageChange = (page, newPageSize) => {
    setCurrentPage(page);
    setPageSize(newPageSize);
  };

  const  pageTotal = walletTransactions?.data?.totalPages;


  return (
    <div>
      <DashboardLayout>
        <WalletCard />

        <Styled.InnerWrapper>
          <Styled.Wrapper sx="pt-5">
            <Input
              className="trnx__search__input"
              type="search"
              size="large"
              prefix={<Icons.IconSearch />}
              placeholder="Search Transactions"
            />

            <Select
              className="trnx__search__select"
              defaultValue="Filter By"
              size="large"
              options={options}
              bordered={false}
            />
          </Styled.Wrapper>

          <Styled.Wrapper sx="table__box">
            <Table
              columns={wallet_col}
              dataSource={trnx}
              loading={loading}
              responsive
              rowSelection="checkbox"
              rowKey="id"
              scroll={{ x: true}}
              pagination={{
                showSizeChanger: false,
                total: pageTotal,
                current: currentPage,
                pageSize: pageSize,
                // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} pages`,
                onChange: handlePageChange,
                pageSizeOptions: ["10", "20", "30", "40", "50", "60", "70", "80", "90", "100",
              ],
              }}
              className="centered-pagination"
            />
          </Styled.Wrapper>
        </Styled.InnerWrapper>
      </DashboardLayout>
    </div>
  );
};

export default Wallet;
