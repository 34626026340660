import { Input, Select,  Table } from "antd";
import React from "react";
import { Icons } from "../../../constants/Icons";
import { options } from "../../../data/Data";
import { Styled } from "../../../constants/Styled";
import { CustomDateRangePicker } from "../../../common";
import { dispute_trnx_col } from "../../../data/disputeTrans.Data";

const TrnxDisputes = () => {
  return (
    <div>
      <Styled.Section sx="block sm:block md:block lg:flex xl:flex 2xl:flex justify-between items-center">
        <Styled.Wrapper sx="gap-4">
          <Input
            className="trnx__search__input"
            type="search"
            size="large"
            prefix={<Icons.IconSearch />}
            placeholder="Search by ID"
            style={{
              width: "175px",
              marginRight: "8px",
            }}
          />
          <Select
            className="trnx__search__select"
            defaultValue="Filter By"
            size="large"
            options={options}
            bordered={false}
          />
        </Styled.Wrapper>
        <Styled.RightButtonWrapper>
        <CustomDateRangePicker />
        <Styled.ExportButtonWrapper>
          <Icons.ReadPDF />
          <Icons.ReadExcel />
        </Styled.ExportButtonWrapper>
        </Styled.RightButtonWrapper>
      </Styled.Section>

      {/* TABLE */}
      <Styled.Wrapper sx="table__box pt-3">
        <Table 
          columns={dispute_trnx_col} 
          pagination={{
            total: 10,
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            showQuickJumper: true,
            position: "bottomCenter"
          }} 
          scroll={{ x: true}}
          />
      </Styled.Wrapper>
    </div>
  );
};

export default TrnxDisputes;
