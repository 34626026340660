import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { Styled } from "../../../constants/Styled";

const GenerateQrCode = () => {
  return (
    <>
      <Styled.Section sx="qrcode__generated">
        <Styled.Wrapper sx="checkout__qrcode__generated">
          <QRCodeSVG
            renderAs="svg"
            bgColor="transparent"
            size={200}
            bordered={false}
            value="TM9D DF98 FE83 3673"
          />
        </Styled.Wrapper>

        <Styled.Wrapper sx="pt-3">
          <Styled.Section sx="generated__code__det">
            <Styled.Text>Voucher Code</Styled.Text>
            <Styled.Text>Amount</Styled.Text>
          </Styled.Section>

          <Styled.Section sx="generated__code__detail">
            <Styled.Text>TM9D DF98 FE83 3673</Styled.Text>
            <Styled.Text>₦120,057</Styled.Text>
          </Styled.Section>
        </Styled.Wrapper>
      </Styled.Section>
    </>
  );
};

export default GenerateQrCode;
