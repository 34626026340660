import React from 'react'
import { Styled } from '../constants/Styled'
import { Icons } from '../constants/Icons'

const HelpFaq = (props) => {
  return (
    <Styled.Wrapper sx="hel__faq__list" key={props.key}>
        <Icons.HelpFaqIcon />
        <Styled.Text>{props.desc}</Styled.Text>
    </Styled.Wrapper>
  )
}

export default HelpFaq