
import { Icons } from "../constants/Icons";
import { formatAmount, formatDateAndTime } from "../utils/Utils";

export const checkoutCol = [
  {
    title: " Card Number",
    dataIndex: "voucher_card_number",
    sorter: (a, b) => a.voucher_card_number - b.voucher_card_number,
  },

  {
    title: " Date/Time",
    dataIndex: "created_at",
    render: formatDateAndTime,
    sorter: (a, b) => a.created_at.toLocaleString("en-US") - b.created_at.toLocaleString("en-US"),
  },

  {
    title: " Type",
    dataIndex: "checkout_method",
    render: checkout_method => (<div className={`status-before-content ${checkout_method === "VOUCHER_CODE" ? "credit" : "debit"}`}>{checkout_method === "VOUCHER_CODE" ? "Credit" : "Debit"}</div>),
    sorter: (a, b) => a.checkout_method - b.checkout_method,
  },

  {
    title: "Amount",
    dataIndex: "amount",
    render: formatAmount,
    sorter: (a, b) => a.amount.toLocaleString() - b.amount.toLocaleString(),
  },

  {
    title: "Status",
    dataIndex: "checkout_status",
    render: (checkout_status) => (
      <span
        className={`status-before-content  sb ${
          checkout_status.toLowerCase()
            ? checkout_status.toLowerCase()
            : checkout_status.toLowerCase()
        }`}
      >
        {checkout_status.toLowerCase()
          ? checkout_status.toLowerCase()
          : checkout_status.toLowerCase()}
      </span>
    ),
    sorter: (a, b) => a.checkout_status - b.checkout_status,
  },

  {
    title: "Processed By",
    dataIndex: "processed_by",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    render: action => <Icons.ActionDot size={20} color="#72747D" width="15.12px" height="15.12px" />,
    align: "center",
  }
];
