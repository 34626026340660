import React, { useEffect, useState } from "react";
import { Styled } from "../../../constants/Styled";
import { wallet_card } from "../../../data/walletData";
import CashOutWallet from "./CashOut.Wallet";
import { Modal } from "antd";
import { Icons } from "../../../constants/Icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchWalletBalance } from "../../../state/wallet.Slice";
import CurrencyFormat from "react-currency-format";

const WalletCard = () => {
  const [isCashOut, setIsCashOut] = useState(false);
  const dispatch = useDispatch();
  const { wallet } = useSelector((state) => state.wallet);

  const handleCashOut = () => {
    setIsCashOut(true);
  };

  useEffect(() => {
    dispatch(fetchWalletBalance());
  }, [dispatch]);

  
  return (
    <>
      <Styled.Wrapper sx="wallet__card">
        <Styled.Section sx="wallet__card__content">
          <Styled.Card>
            <Styled.Text>WALLET BALANCE</Styled.Text>
            <Styled.Span>
              <CurrencyFormat
                value={
                  wallet?.data?.balance ?? 0
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix="₦ "
              />
            </Styled.Span>
          </Styled.Card>
          <Styled.Card onClick={handleCashOut}>
            <Icons.CashOutIcon />
          </Styled.Card>
        </Styled.Section>

        {wallet_card.map((dash) => (
          <Styled.Section sx="wallet__card__content__2" key={dash.index}>
            <Styled.Card>
              <Styled.Text>{dash.title}</Styled.Text>
              {dash.showCurrency ? (
                <Styled.Span>
                  {dash.showCurrency} {dash.value}
                </Styled.Span>
              ) : (
                <Styled.Span>{dash.value}</Styled.Span>
              )}
            </Styled.Card>
            <Styled.Card onClick={handleCashOut}>{dash.icon}</Styled.Card>
          </Styled.Section>
        ))}
      </Styled.Wrapper>

      {isCashOut && (
        <Modal
          title="Cash Out"
          open={handleCashOut}
          onCancel={() => setIsCashOut(false)}
          footer={null}
          width={400}
        >
          <CashOutWallet />
        </Modal>
      )}
    </>
  );
};

export default WalletCard;
