import { formatDateAndTime } from "../utils/Utils";



export const audit_columns = [ 
    {
        title: "DATE",
        dataIndex: "created_at",
        render: formatDateAndTime,
        sorter: (a, b) => a.created_at - b.created_at
    },

    {
        title: "Email",
        dataIndex: "staff",
        sorter: (a, b) => a.staff - b.staff,
    },

    {
        title: "Category",
        dataIndex: "resource",
        sorter: (a, b) => a.resource - b.resource,
    },

    {
        title: "Action",
        dataIndex: "action",
        sorter: (a, b) => a.action - b.action,
    },

    {
        title: "Description",
        dataIndex: "description",
        sorter: (a, b) => a.description - b.description,
    }

];
